import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import {
  _l,
  checklistItem,
  checklistItemFormat,
  createCSVFile,
  csvOnLoadHandler,
  csvRowSplit,
  csvValidations,
  removeDuplicateObjectsFromArray,
  showError,
  utf8CompareString,
} from "../../../hooks/utilities";
import GeneralItemTable from "../../Offcanvas/NewSchedule/GeneralItemTable";
import { useDispatch, useSelector } from "react-redux";
import {
  appendChecklistItemToClipBoard,
  getFloorDetails,
  removeChecklistItemFromClipBoard,
  setFloorFilters,
  updateFloorSetting,
  updateRoomKPICounts,
  updateTypeOfSpaceList,
} from "../../../actions/benchmark-actions/benchmark-actions";
import {
  CHECKLIST_EXPORT_CSV_HEADER,
  WEEK_DAYS,
} from "../../../constants/constants";
import moment from "moment";
import { showMessage } from "../../../actions/messages";
 
import UncontrolledDropdownWithCheckbox from "../../Dropdowns/UncontrolledDropdownWithCheckbox";
import BenchmarkServices from "../../../services/benchmark-services";
import Select from "react-select";
import CreateChecklistTemplate from "../../Modals/CreateChecklistTemplate";
import CustomerServices from "../../../services/customer-services";
import { useSearchParams } from "react-router-dom";
import AddNewTypeOfSpace from "../../Dropdowns/AddNewTypeOfSpace";
import { getBenchmarkWeekSchedule } from "../../../actions/customer";
import GeneralListing from "../../TaskModalComponents/GeneralListing";
import ClipboardIcon from "../../../assets/icons/ClipboardIcon";
import XCircleIcon from "../../../assets/icons/XCircleIcon";

let typeOfSpaceItemsObject = {};

const ChecklistCreation = ({
  checklists,
  floor,
  onClose,
  providerData,
  readOnlyMode,
  templates,
  setTemplates,
  setFloor,
  onSave,
  templateView = false,
  setChecklists
}) => {
  let { floorDetails, filteredFloorIds } = useSelector(
    (state) => state.benchmarkReducer
  );
  let { benchmarkDetail } = useSelector((state) => state.customer);

  const [searchParams] = useSearchParams();
  const type = searchParams.get("provider-type");

  const dispatch = useDispatch();

  const [typeOfSpaceArray, setTypeOfSpaceArray] = useState(
    checklists
      ? removeDuplicateObjectsFromArray(
          checklists.map((x) => {
            return {
              title: x.spaceTitle,
              id: x.room_id,
              is_default: x.is_default,
            };
          }),
          "title"
        )
      : []
  );
  const [checklistLocalState, setChecklistLocalState] = useState(checklists);
  const [showImportItemsModal, setShowImportItemsModal] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");
  const [bulkActionsCheckboxes, setBulkActionsCheckboxes] = useState([]);
  const [showChecklistTemplateModal, setShowChecklistTemplateModal] =
    useState(false);
  const [openAddNewSpaceModal, setOpenAddNewSpaceModal] = useState(false);
  const [floorDataToEdit, setFloorDataToEdit] = useState({
    title: floor.title,
    id: floor.id,
  });
  const [typeOfSpaceDataToEdit, setTypeOfSpaceDataToEdit] = useState({
    title: "",
    id: 0,
    item_id: 0,
  });
  const [activeKey, setActiveKey] = useState([0]);
  const [activeSubKey, setActiveSubKey] = useState([0]);
  const [activeAccordionKeys, setActiveAccordionKeys] = useState([0]);
  const [activeSubAccordionKeys, setActiveSubAccordionKeys] = useState([]);
  const [forceRender, setForceRender] = useState(false);
  const [typeOfSpaceList, setTypeOfSpaceList] = useState([]);

  const selectedOffice = localStorage.getItem("selectedOffice");
  const keySelectorById = item => item.id;
  const typeOfSpaceMap = new Map(floorDetails.type_of_space.map(item => [keySelectorById(item), item]));

  const getTotalSpaceItemQuantity = (spaceItems) => {
    let spaceItemQuantity = 0;
    if (!filteredFloorIds || !filteredFloorIds.length) {
      spaceItemQuantity = spaceItems.length;
      return spaceItemQuantity;
    } else {
      spaceItems.forEach((item) => {
        if (filteredFloorIds.findIndex((x) => x == item) !== -1) {
          spaceItemQuantity++;
        }
      });
    }
    return spaceItemQuantity;
  };

  const downloadCSV = () => {
    let nonEmptyChecklists = checklistLocalState.filter(
      (x) => x.description != ""
    );
    nonEmptyChecklists = nonEmptyChecklists.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (o) =>
            (obj.room_id == o.room_id && obj.description == o.description) ||
            (obj.description == "" && o.description == "")
        )
    );
    if (nonEmptyChecklists.length) {
      let csvToArray = [CHECKLIST_EXPORT_CSV_HEADER];
      nonEmptyChecklists.forEach((checklist) => {
        let space = floorDetails.type_of_space.find(
          (x) => x.id == checklist.room_id
        );
        if (!space) {
          space = floorDetails.type_of_space.find((x) => x.is_default == 1);
        }
        csvToArray.push([
          space.title,
          checklist.description,
          moment(checklist.slot).isValid()
            ? moment(checklist.slot).format("hh:mm A")
            : "",
          checklist.priority ? 1 : 0,
          checklist.photo_required > 0 ? 1 : 0,
          checklist.days.mon ? 1 : 0,
          checklist.days.tue ? 1 : 0,
          checklist.days.wed ? 1 : 0,
          checklist.days.thu ? 1 : 0,
          checklist.days.fri ? 1 : 0,
          checklist.days.sat ? 1 : 0,
          checklist.days.sun ? 1 : 0,
          checklist.days.monthly ? 1 : 0,
          checklist.days.quarterly ? 1 : 0,
          checklist.days.half_yearly ? 1 : 0,
          checklist.days.yearly ? 1 : 0,
        ]);
      });
      createCSVFile(
        csvToArray,
        `${benchmarkDetail.provider_name}_${benchmarkDetail.id}_${_l(
          "l_template"
        )}.csv`
      );
    } else {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_no_checklists_to_export"))
      );
    }
  };

  const downloadSample = () => {
    let csvToArray = [CHECKLIST_EXPORT_CSV_HEADER];
    [0, 1].forEach(() => {
      csvToArray.push([
        "Meeting Room",
        _l("l_cleaning_placeholder"),
        "10:30 AM",
        1,
        1,
        0,
        1,
        1,
        0,
        1,
        1,
        0,
        0,
        0,
        0,
        0,
      ]);
    });
    createCSVFile(csvToArray, `${_l("l_template")}.csv`);
  };

  const handleCsvImport = async () => {
    setShowImportItemsModal(false);
    setCsvFile("");
    let checkListTemplateArray = [];
    let typeOfSpaceMasterList = floorDetails.type_of_space;

    try {
      if (processedCSV) {
        const rows = csvRowSplit(processedCSV);

        const spreadsheetData = rows.map((row) => {
          return row.map((column) => column.replace(/(^"|"$)/g, ""));
        });

        if (!csvValidations(spreadsheetData)) {
          showError("l_invalid_csv_format_message");
          return false;
        }

        let newTypeOfSpaces = [];
        spreadsheetData.forEach((row, rowIndex) => {
          if (
            row.length == 16 &&
            rowIndex > 0 &&
            row[1] &&
            row[1].trim().length
          ) {
            let spaceTitle = row[0];
            let space = typeOfSpaceMasterList.find(
              (x) =>
                x.title &&
                spaceTitle &&
                utf8CompareString(x.title.trim(), spaceTitle.trim())
            );
            if (
              (!space ||
                (space.is_default == 0 &&
                  !space.details.find((w) => w.floor_id == floor.id))) &&
              !newTypeOfSpaces.includes(spaceTitle)
            ) {
              newTypeOfSpaces.push(spaceTitle);
            }
          }
        });

        /**Add new type of space to database which was not exists earlier but found in database */
        if (newTypeOfSpaces.length) {
          const response = await CustomerServices.addFloorItem(
            selectedOffice,
            1,
            newTypeOfSpaces,
            "type_of_space",
            floor.id,
            0
          );
          if (response.status) {
            dispatch(updateFloorSetting(floor, response.data.type_of_space));
            typeOfSpaceMasterList = response.data.type_of_space;
            let currentFloorTypeOfSpaces = typeOfSpaceArray.slice();
            newTypeOfSpaces.forEach((space) => {
              let detail = typeOfSpaceMasterList.find((w) =>
                utf8CompareString(w.title.trim(), space.trim())
              );
              if (detail) {
                currentFloorTypeOfSpaces.push({
                  title: space,
                  id: detail.id,
                  is_default: 0,
                });
              }
            });
            if (currentFloorTypeOfSpaces && currentFloorTypeOfSpaces.length) {
              setTypeOfSpaceArray(currentFloorTypeOfSpaces);
            }
          }
        }

        /**Continue Import process after new spaces added */
        spreadsheetData.forEach((row, rowIndex) => {
          if (
            row.length == 16 &&
            rowIndex > 0 &&
            row[1] &&
            row[1].trim().length
          ) {
            let spaceTitle = row[0];
            let uncheckedSpaces = [];
            let space = typeOfSpaceMasterList.find(
              (x) =>
                x.title &&
                spaceTitle &&
                utf8CompareString(
                  x.title.trim().toLocaleLowerCase(),
                  spaceTitle.trim().toLocaleLowerCase()
                )
            );
            if (!space) {
              space = typeOfSpaceMasterList.find((x) => x.is_default == 1);
            }

            if (space && space.id) {
              let copyFloorIds = [];
              if (space.is_default == 0) {
                copyFloorIds = Array.from(
                  new Set(
                    space.details
                      .map((x) => x.floor_id)
                      .filter((w) => w != floor.id)
                  )
                );
              }

              if (copyFloorIds.length) {
                copyFloorIds.forEach((id) => {
                  space.details.forEach((x) => {
                    if (x.floor_id == id) {
                      dispatch(
                        appendChecklistItemToClipBoard({
                          ...checklistItem,
                          id: 0,
                          floor_id: id,
                          room_id: space.id,
                          description: row[1],
                          slot:
                            row[2] &&
                            new Date(`01-01-2000 ${row[2]}`) instanceof Date
                              ? new Date(`01-01-2000 ${row[2]}`)
                              : "",
                          priority: Number(row[3]) ? 1 : 0,
                          type_of_space_item_id: x.id,
                          photo_required: Number(row[4]) ? 1 : 0,
                          days: {
                            mon: Number(row[5]) ? true : false,
                            tue: Number(row[6]) ? true : false,
                            wed: Number(row[7]) ? true : false,
                            thu: Number(row[8]) ? true : false,
                            fri: Number(row[9]) ? true : false,
                            sat: Number(row[10]) ? true : false,
                            sun: Number(row[11]) ? true : false,
                            monthly: Number(row[12]) ? true : false,
                            quarterly: Number(row[13]) ? true : false,
                            half_yearly: Number(row[14]) ? true : false,
                            yearly: Number(row[15]) ? true : false,
                          },
                          item_comments: [],
                          type_of_space_item_id: x.id,
                          type_of_space_items: space.details
                            .filter((w) => w.floor_id == id)
                            .map((x) => x.id),
                        })
                      );
                    }
                  });
                });
              }

              if (space.is_default == 1) {
                checkListTemplateArray.push({
                  ...checklistItem,
                  id: 0,
                  room_id: space.id,
                  description: row[1],
                  slot:
                    row[2] && new Date(`01-01-2000 ${row[2]}`) instanceof Date
                      ? new Date(`01-01-2000 ${row[2]}`)
                      : "",
                  priority: Number(row[3]) ? 1 : 0,
                  photo_required: Number(row[4]) ? 1 : 0,
                  days: {
                    mon: Number(row[5]) ? true : false,
                    tue: Number(row[6]) ? true : false,
                    wed: Number(row[7]) ? true : false,
                    thu: Number(row[8]) ? true : false,
                    fri: Number(row[9]) ? true : false,
                    sat: Number(row[10]) ? true : false,
                    sun: Number(row[11]) ? true : false,
                    monthly: Number(row[12]) ? true : false,
                    quarterly: Number(row[13]) ? true : false,
                    half_yearly: Number(row[14]) ? true : false,
                    yearly: Number(row[15]) ? true : false,
                  },
                  item_comments: [],
                  type_of_space_item_id: 0,
                  type_of_space_items: [],
                });
              }

              space.details.forEach((x) => {
                if (x.floor_id == floor.id) {
                  checkListTemplateArray.push({
                    ...checklistItem,
                    id: 0,
                    room_id: space.id,
                    description: row[1],
                    slot:
                      row[2] && new Date(`01-01-2000 ${row[2]}`) instanceof Date
                        ? new Date(`01-01-2000 ${row[2]}`)
                        : "",
                    priority: Number(row[3]) ? 1 : 0,
                    photo_required: Number(row[4]) ? 1 : 0,
                    days: {
                      mon: Number(row[5]) ? true : false,
                      tue: Number(row[6]) ? true : false,
                      wed: Number(row[7]) ? true : false,
                      thu: Number(row[8]) ? true : false,
                      fri: Number(row[9]) ? true : false,
                      sat: Number(row[10]) ? true : false,
                      sun: Number(row[11]) ? true : false,
                      monthly: Number(row[12]) ? true : false,
                      quarterly: Number(row[13]) ? true : false,
                      half_yearly: Number(row[14]) ? true : false,
                      yearly: Number(row[15]) ? true : false,
                    },
                    item_comments: [],
                    type_of_space_item_id: x.id,
                    type_of_space_items: space.details
                      .filter((w) => w.floor_id == floor.id)
                      .map((x) => x.id),
                  });
                }
              });

              if (!space.checked) {
                uncheckedSpaces.push(space.id);
              }
            }
            uncheckedSpaces = Array.from(new Set(uncheckedSpaces)); // Remove duplicate space ids if there are any
            dispatch(updateTypeOfSpaceList(uncheckedSpaces, true));
          }
        });

        checkListTemplateArray.forEach((checklist) => {
          if (checklist.days && Object.keys(checklist.days).length) {
            Object.keys(checklist.days).forEach((day) => {
              if (WEEK_DAYS.includes(day) && checklist.days[day]) {
                checklist.days = {
                  ...checklist.days,
                  monthly: false,
                  quarterly: false,
                  half_yearly: false,
                  yearly: false,
                };
              }
            });
          }
        });

        let allCurrentChecklists = checklists
          .concat(checkListTemplateArray)
          .filter((w) => w.description != "");
        allCurrentChecklists = allCurrentChecklists.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.room_id == o.room_id &&
                  obj.type_of_space_item_id == o.type_of_space_item_id &&
                  obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            )
        );
        typeOfSpaceMasterList.forEach((space) => {
          if (space.is_default == 0) {
            space.details.map((spaceItem) => {
              if (
                allCurrentChecklists.findIndex(
                  (x) =>
                    x.type_of_space_item_id == spaceItem.id &&
                    x.room_id == space.id &&
                    x.description.trim() == ""
                ) === -1
              ) {
                allCurrentChecklists.push({
                  ...checklistItem,
                  room_id: space.id,
                  id: crypto.randomUUID(),
                  type_of_space_item_id: spaceItem.id,
                  type_of_space_items: space.details
                    ? space.details.map((x) => x.id)
                    : [],
                  description: "",
                  slot: "",
                });
              }
            });
          } else {
            if (
              allCurrentChecklists.findIndex(
                (x) => x.room_id == space.id && x.description.trim() == ""
              ) === -1
            ) {
              allCurrentChecklists.push({
                ...checklistItem,
                room_id: space.id,
                id: crypto.randomUUID(),
                type_of_space_items: space.details
                  ? space.details.map((x) => x.id)
                  : [],
                description: "",
                type_of_space_item_id: 0,
                slot: "",
              });
            }
          }
        });
        setChecklistLocalState(allCurrentChecklists);
        dispatch(setFloorFilters([floor.id]));
      } else {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_failed_to_process_file"))
        );
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const copyItemToSpaceItem = (spaceItem, check) => {
    let itemsToBeCopied = [];
    let space = floorDetails.type_of_space.find(
      (w) => w.details.findIndex((z) => z.id == spaceItem.id) !== -1
    );
    if (check) {
      if (bulkActionsCheckboxes.length) {
        bulkActionsCheckboxes
          .filter((x) => x.description)
          .forEach((w) => {
            if (
              checklistLocalState.findIndex(
                (x) =>
                  x.room_id == w.room_id &&
                  x.type_of_space_item_id == w.type_of_space_item_id &&
                  x.description == w.description &&
                  x.type_of_space_item_id == spaceItem.id
              ) === -1
            ) {
              itemsToBeCopied.push({
                ...w,
                id: 0,
                room_name: space.title,
                room_id: space.id,
                type_of_space_item_id: spaceItem.id,
                floor_id: spaceItem.floor_id,
              });
            }
          });
        if (itemsToBeCopied.length) {
          let checklistArray = checklistLocalState
            .concat(itemsToBeCopied)
            .slice();
          checklistArray.sort((a, b) => {
            if (a.description === "" && b.description !== "") {
              return 1;
            }
            if (a.description !== "" && b.description === "") {
              return -1;
            }
            return 0;
          });
          setChecklistLocalState(checklistArray);
        }
      } else {
        dispatch(
          showMessage(
            "unsuccess",
            _l("l_error"),
            `${"l_select_items_to_copy"} ${spaceItem.title}`
          )
        );
        showError("l_select_items_to_copy");
      }
    } else {
      setChecklistLocalState(
        checklistLocalState.filter((w) =>
          bulkActionsCheckboxes.findIndex(
            (z) =>
              z.room_id != w.room_id &&
              z.type_of_space_item_id != w.type_of_space_item_id &&
              z.description != w.description &&
              z.type_of_space_item_id != spaceItem.id
          )
        )
      );
    }
  };

  const removeChecklistItem = (checklist) => {
    BenchmarkServices.removeScheduleChecklistItem(
      providerData.id,
      selectedOffice,
      [checklist.id]
    );
  };

  const bulkChecklistDelete = (itemIds) => {
    BenchmarkServices.removeScheduleChecklistItem(
      providerData.id,
      selectedOffice,
      itemIds
    ).then((res) => {
      if (res.status) {
        dispatch(getBenchmarkWeekSchedule(providerData.id));
      }
    });
  };

  const saveTemplate = async (title) => {
    setShowChecklistTemplateModal(false);
    if (templates.find((x) => x.title && x.title.trim() == title.trim())) {
      showError("l_template_already_exists");
    } else if (title && title.trim().length) {
      let checklistItems = checklistLocalState.slice();
      let formattedChecklistArray = [];

      if (checklistItems && checklistItems.length) {
        checklistItems.forEach((checklist) => {
          if (checklist.description) {
            formattedChecklistArray.push({
              ...checklistItemFormat,
              id: checklist.id || 0,
              slot: checklist.slot,
              slot_title: checklist.slot_title,
              room_id: checklist.room_id,
              floor_id: floor.id,
              checklist: [
                {
                  name: checklist.description,
                  photo: 0,
                  selected: 1,
                  addedFromTemplate: false,
                  unique_id: checklist.id,
                  is_default_checklist: false,
                  tasks_per_month: 1,
                },
              ],
              days: checklist.days,
              priority: checklist.priority,
              comments: checklist.item_comments,
              photo_required: checklist.photo_required,
              type_of_space_items: checklist.type_of_space_items,
              type_of_space_item_id: checklist.type_of_space_item_id,
            });
          }
        });
      }

      if (formattedChecklistArray.length) {
        const response = await BenchmarkServices.createChecklistTemplate(
          selectedOffice,
          providerData.categoryId,
          title.trim(),
          formattedChecklistArray
        );
        if (response.status && response.data.inserted_template_id) {
          setTemplates(
            templates.concat([
              { title: title.trim(), id: response.data.inserted_template_id },
            ])
          );
        } else {
          showError("l_something_went_wrong");
        }
      }
    } else {
      showError("l_please_enter_template_title");
    }
  };

  const copyTemplateChecklist = async (template) => {
    try {
      const response = await BenchmarkServices.getTemplateChecklistItems(
        selectedOffice,
        benchmarkDetail.provider_id,
        template.value
      );
      if (response.status && response.data.length) {
        let templateItems = response.data.slice().map((checklist) => {return {...checklist, room_id: checklist.type_of_space_id}});
        let currentChecklistItems = checklistLocalState.slice();
        let spaceTitles = {};

        currentChecklistItems.forEach((checklist) => {
          spaceTitles[checklist.room_id] = checklist.spaceTitle;
        });

        templateItems.forEach((x) => {
          if (
            !currentChecklistItems.find(
              (w) =>
                w.description &&
                w.description.trim().toLocaleLowerCase() ==
                  x.description.trim().toLocaleLowerCase() &&
                w.room_id == x.room_id &&
                x.type_of_space_item_id == w.type_of_space_item_id
            )
          ) {
            currentChecklistItems.push({
              ...x,
              room_id: x.type_of_space_id,
              id: 0,
              spaceTitle: spaceTitles[Number(x.room_id)]
            });
          }
        });

        currentChecklistItems = currentChecklistItems.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.room_id == o.room_id &&
                  obj.type_of_space_item_id == o.type_of_space_item_id &&
                  obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            ) || obj.description == ""
        );

        currentChecklistItems.sort((a, b) => {
          if (a.description === "" && b.description !== "") {
            return 1;
          }
          if (a.description !== "" && b.description === "") {
            return -1;
          }
          return 0;
        });
        setChecklistLocalState(currentChecklistItems);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getFloorIdFromSpaceItem = (spaceItemId) => {
    let floorId = 0;
    if (spaceItemId == 0) {
      floorId = -1;
    }
    floorDetails.type_of_space.forEach((space) => {
      space.details.forEach((spaceItem) => {
        if (spaceItem.id == spaceItemId) {
          floorId = spaceItem.floor_id;
        }
      });
    });
    return floorId;
  };

  const updateFloorTitle = async () => {
    if (floor.title !== floorDataToEdit.title) {
      if (!floorDataToEdit.title || !floorDataToEdit.title.trim().length) {
        showError("l_floor_name_required");
        return false;
      } else {
        try {
          const response = await CustomerServices.addUpdateFloorSettings(
            { ...floor, title: floorDataToEdit.title },
            [],
            selectedOffice
          );
          if (response.status) {
            let targetFloor = response.data.floor.find(
              (x) =>
                x.title &&
                x.title.trim() &&
                x.title.trim().toLowerCase() == floor.title.trim().toLowerCase()
            );
            targetFloor = {
              ...floor,
              title: floorDataToEdit.title,
            };
            dispatch(
              updateFloorSetting(targetFloor, response.data.type_of_space)
            );
            dispatch(
              showMessage("success", _l("l_success"), _l("l_floor_name_update"))
            );
            setFloor({...floor, title: floorDataToEdit.title});
          } else {
            showError(response.message);
            setFloorDataToEdit({ ...floorDataToEdit, title: floor.title });
          }
        } catch (e) {
          setFloorDataToEdit({ ...floorDataToEdit, title: floor.title });
          console.error(e);
        }
      }
    }
  };

  const updateTypeOfSpaceTitle = async () => {
    let targetSpace = floorDetails.type_of_space.find(
      (w) => w.id == typeOfSpaceDataToEdit.id
    );
    if (targetSpace) {
      if (typeOfSpaceDataToEdit.item_id > 0) {
        let targetSpaceItem = targetSpace.details.find(
          (w) => w.id == typeOfSpaceDataToEdit.item_id
        );
        if (
          targetSpaceItem &&
          targetSpaceItem.title !== typeOfSpaceDataToEdit.title
        ) {
          if (!typeOfSpaceDataToEdit.title.trim().length) {
            showError("l_title_required");
          } else {
            try {
              const response = await BenchmarkServices.updateTypeOfSpaceTitle(
                selectedOffice,
                "type_of_space_item",
                typeOfSpaceDataToEdit.id,
                typeOfSpaceDataToEdit.item_id,
                typeOfSpaceDataToEdit.title
              );
              if (response.status) {
                dispatch(
                  updateFloorSetting(floor, response.data.type_of_space)
                );
                dispatch(
                  showMessage(
                    "success",
                    _l("l_success"),
                    _l("l_space_title_updated")
                  )
                );
              } else {
                showError(response.message);
              }
            } catch (e) {
              console.error(e);
            }
          }
        }
      } else {
        if (targetSpace && targetSpace.title !== typeOfSpaceDataToEdit.title) {
          if (!typeOfSpaceDataToEdit.title.trim().length) {
            showError("l_title_required");
          } else {
            try {
              const response = await BenchmarkServices.updateTypeOfSpaceTitle(
                selectedOffice,
                "type_of_space",
                typeOfSpaceDataToEdit.id,
                0,
                typeOfSpaceDataToEdit.title
              );
              if (response.status) {
                dispatch(
                  updateFloorSetting(floor, response.data.type_of_space)
                );
                dispatch(
                  showMessage(
                    "success",
                    _l("l_success"),
                    _l("l_space_title_updated")
                  )
                );
              } else {
                showError(response.message);
              }
            } catch (e) {
              console.error(e);
            }
          }
        }
      }
    }
  };

  const createTemplateForFirstBenchmark = () => {
    const templateTitle = `#${benchmarkDetail.id}-${benchmarkDetail.provider_name}`
    if (templates.find((x) => x.title && x.title.trim() == templateTitle.trim())) {
      return;
    }
    if(Number(benchmarkDetail.project_benchmark_schedule_count) === 1 && type !== "external-benchmark" && !readOnlyMode && checklistLocalState.every((item) => isNaN(item.id) || !Number(item.id)))
    {
      saveTemplate(templateTitle)
    }
  };

  const updateAccordionKeys = (index, keys) => {
    handleAccordionItemClick(index);
    if(activeAccordionKeys.includes(index))
    {
      setActiveAccordionKeys(activeAccordionKeys.filter((w) => w !== index));
      setActiveSubAccordionKeys(activeSubAccordionKeys.filter((w) => !keys.includes(w)));
    }
    else {
      setActiveAccordionKeys([index]);
      setActiveSubAccordionKeys(activeSubAccordionKeys.concat(keys));
    }
  };

  const appendBlankChecklistItemsToNewSpace = () => {
    if(!readOnlyMode)
    {
      let emptyChecklistItems = [];
      floorDetails.type_of_space_items.forEach((type_of_space) => {
        if(!checklistLocalState.find(w => w.room_id == type_of_space.room_id && w.type_of_space_item_id == type_of_space.id))
        {
          emptyChecklistItems.push({
            ...checklistItem,
            description: "",
            room_id: type_of_space.room_id,
            spaceTitle: type_of_space.room_title,
            type_of_space_item_id: type_of_space.id,
            type_of_space_items: [],
          });
        }
      });
      setChecklistLocalState(checklistLocalState.concat(emptyChecklistItems));      
    }
  };

  const updateTypeOfSpaceItemList = () => {
    if(floorDetails.type_of_space_items && floorDetails.type_of_space_items.length)
    {
      floorDetails.type_of_space_items.forEach((space) => {
        if(space.floor_id == floor.id)
        {
          typeOfSpaceItemsObject[`${space.room_id}`] =  typeOfSpaceItemsObject[`${space.room_id}`] ? typeOfSpaceItemsObject[`${space.room_id}`].concat(space) : [space];
        }
      });
      appendBlankChecklistItemsToNewSpace();
    }
  };

  const handleAccordionItemClick = (eventKey) => {
    if (activeKey.includes(eventKey)) {
      setActiveKey([]);
    } else {
      setActiveKey([eventKey]);
    }
  };

  const handleAccordionSubItemClick = (eventKey) => {
    if (activeSubKey.includes(eventKey)) {
      setActiveSubKey([]);
    } else {
      setActiveSubKey([eventKey]);
    }
  };

  const updateSpaceSkillRequirement = async(e, spaceDetail) => {
    const flagValue = e.target.checked ? 1 : 0;
    try {
      const projectId = localStorage.getItem("selectedOffice");
      const response = await BenchmarkServices.updateTypeOfSpaceTitle(projectId, "type_of_space", spaceDetail.id, 0, spaceDetail.title, undefined, floor.id, flagValue);
      if(response.status)
      {
        dispatch(getFloorDetails(projectId));
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(setFloorFilters([floor.id]));
  }, []);

  useEffect(() => {
    let kpiCounts = {};
    checklistLocalState.forEach((checklist) => {
      if (!kpiCounts[checklist.room_id]) {
        kpiCounts[checklist.room_id] = 0;
      }
      if (
        checklist.description &&
        checklist.description.trim().length &&
        (checklist.type_of_space_item_id == 0 ||
          getFloorIdFromSpaceItem(checklist.type_of_space_item_id) == floor.id)
      ) {
        kpiCounts[checklist.room_id] = (kpiCounts[checklist.room_id] || 0) + 1;
      }
    });
    dispatch(updateRoomKPICounts(kpiCounts));
    setChecklists(checklistLocalState);
  }, [checklistLocalState]);

  useEffect(() => {
    updateTypeOfSpaceItemList();
    setForceRender(forceRender);
  }, [floorDetails.type_of_space_items])

  useEffect(() => {
    if (floorDetails.type_of_space && floorDetails.type_of_space.length) {
      const typeOfSpaceListWrapper = floorDetails.type_of_space.filter((space) => {
        const floorIds = space.details.map((x) => x.floor_id);  
        if (floorIds.includes(floor.id) || space.is_default != 0) {
          return space;
        }
      })
      setTypeOfSpaceList(typeOfSpaceListWrapper)
    }
  }, [floorDetails.type_of_space])

  
  return (
    <div className="h-100 radius_3 d-flex flex-column onboarding-floor-bg flex-grow-1 checklist-creation-abs lighthtemebg pb-5">
      <div className="mb-32px d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-center justify-content-between w100minus30">
          <div className="c-font f-14 fw-semibold pe-1 text-truncate title-fonts">
            <input
              disabled={readOnlyMode}
              type="text"
              className="bg-transparent p-0 form-control border-0 c-font f-14 fw-semibold"
              defaultValue={floor.title}
              onChange={(e) =>
                setFloorDataToEdit({
                  ...floorDataToEdit,
                  title: e.target.value,
                })
              }
              onBlur={updateFloorTitle}
            />
          </div>
          <div className="d-flex align-items-center w100minus100per">
            <div className="schedule-count-box-main d-flex align-items-center w-100 overflow-hidden justify-content-end">
              <div className="d-flex align-items-center overflow-auto w100minus150 flex-grow-1 mx-2">
                <div className="d-flex ms-auto px-2">
                  {typeOfSpaceList.length > 0 ? (
                    typeOfSpaceList.slice(0,3).map((space) => {
                      return (
                        <>
                          <div
                            className="schedule-count-box with_separator_10 c-font f-10"
                             
                             
                            data-class="tooltip-main "
                          >
                            <div className="fw-semibold schedule-number title-fonts c-font f-14">
                              {space.count || 0}
                            </div>
                            <div className="color-white-80 schedule-title text-truncate c-font f-12">
                              {space.title}
                            </div>
                          </div>
                           
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {
                    typeOfSpaceList.length > 3
                      ?
                      <button onClick={(event) => event.stopPropagation()} className="align-items-center badge badge-done d-flex h40w40 fw-light justify-content-center rounded-pill text-truncate">
                        <UncontrolledDropdown className="align-items-center d-flex dropdown justify-content-center w-100">
                          <DropdownToggle data-toggle="dropdown" tag="div" className="comman_action_icon">
                            <div>{`+ ${typeOfSpaceList.length - 3}`}</div>
                            <GeneralListing
                              unControlList={true}
                              list={
                                typeOfSpaceList.slice(3)
                                .map((space, i) => {
                                  return {
                                    name: `${space.title} - ${space.count || 0}`,
                                  }
                              })
                            }
                            />
                          </DropdownToggle>
                        </UncontrolledDropdown>
                      </button>
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className={`h50w50 ms-10px cursor-pointer`}
          onClick={() => {
            onClose(checklistLocalState);
            if(!templateView)
            {
              createTemplateForFirstBenchmark();
            }
          }}
        >
          <XCircleIcon className="HW50 stroke-width-4px" />
        </a>
      </div>
      
        <div className="h100minus70px bg-white-03-solid p-20">
          <div className="custom-accordian-main with-left-header-arrow flex-grow-1 d-flex flex-column h100minus50px h-100 p-15 py-0 px-0">
          <Accordion className="flex-grow-1 overflow-auto"activeKey={activeKey} alwaysOpen>
            {typeOfSpaceArray.map((space, index) => {

              let spaceDetail = {details: typeOfSpaceItemsObject[`${space.id}`] ? removeDuplicateObjectsFromArray(typeOfSpaceItemsObject[`${space.id}`], "title").filter(w => checklistLocalState.findIndex((z) => z.type_of_space_item_id == w.id) != -1) : []};

              let spaceFloorList = [];

              return (
                <Accordion.Item
                  key={index}
                  eventKey={index}
                  className={`mainTopicTaskFilter for-accessible`}
                >
                  <div className="d-flex align-items-center justify-content-between bgspacelisting lighthtemebg">
                  <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts w100minus100per p-5px px-0  radius_3 light-theme-grey-light-bg"  onClick={() => {updateAccordionKeys(index, spaceDetail.details.map((w) => w.id));}}>
                    <Accordion.Header className="text-truncate w-auto bg-transparent with_separator_10 ps-0">
                      <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                        <div className="title-font c-font f-12 fw-semibold text-truncate d-flex align-items-center">
                          <input
                            type="text"
                            className="bg-transparent p-0 form-control border-0 title-font c-font f-14 fw-semibold text-truncate"
                            defaultValue={space.title}
                            onChange={(e) =>
                              setTypeOfSpaceDataToEdit({
                                ...typeOfSpaceDataToEdit,
                                title: e.target.value,
                              })
                            }
                            onFocus={() => {
                              setTypeOfSpaceDataToEdit({
                                ...typeOfSpaceDataToEdit,
                                title: space.title,
                                id: space.id,
                              });
                            }}
                            onBlur={updateTypeOfSpaceTitle}
                            disabled={readOnlyMode || space.is_default == 1}
                          />
                          {space.is_default == 1 ||
                          getTotalSpaceItemQuantity(
                            spaceDetail.details.map((x) => x.floor_id)
                          ) <= 1 ? (
                            <></>
                          ) : (
                            <span className="c-font f-12 fw-semibold title-fonts color-green ps-2">
                              [
                              {getTotalSpaceItemQuantity(
                                spaceDetail.details.map((x) => x.floor_id)
                              )}
                              ]
                            </span>
                          )}
                        </div>
                      </div>
                    </Accordion.Header>
                  </div>
                  <div className="form-check form-check-inline   d-flex align-items-center me-10px">
                    <label className="input-label no-asterisk form-label me-10px mb-0">{_l("l_skill_require_for_benchmark")}</label>
                    <Form.Check
                      className="withbgwhite with-no-label-space"
                      type="switch"
                      role="switch"
                      checked={typeOfSpaceMap.get(space.id) && typeOfSpaceMap.get(space.id).skill_require_for_benchmark > 0}
                      onChange={(e) => updateSpaceSkillRequirement(e, space)}
                      disabled={readOnlyMode}
                    />
                  </div>
                  </div>
                  {space.is_default == 0 &&
                  spaceDetail.details.length > 1 ? (
                    <Accordion.Body className="mt-1 childTopicTaskFilter">
                      <div>
                        {spaceDetail.details
                          .filter((x) => x.floor_id == floor.id)
                          .map((spaceItem, itemKey) => {
                            let floorTypeOfSpaceItemsList = [];
                            return (
                              <div
                                key={itemKey}
                                className="custom-accordian-main with-left-header-arrow p-15 py-0"
                              >
                                <Accordion
                                  activeKey={activeSubKey} alwaysOpen
                                >
                                  <Accordion.Item
                                    eventKey={itemKey}
                                    className={`mainTopicTaskFilter for-accessible`}
                                  >
                                    <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts w-100 p-5px px-0 bg-white-03 radius_3 light-theme-grey-light-bg" onClick={() => handleAccordionSubItemClick(itemKey)}>
                                      <Accordion.Header className="text-truncate w-auto bg-transparent with_separator_10 ps-0">
                                        <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                          <div className="title-font c-font f-12 fw-semibold text-truncate ">
                                            <input
                                              type="text"
                                              className="bg-transparent p-0 form-control border-0 title-font c-font f-12 fw-semibold text-truncate"
                                              defaultValue={spaceItem.title}
                                              onChange={(e) =>
                                                setTypeOfSpaceDataToEdit({
                                                  ...typeOfSpaceDataToEdit,
                                                  title: e.target.value,
                                                })
                                              }
                                              onFocus={() => {
                                                setTypeOfSpaceDataToEdit({
                                                  ...typeOfSpaceDataToEdit,
                                                  title: spaceItem.title,
                                                  id: space.id,
                                                  item_id: spaceItem.id,
                                                });
                                              }}
                                              onBlur={updateTypeOfSpaceTitle}
                                              disabled={readOnlyMode}
                                            />
                                          </div>
                                        </div>
                                      </Accordion.Header>
                                      {!readOnlyMode &&
                                      floorTypeOfSpaceItemsList &&
                                      floorTypeOfSpaceItemsList.length ? (
                                        <UncontrolledDropdown className="with_separator_10">
                                          <DropdownToggle
                                            tag="a"
                                            className=" on-hover-active-toggle-img z-index-3 before-dnone border-0"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                          >
                                            <ClipboardIcon
                                              className="HW18"
                                            />
                                          </DropdownToggle>
                                          <DropdownItem
                                            toggle={false}
                                            className="p-0"
                                          >
                                            <UncontrolledDropdownWithCheckbox
                                              list={floorTypeOfSpaceItemsList}
                                              onCheckHandler={(
                                                target,
                                                check
                                              ) => {
                                                copyItemToSpaceItem(
                                                  target,
                                                  check
                                                );
                                              }}
                                            />
                                          </DropdownItem>
                                        </UncontrolledDropdown>
                                      ) : (
                                        <React.Fragment></React.Fragment>
                                      )}
                                    </div>
                                    <Accordion.Body className="mt-1 childTopicTaskFilter">
                                    {activeSubAccordionKeys.includes(spaceItem.id) && <GeneralItemTable
                                        key={spaceItem.id}
                                        bulkActionsCheckboxes={
                                          bulkActionsCheckboxes
                                        }
                                        checklists={checklistLocalState}
                                        isReadOnly={readOnlyMode}
                                        readOnlyMode={readOnlyMode}
                                        room_id={space.id}
                                        type_of_space_item_id={spaceItem.id}
                                        room_title={space.title}
                                        setBulkActionsCheckboxes={
                                          setBulkActionsCheckboxes
                                        }
                                        setChecklists={setChecklistLocalState}
                                        deleteChecklistItem={(checklist) => {
                                          removeChecklistItem(checklist);
                                        }}
                                        bulkChecklistDelete={
                                          bulkChecklistDelete
                                        }
                                      />}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            );
                          })}
                      </div>
                    </Accordion.Body>
                  ) : space.is_default == 1 ||
                    spaceDetail.details.length == 1 ? (
                    <Accordion.Body className="mt-1 childTopicTaskFilter">
                      {activeAccordionKeys.includes(index) && <GeneralItemTable
                        key={index}
                        bulkActionsCheckboxes={bulkActionsCheckboxes}
                        checklists={checklistLocalState}
                        isReadOnly={readOnlyMode}
                        readOnlyMode={readOnlyMode}
                        room_id={space.id}
                        room_title={space.title}
                        type_of_space_item_id={
                          spaceDetail.details && spaceDetail.details.length
                            ? spaceDetail.details[0].id
                            : "0"
                        }
                        setBulkActionsCheckboxes={setBulkActionsCheckboxes}
                        setChecklists={setChecklistLocalState}
                        deleteChecklistItem={(checklist) => {
                          removeChecklistItem(checklist);
                        }}
                        bulkChecklistDelete={bulkChecklistDelete}
                      />}
                    </Accordion.Body>
                  ) : (
                    <></>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
          {readOnlyMode && type !== "external-benchmark" ? (
            <div className="d-flex align-items-center justify-content-between mt-3 dropup">
              <div className="d-flex align-items-center ">
                <Button
                  className="mx-1"
                  variant="white-05 border-0"
                  onClick={downloadCSV}
                >
                  {_l("l_export_checklist")}
                </Button>
                <Button
                  className="mx-1"
                  variant="white-05 border-0"
                  onClick={() => {
                    setShowChecklistTemplateModal(true);
                  }}
                >
                  {_l("l_save_as_template")}
                </Button>
              </div>
            </div>
          ) : (

            <div className={`d-flex align-items-center justify-content-between mt-3 dropup ${!templateView ? '' : 'pe-2 w100minus50'}`}> 
              {
                !templateView
                ?
                <UncontrolledDropdown className="d-flex align-items-center">
                  <DropdownToggle
                    tag="a"
                    className=" on-hover-active-toggle-img z-index-3 before-dnone border-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <Button
                      className="mx-1"
                      variant="white-05 border-0 text-nowrap"
                      onClick={() => setOpenAddNewSpaceModal(true)}
                    >
                      {_l("l_add_room")}
                    </Button>
                  </DropdownToggle>
                  <DropdownItem toggle={false} className="p-0">
                    <AddNewTypeOfSpace
                      checklists={checklistLocalState}
                      setChecklists={setChecklistLocalState}
                      show={openAddNewSpaceModal}
                      setShow={setOpenAddNewSpaceModal}
                      floor={floor}
                      setTypeOfSpaceArray={setTypeOfSpaceArray}
                      typeOfSpaceArray={typeOfSpaceArray}
                    />
                  </DropdownItem>
                </UncontrolledDropdown>
                :
                <React.Fragment></React.Fragment>

              }
              <div className="d-flex align-items-center ">
                {
                  !templateView
                  ?
                  <React.Fragment>
                    <Button
                      className="mx-1"
                      variant="white-05 border-0"
                      onClick={() => {
                        setShowImportItemsModal(true);
                      }}
                    >{`${_l("l_import")} ${_l("l_checklist")}`}</Button>
                    <Button
                      className="mx-1"
                      variant="white-05 border-0"
                      onClick={downloadCSV}
                    >
                      {_l("l_export_checklist")}
                    </Button>
                    <React.Fragment>
                      {type !== "external-benchmark" ? (
                        <Button
                          className="mx-1"
                          variant="white-05 border-0"
                          onClick={() => {
                            setShowChecklistTemplateModal(true);
                          }}
                        >
                          {_l("l_save_as_template")}
                        </Button>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <div className="position-relative">
                      <Select
                        placeholder={_l("l_template")}
                        className="custom-select-menu drop-up selectpostop  min-h-33 w-200  with-filter-select ms-2 bg-white-05 m-0"
                        classNamePrefix="react-select"
                        options={templates.map((w) => {
                          return { label: w.title, value: w.id };
                        })}
                        menuPlacement="auto"
                        onChange={copyTemplateChecklist}
                      />
                      </div>
                    </React.Fragment>
                  </React.Fragment>
                  :
                  <React.Fragment></React.Fragment>
                }
                <Button
                  className="mx-1 border-0 ms-2"
                  variant="primary white-05 border-0"
                  onClick={() => {
                    onSave(checklistLocalState);
                    if(!templateView)
                    {
                      createTemplateForFirstBenchmark();
                    }
                  }}
                >
                  {_l("l_save")}
                </Button>
              </div>
            </div>
          )}
          </div>
        </div>
      
      {showImportItemsModal ? (
        <></>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {showChecklistTemplateModal ? (
        <CreateChecklistTemplate
          handleClose={() => {
            setShowChecklistTemplateModal(false);
          }}
          handleSave={saveTemplate}
          show={showChecklistTemplateModal}
          scheduleDetail={{
            ...benchmarkDetail,
            categoryTitle: benchmarkDetail.provider_name,
            benchmarkId: benchmarkDetail.id,
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default ChecklistCreation;
