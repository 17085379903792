import React, { useEffect, useState } from "react";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MentionsInput, Mention } from "react-mentions";
 
import {
  _l,
  CloseCommentModal,
  tabindexFix,
  getImagePreviewIcon,
  getPreviewFromURL,
  stringReplacer,
  TaskModalClose,
  showError,
  hideLeftPanelNew,
  showLeftPanelNew,
  getFileDetails,
  removeDuplicates,
  toggleLeftPanelAndOffcanvasClasses,
  showContactDirectory,
  addexpandedClassIntoOffice
} from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import ReactHtmlParser from "react-html-parser";
import { useDispatch,useSelector } from "react-redux";
import jQuery from "jquery";
import Lightbox from "yet-another-react-lightbox";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import TaskShareLinkModal from "../Modals/TaskShareLinkModal";
import MeetingProviderModal from "../Modals/MeetingProviderModal";
import { SET_NEW_MEETING } from "../../actions/action-type";
import {Button, Spinner} from 'react-bootstrap';
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { NO_EXTERNAL_URLS } from "../../constants/constants";
import { getSelectedTask, setActionForLeaseDocument, setCallFullscreen, setCommentIDtoHighlight, setExternalShowFullscreen, setImageViewerisOPen, setMailReply, setTaskAttchmentsForCreate, setTypeOfLeaseDocument, updateTaskList } from "../../actions/customer";
import { mentionStyle } from "../../assets/mention/mentionStyle";
import jquery from "jquery";
import { leadModalToggle } from "../../actions/customer";
import { commanoffcanvasisOpen, commanoffcanvasisOpenfromCalendar,updateHideLoader } from "../../actions/customer";
import moment from "moment";
import StarRatingDropdown from "../TaskModalComponents/StarRatingDropdown";
import AIContextModal from "../Modals/AIContextModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomerServices from "../../services/customer-services";
import Typing from "../Typing/Typing";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { mentionInputStyle } from "../../assets/mention/mentionInputStyle";
import Toasts from "../Toasts/Toasts";
import ChatBoxSkeleton from "../Skeleton/ChatBoxSkeleton";
import MagnifyingGlassIcon from "../../assets/icons/MagnifyingGlassIcon";
import UploadSimpleIcon from "../../assets/icons/UploadSimpleIcon";
import VideoCameraIcon from "../../assets/icons/VideoCameraIcon";
import PushPinIcon from "../../assets/icons/PushPinIcon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import AtIcon from "../../assets/icons/AtIcon";
import HashIcon from "../../assets/icons/HashIcon";
import XIcon from "../../assets/icons/XIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import ArrowBendDownLeftIcon from "../../assets/icons/ArrowBendDownLeftIcon";
import DownloadSimpleIcon from "../../assets/icons/DownloadSimpleIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import ChecksIcon from "../../assets/icons/ChecksIcon";
import SmileyIcon from "../../assets/icons/SmileyIcon";
import CornersInIcon from "../../assets/icons/CornersInIcon";
import CornersOutIcon from "../../assets/icons/CornersOutIcon";
import StarIcon from "../../assets/icons/StarIcon";
import CpuIcon from "../../assets/icons/CpuIcon";
import RobotIcon from "../../assets/icons/RobotIcon";
import ArrowBendUpLeftIcon from "../../assets/icons/ArrowBendUpLeftIcon";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
import EmojiPicker from "../Emoji/EmojiPicker";

let fileUplods = [];
const CommentSection = ({
  show,
  handleClose,
  commentList,
  mentionList,
  handleAddOrEditComment,
  loading,
  handleDeleteComment,
  handleSearchComment,
  isReadOnly = false,
  docType = "",
  external = false,
  data,
  comment,
  meetingUrl,
  contacts = [],
  ImageViewerComments=false,
  commanCommentSection=false,
  guestcommentCount = 0,
  callUserList = [],
  showFullscreen=false,
  setSelectedAttachment = () => {},
  setShowTaskImageDropDown = () => {},
  showCloseIcon,
  isRelativeView=false,
  IsKanbanView = false,
  disableFullScreenToggle=false,
  isExternal,
  setShowFullscreen,
  handleAction,
  setShowCommentModal,
  setActiveTaskID=()=>{},
  onCloseShowLeftPanelDefault,
  type,
  handleTaskRating =()=>{},
  taskRating = 0,
  getTaskComments,
  selectedAttachment,
  iscallModal = false,
  pageName,
  aiIsTypingLease
}) => {
  const { id, page } = useParams();
  
  const [showSearch, setShowSearch] = useState(false);
  const [searchComment, setSearchComment] = useState("");
  const [commentInput, setCommentInput] = useState("");
  const [commentId, setCommentId] = useState(0);
  const [isMentionListShow, setIsMentionListShow] = useState(false);
  const [comments, setComments] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [previewSrc, setPreviewSrc] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [allAttachments, setAllAttachments] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const handleCloseShareLink = () => {setShowLink(false); setMailForComment({})};
  const handleShowShareLink = () => setShowLink(true);
  const [meetingModal, setMeetingModal] = useState(false);
  const [task, setTask] = useState(data ? data : []);
  const [showExternalImage, setshowExternalImage] = useState(false);
  const [mailForComment, setMailForComment] = useState({})
  const [viewAIContext, setViewAIContext] = useState(false);
  const [waitingForAIResponse, setWaitingForAIResponse] = useState(false);
  const guestUsrerCommentsCount = JSON.parse(
    localStorage.getItem("guest_comment")
  );
  const projectId = localStorage.getItem("selectedOffice")
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showAianalyticsModal, setShowAianalyticsModal] = useState(false);
  const scheduleChecklistId = searchParams.get("update-schedule-checklist");
  const [showToast, setShowToast] = useState(false);
  const [toastHeader, setToastHeader] = useState(_l("l_are_you_sure"));
  const [confirmButtonText, setConfirmButtonText] = useState(_l("l_yes"));
  const [cancelButtonText, setCancelButtonText] = useState(_l("l_no"));
  const [toastClass, setToastClass] = useState("toast-with-btn");
  const [toastMessage, setToastMessage] = useState(_l("l_you_want_to_create_summarize_task"));
  const [parentPreview, setParentPreview] = useState({})
  const {calendarCanvasIsOpen, isCallFullscreen,callComments, isTodoTable, taskList, leftPanelTab ,leaseDocAction, commanoffcanvasIsOpen, showCommentSckeleton } = useSelector((state) => state.customer);
  const {callStatus, isCallRecived } = useSelector((state) => state.chat);
  const [showEmoji, setShowEmoji] = useState(false);
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;
  const [taskCreationLoader, setTaskCreationLoader] = useState(false);
  const [isSkeltonShow, setIsSkeltonShow] = useState(true);


  let contextRelType = ""
  let contact_role  = localStorage.getItem("contact_role")
  const onClick = (emojiData) => {
    setCommentInput((commentInputCallback)=>commentInputCallback + emojiData.emoji)
  }
  const handleEmojiSelect = (emoji) => {
    setCommentInput((commentInputCallback)=>commentInputCallback + emoji)
  };

  useEffect(() => {
    if (commentList && commentList.length) {
      setComments(commentList);
      scrollToBottom();
      commentList.map((item) => {
        if (item.attachments && item.attachments.length > 0) {
          item.attachments.map((item2) => {
            setAllAttachments((allAttachments) => [
              ...allAttachments,
              {
                id: item2.id,
                task_id: data ? data.id : 0,
                task_comment_id: item.id,
                image_url: item2.file_name,
                dateadded: item.dateadded,
              },
            ]);
          });
        }
      });
    } else {
      setComments(comment);
    }
  }, [commentList]);

  useEffect(() => {
    jQuery(".office-list-icon, #openCommentModal,#left-office-list,.office-box").on(
      "click",
      function () {
        if (show && commanoffcanvasIsOpen) {
          localStorage.removeItem("externalTaskData");
          localStorage.removeItem("externalTaskHash");
          handleAction("modalClose");
          dispatch(setMailReply(false));
          dispatch(setActionForLeaseDocument());
          dispatch(setTypeOfLeaseDocument());
          dispatch(setCommentIDtoHighlight());
          if (leftPanelTab == "homepage" && docType != "imageview") {
            addexpandedClassIntoOffice();
          }
          if(docType ==="imageview" || (window.location.pathname === "/providerlist" && docType === "task")){
            dispatch(setImageViewerisOPen(false));   
            if(jquery('#commanCanvasImageBody').length == 1){
              if(jquery('#commonCanvasBody').length == 0){
                if(jquery(".modal-full-left-panel").length > 1){
                  if(jquery('body').hasClass('with-right-penel')){
                  }else{
                    jquery('body').addClass('with-right-penel');
                  }
                  jquery('body').addClass('task-modal-offcanvas-open');
                  }else{
                  jquery('body').removeClass('with-right-penel');
                  jquery('body').removeClass('task-modal-offcanvas-open');
                }
                  jquery('body').addClass('with-left-penel');
              }
              if((jquery('.comman-comments').length == 0)&& jquery("#commonCanvasBody").length ==1){
                  jquery('body').removeClass('with-right-penel');
                  jquery('body').addClass('with-left-penel');
              }
          }
          dispatch(setTaskAttchmentsForCreate())
          }if (window.location.pathname === "/providerlist" && ["estimate", "subscription", "invoice", "documents"].includes(docType)) {
            
          }else{
          dispatch(updateHideLoader(false));
          if (docType !== "imageview") {
            dispatch(commanoffcanvasisOpen(false))
          }
          dispatch(commanoffcanvasisOpenfromCalendar(false))
          if (calendarCanvasIsOpen) {
            setActiveTaskID(0)
          }

          if (onCloseShowLeftPanelDefault) {
            TaskModalClose(onCloseShowLeftPanelDefault);
          } else {
            if(scheduleChecklistId > 0)
            {
              toggleLeftPanelAndOffcanvasClasses(true);
            }
            else {
              if (docType !== "imageview") {
                TaskModalClose();
              }
            }
          }
          if (type == "lead") {
            dispatch(leadModalToggle(false));
          }
          if ((projectId == 0 || projectId == null || projectId == undefined) && (window.location.pathname != "/dashboard")) {
            if (docType !== "gmail") {
              if (window.location.pathname !== "/my-drive") {
                hideLeftPanelNew();
              }
            }
            if (window.location.pathname === "/providerlist" && docType === "provider_page") {
              jquery('#intro-office-list').addClass('expanded');
              localStorage.removeItem("currentCompanyID");
            }
          }else if ((window.location.pathname == "/dashboard")){
            showLeftPanelNew();
          }else if (window.location.pathname === "/providerlist" && docType === "provider_page") {
            jquery('#intro-office-list').addClass('expanded');
            localStorage.removeItem("currentCompanyID");
          }
        }
        localStorage.removeItem("is_pilot_onboarding")
        }
      }
    );
  }, []);

  useEffect(() => {
    if (msgListDiv && msgListDiv.scrollHeight) {
      scrollToBottom();
    }
  }, [msgListDiv]);

  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const userType = localStorage.getItem("user_type");
  const contact_image = localStorage.getItem("contact_image");
  const contact_full_name = localStorage.getItem("full_name");
  const msgListDiv = document.getElementById("comment-list");

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    fileUplods = files;
    setImagePreviews(previrewArray);
  };
  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(fileUplods).filter(
      (img) => img.name != name
    );
    preparePreview(updatedFileList);
  };

  const dispatch = useDispatch();
  const editComment = (id) => {
    const targetComment = comments.filter((comment) =>
      docType == "proposal" ? comment.comment_id == id : comment.id === id
    );
    if (targetComment.length) {
      setCommentId(id);
      setCommentInput(
        targetComment[0].content ||
          targetComment[0].comment ||
          targetComment[0].description
      );
    }
  };
  const mentionListToggle = () => {
    if (!isMentionListShow) {
      setCommentInput("@");
      jQuery("#comment_input").focus();
    } else {
      setCommentInput("");
    }
    setIsMentionListShow(!isMentionListShow);
  };
  const scrollToBottom = () => {
    if (commentList.length) {
      try {
        if (msgListDiv && msgListDiv.scrollHeight && msgListDiv.scrollHeight != null)
          msgListDiv.scrollTop = msgListDiv.scrollHeight
            ? msgListDiv.scrollHeight
            : 0;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const setAttchmentOnTop = (id) => {
    const targetAttchement = allAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = allAttachments.filter(
      (attachment) => attachment.id != id
    );
    setAllAttachments(targetAttchement.concat(otherAttachments));
  };
  const handleImagePreview = (comment, image, index) => {
    if (!external && ["imageview", "task", "request-task"].includes(docType)) {
      let preview = {};
      preview.url = getPreviewFromURL(image.file_url || image.file_name);
      if (typeof preview.url !== "string") {
        const link = document.createElement("a");
        link.href = image.file_url;
        link.download = image.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setShowTaskImage(true);
        let attachmentForTask = {
          id: image.id,
          task_id: data ? data.id : 0,
          task_comment_id: comment.id,
          image_url: image.file_name,
          dateadded: comment.dateadded,
          latitude: comment.latitude,
          longitude: comment.longitude,
        };
        setAttachmentDetails(attachmentForTask);
        setSelectedAttachment(attachmentForTask);
        dispatch(setTaskAttchmentsForCreate(attachmentForTask));
      }
    } else {
      let selectAttachment = comment.attachments.filter(
        (file, innerIndex) => innerIndex == index
      );
      let otherAttachment = comment.attachments.filter(
        (file, innerIndex) => innerIndex != index
      );
      setPreviewSrc(
        selectAttachment.concat(otherAttachment).map((src) => {
          return {
            src: src.file_name,
          };
        })
      );
    }
  };
  let previrewArray = [];
  let preview_url = " ";
  const setCopiedFiles = (e) => {
    if (e.clipboardData.files.length <= 5) {
      e.preventDefault();
      Array.from(e.clipboardData.files).map((img) => {
        preview_url = getImagePreviewIcon(img);
        previrewArray.push({ name: img.name, url: preview_url });
        setImagePreviews(previrewArray);
        fileUplods = e.clipboardData.files;
      });
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_maximum_5_files_are_allowed_at_a_time")
        )
      );
      return;
    }
  };

  const getDocTypeForExternal = () => {
    let externalDocType = data.taskDetails ? 'task' : docType;
    switch(docType)
    {
      case "premiumEstimate":
        externalDocType = "estimate";
        break;
    }
    return externalDocType;
  }

  useEffect(() => {
    if (msgListDiv && msgListDiv.scrollHeight) {
      scrollToBottom();
    }
  }, [comments, msgListDiv]);

  const hideCommentSection = () => {
    let flag = false;
    let selectedProposal = localStorage.getItem("proposalId");

    if (data.proposal_id && data.proposal_status == 2) {
      flag = true;
    } else if (
      data.proposal_list &&
      data.proposal_list.length &&
      selectedProposal
    ) {
      let list = data.proposal_list[0].proposals;
      let proposalDetails = list.find((x) => x.proposal_id == selectedProposal);
      if (proposalDetails && proposalDetails.status == 2) {
        flag = true;
      }
    }
    return flag;
  };

  useEffect(() => {
    if (selectedAttachment && selectedAttachment.image_url) {
      let preview = {};
      preview.url = getPreviewFromURL(selectedAttachment.image_url);
      setParentPreview(preview)
    }
  }, [selectedAttachment])

  useEffect(() => {}, [parentPreview])
  
  

  const commentSectionContain = (
    <div className="task-detail-right-main h-100 d-flex flex-column res-width-100 task-detail-right-main position-relative w-100"
    onClick={()=>{
      dispatch(setActionForLeaseDocument());
    }}>
      
        <>
          <div
            className={`border-bottom Comment-header h70px p-10 d-flex ${
              showCloseIcon ? "pb22px" : ""
            }`}
          >
            <div className="d-flex align-items-center w-100">
              <div className="right-task-header flex-grow-1 p-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className={`task-header-left-options ${isRelativeView ? "":"d-none"}`}>
                    <div className="d-flex align-items-center justify-content-between">
                      {isRelativeView ? (
                        <div className="d-flex align-items-center title-fonts  color-white">
                          <div className="h32w32 comman-image-box with-bg rounded-circle bg-white-05">
                            <div id="chat-screen-recipient-image" className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{backgroundImage:`url('')`}}>

                            </div>
                          </div>
                          <div id="chat-screen-recipient-name" className="w100minus30 ps-2 text-truncate"></div>
                        </div>
                      ) : (
                        <div className="title-fonts fw-semibold color-white d-none">
                          {_l("l_comments")}
                        </div>
                      )}
                      <div className="dropend d-none">
                        <a href="#/" className="comman_action_icon with_separator_10 before-dnone on-hover-active-toggle-img">
                          <div className="action_icon with_bg">
                            <PushPinIcon
                              className="HW18"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="task-header-right-option flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <a href="#/" className="action_icon d-none">
                        <MagnifyingGlassIcon
                          className="HW18"
                        />
                      </a>
                      <a href="#/"
                        className="action_icon ml-10 on-hover-active-toggle-img d-none on-hover-active-toggle-img"
                        
                      >
                        <UploadSimpleIcon
                          className="HW18"
                        />
                      </a>
                      {NO_EXTERNAL_URLS.includes(docType) || (data && data.taskDetails && data.taskDetails.id  && data.taskDetails.id == "CreateTask") ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <a href="#/"
                          id="commentOptionDropdown on-hover-active-toggle-img"
                          data-bs-toggle="dropdown"
                          onClick={() => {}}
                        >
                          <div className="btn btn-primary btn-sm-2 text-uppercase line-height-1 action_icon">
                            {_l("l_share")} 
                          </div>
                        </a>
                      )}
                      { external ? <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_copy_link"),
                            actionHandler: () => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${getDocTypeForExternal()}/${data.hash ? data.hash : data.taskDetails.hash ? data.taskDetails.hash : ""}`
                              );
                              dispatch(
                                showMessage(
                                  "sucess",
                                  _l("success"),
                                  _l("l_link_copied")
                                )
                              );
                            },
                            actionIcon: "CopyIcon",
                          }
                        ]}
                      /> : <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_copy_link"),
                            actionHandler: () => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${getDocTypeForExternal()}/${data.hash ? data.hash : data.taskDetails.hash ? data.taskDetails.hash : ""}`
                              );
                              dispatch(
                                showMessage(
                                  "sucess",
                                  _l("success"),
                                  _l("l_link_copied")
                                )
                              );
                            },
                            actionIcon: "CopyIcon",
                          },
                          {
                            actionName: _l("l_send_to_email"),
                            actionHandler: () => {
                              setShowLink(true);
                            },
                            actionIcon: "EnvelopeSimpleIcon",
                          },
                          {
                            actionName: _l("l_open_link"),
                            actionHandler: () => {
                              window.open(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${data.taskDetails ? 'task' : docType}/${data.hash ? data.hash : data.taskDetails.hash ? data.taskDetails.hash : ""}`
                              );
                            },
                            actionIcon: "LinkSimpleIcon",
                          },
                        ]}
                      />
                      }
                      {
                        isRelativeView
                        ?
                        <React.Fragment></React.Fragment>
                        :
                        <>
                        <a href="#/"
                          className="action_icon ml-10 on-hover-active-toggle-img d-none h32w32 on-hover-active-toggle-img"
                           
                           
                           
                          onClick={() => {
                            if (meetingUrl && meetingUrl != "") {
                              let data = {
                                link: meetingUrl,
                                newTaskFlag: 0,
                                newRequestFlag: 0,
                                task_id: task ? task.id : 0,
                              };
                              dispatch({
                                type: SET_NEW_MEETING,
                                payload: { data },
                              });
                            } else {
                              setMeetingModal(true);
                            }
                          }}
                        >
                          <VideoCameraIcon
                            className="HW18"
                          />
                        </a>
                        <div className="d-flex align-items-center ms-auto comman_action_icon">
                        {(showAIContextModal() && adminAsStaff && (data.parent_id == 0 || !data.parent_id) || docType == "leaseDocument") &&  !["edit","filelog"].includes(leaseDocAction) ?
                        <>
                        <div className={`d-flex align-items-end justify-content-end ${docType == "leaseDocument" && external ? 'd-none' : ''}`}
                            
                            
                            >
                        <Button variant="" className="btn-white-05" size="sm"
                        onClick={()=>{
                          if (data.sub_task_id > 0) {
                            dispatch(
                              getSelectedTask(
                                localStorage.getItem("selectedOffice"),
                                data.sub_task_id
                              )
                            );
                          } else {    
                            setShowToast(true)
                            setShowAianalyticsModal(true);
                          }
                        }}>
                       {data.sub_task_id > 0 ? _l("open_linked_task") : taskCreationLoader ? _l("l_please_wait") : _l("l_summarize")}
                          {taskCreationLoader && data.sub_task_id == 0 ? <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2 `}
                              aria-hidden="true"
                            />
                          </> : <></>}
                          </Button>
                        </div>
                        </>
                 
                            : <></>
                          }
                        {showAIContextModal() && adminAsStaff && data.parent_id > 0?
                        <>
                        <div className="d-flex align-items-end justify-content-end"
                            
                            
                            >
                        <Button variant="" className="btn-white-05" size="sm"
                        onClick={()=>{
                          dispatch(
                            getSelectedTask(
                              localStorage.getItem("selectedOffice"),
                              data.parent_id
                            )
                          );
                        }}>
                          {_l("open_linked_task")}
                       
                          </Button>
                        </div>
                        </>
                 
                            : <></>
                          }

                        {!external &&
                      data.addedfrom > 0 &&
                      ["imageview", "task", "request-task"].includes(
                        docType
                      ) ? (
                        <></>
                      ) : (
                        <></>
                      )}
                          <div className={`before-dnone with_separator_10 ${(IsKanbanView || disableFullScreenToggle || ImageViewerComments || (docType == "leaseDocument" && !localStorage.getItem("accessToken"))) || !localStorage.getItem("accessToken") ? "d-none" : ""}`}>
                            {showFullscreen || isCallFullscreen ? (
                              <a href="#/"
                                className={`action_icon with_bg h32w32 d-flex on-hover-active-toggle-img  ${isExternal ? "d-none" : ""}`}
                                onClick={() => {
                                  if (iscallModal) {
                                    handleAction("modalClose");
                                    TaskModalClose();
                                    dispatch(setCallFullscreen(false));                                    
                                    showLeftPanelNew();
                                    showContactDirectory();
                                    dispatch(commanoffcanvasisOpen(false));
                                    if (leftPanelTab == "homepage") {
                                        jquery("#intro-office-list").addClass("expanded");
                                    }
                                  } else { 
                                    setShowFullscreen(false);
                                    handleAction("showFullscreen", false);
                                    setShowCommentModal(true);
                                    dispatch(setExternalShowFullscreen(false));
                                  }

                                }}
                              >
                                <CornersInIcon
                                  className="HW18 zoom_in"
                                />
                              </a>
                            ) : (
                              <a href="#/"
                                className={`action_icon with_bg h32w32 d-flex on-hover-active-toggle-img ${isExternal ? "d-none" : ""}`}
                                onClick={() => {
                                  if (iscallModal) {
                                    dispatch(setCallFullscreen(false));
                                    TaskModalClose()
                                  }else{
                                  setShowFullscreen(true);
                                  handleAction("showFullscreen", true);
                                  setShowCommentModal(true);
                                  dispatch(setExternalShowFullscreen(true));
                                  }

                                }}
                              >
                                <CornersOutIcon
                                  className="HW18 zoom_out"
                                />
                              </a>
                            )}
                          </div>
                         
                         {!isCallFullscreen? <a href="#/"
                            type="button"
                            className={`modal-close action_icon with_bg h32w32 d-flex position-relaive modal-close-button z-index-5 on-hover-active-toggle-img ${isExternal ? page == "dashboardDocuments" && id ? '' : "d-none" : ""
                              }`}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => {
                              localStorage.removeItem("TaskHashLink");
                              localStorage.removeItem("TaskTypeLink");
                              localStorage.removeItem("currentLeftPanelTab", "my-request");
                              localStorage.removeItem("externalTaskData");
                              localStorage.removeItem("externalTaskHash");
                              handleAction("modalClose");
                              dispatch(setMailReply(false));
                              dispatch(setActionForLeaseDocument());
                              dispatch(setTypeOfLeaseDocument());
                              dispatch(setCommentIDtoHighlight());
                              if (leftPanelTab == "homepage" && docType != "imageview") {
                                addexpandedClassIntoOffice();
                              }
                              if(docType ==="imageview" || (window.location.pathname === "/providerlist" && docType === "task")){
                                dispatch(setImageViewerisOPen(false));   
                                if(jquery('#commanCanvasImageBody').length == 1){
                                  if(jquery('#commonCanvasBody').length == 0){
                                    if(jquery(".modal-full-left-panel").length > 1){
                                      if(jquery('body').hasClass('with-right-penel')){
                                      }else{
                                        jquery('body').addClass('with-right-penel');
                                      }
                                      jquery('body').addClass('task-modal-offcanvas-open');
                                      }else{
                                      jquery('body').removeClass('with-right-penel');
                                      jquery('body').removeClass('task-modal-offcanvas-open');
                                    }
                                      jquery('body').addClass('with-left-penel');
                                  }
                                  if((jquery('.comman-comments').length == 0)&& jquery("#commonCanvasBody").length ==1){
                                      jquery('body').removeClass('with-right-penel');
                                      jquery('body').addClass('with-left-penel');
                                  }
                              }
                              dispatch(setTaskAttchmentsForCreate())
                              }if (window.location.pathname === "/providerlist" && ["estimate", "subscription", "invoice", "documents"].includes(docType)) {
                                
                              }else{
                              dispatch(updateHideLoader(false));
                              if (docType !== "imageview") {
                                dispatch(commanoffcanvasisOpen(false))
                              }
                              dispatch(commanoffcanvasisOpenfromCalendar(false))
                              if (calendarCanvasIsOpen) {
                                setActiveTaskID(0)
                              }

                              if (onCloseShowLeftPanelDefault) {
                                TaskModalClose(onCloseShowLeftPanelDefault);
                              } else {
                                if(scheduleChecklistId > 0)
                                {
                                  toggleLeftPanelAndOffcanvasClasses(true);
                                }
                                else {
                                  if (docType !== "imageview") {
                                    TaskModalClose();
                                  }
                                }
                              }
                              if (type == "lead") {
                                dispatch(leadModalToggle(false));
                              }
                              if ((projectId == 0 || projectId == null || projectId == undefined) && (window.location.pathname != "/dashboard")) {
                                if (docType !== "gmail") {
                                  if (window.location.pathname !== "/my-drive") {
                                    hideLeftPanelNew();
                                  }
                                }
                                if (window.location.pathname === "/providerlist" && docType === "provider_page") {
                                  jquery('#intro-office-list').addClass('expanded');
                                  localStorage.removeItem("currentCompanyID");
                                }
                              }else if ((window.location.pathname == "/dashboard")){
                                showLeftPanelNew();
                              }else if (window.location.pathname === "/providerlist" && docType === "provider_page") {
                                jquery('#intro-office-list').addClass('expanded');
                                localStorage.removeItem("currentCompanyID");
                              }
                            }
                            localStorage.removeItem("is_pilot_onboarding")
                            }}
                             
                             
                             
                          >
                            <XIcon className="HW16" />
                          </a> :<></>}
                        </div>  
                        </>
                      }
                      {meetingModal ? (
                        <MeetingProviderModal
                          meetingProvider={meetingModal}
                          HandleClose={() => {
                            setMeetingModal(false);
                          }}
                          selectedTask={data}
                          commentSection={1}
                        />
                      ) : (
                        <></>
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
              {external ? (
                <></>
              ) : (
                <a href="#/"
                  type="button"
                   
                   
                   
                   
                  className={`modal-close with_separator_10 pe-0 position-relaive  z-index-5 on-hover-active-toggle-img ${
                    showCloseIcon ? "" : "d-none"
                  }`}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <XIcon className="HW18" />
                </a>
              )}
            </div>
          </div>
          <div className="comman-tab-wrapper pb-0">
            <div className="d-flex align-items-center w-100">
              <div className="tab-wrapper d-flex align-items-center justify-content-center flex-grow-1 d-none">
                <a href="#/" className="tab-name">{_l("l_comments")}</a>
                <a href="#/" className="info-icon">
                  <InfoIcon
                    className="HW18 opacity-50"
                  />
                </a>
              </div>
              <div className="active d-flex align-items-center justify-content-center flex-grow-1 d-none">
                <a href="#/" className="tab-name">{_l("l_internal_rating")}</a>
                <a href="#/" className="info-icon on-hover-active-toggle-img">
                  <InfoIcon
                    className="HW18 opacity-50"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="right-chat-wrapper position-relative d-flex flex-column">
            {showSearch ? (
              <div className="search-with-close p-0 m-0 border-bottom">
                <div className="d-flex align-items-center ">
                  <div className="comment-input-box bg-base-header-color pb-0 radius_3  w-100">
                    <input
                      className="bg-base-header-color border-0 c-font f-12 form-control p-2 radius_3"
                      placeholder={`${_l("l_search")}`}
                      value={searchComment}
                      onChange={(e) => {
                        setSearchComment(e.target.value);
                        handleSearchComment(e.target.value);
                      }}
                    />
                  </div>
                  <a href="#/"
                    className="bg-base-header-color border-left d-flex h35w35 line-height-0"
                    onClick={() => {
                      setShowSearch(false);
                      setSearchComment("");
                      handleSearchComment("");
                    }}
                  >
                    <div className="img-width-18 m-auto on-hover-active-toggle-img">
                    <XIcon className="HW16" />
                    </div>
                  </a>
                </div>
                <div className="pill-wrapper d-none">
                  <a href="#/"
                    className="comman_action_icon filter-list-view d-none"
                    id="filter-show-list"
                  >
                    <div className="action_icon with_bg_03 h35w35 on-hover-active-toggle-img">
                      <div className="img-width-18 m-auto">
                        <MagnifyingGlassIcon
                          size={16}
                          className="HW16"
                        />
                      </div>
                    </div>
                  </a>
                  <div id="show-filter-list" className="filter-list-toggle">
                    <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                      <div className="d-flex commn-search radius_3 w-100 justify-content-between">
                        <div className="comment-input-box">
                          <input
                            type="text"
                            id="task-search-string"
                            className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                            placeholder={`${_l("l_search")}`}
                            data-bs-toggle="tooltip"
                          />
                        </div>
                        <a href="#/" className="comman_action_icon on-hover-active-toggle-img">
                          <div className="d-flex align-items-center ">
                            <div className="action_icon h25w25">
                              <MagnifyingGlassIcon
                                className="HW14"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="chat-main-box flex-grow-1 comman_vertical_btn_h">
              <div className="d-flex flex-column h-100">
                <div className="c-font f-12 py-1 px-2 title-fonts color-white-60 border-bottom d-none">
                  {_l("l_comments")}
                </div>

                <div className="chat-wrapper WithBorderLeftRight position-relative" id="comment-list">
                  {/* ChatBoxSkeleton start  */}
                {
                  showCommentSckeleton ?
                    ["0", "1", "2"].map((s) => {
                      return <ChatBoxSkeleton />
                    }) :
                    <>

                    </>
                }
                  {/* ChatBoxSkeleton end */}
                  {comments && comments.length ? (
                    comments.map((comment, index) => {

                      const replacements = [
                        { keyword: '{username}', replacement: comment.mention_staff_name },
                        { keyword: '{mention_id}', replacement: comment.mention_id },
                        { keyword: '{mention_type}', replacement: comment.mention_type },
                        { keyword: '{sender_name}', replacement: comment.sender_name },
                      ];
                      comment.content = stringReplacer(replacements,_l(comment.content));
                      const commentContent =
                        comment.content ||
                        comment.comment ||
                        comment.description;
                      const dummyDiv = document.createElement("div");
                      dummyDiv.innerHTML = commentContent;
                      return (
                        <div
                          className={`chat-box ${
                            (userType == "contact" &&
                              contact_id === comment.contact_id) ||
                            (userType == "staff" && staff_id == comment.staffid)
                              ? ""
                              : ""
                          } ${
                            comment.is_allowed_to_edit == 1 || (isCallRecived && comment.staffid == localStorage.getItem("staff_id"))
                              ? "right-side-msg"
                              : ""
                          }`}
                          key={index}
                        >
                          <div className="d-flex">
                            <div
                              className="h32w32 comman-image-box with-bg rounded-circle bg-white-05"
                            
                               
                               
                            >
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('${comment.profile_url ? comment.profile_url : comment.addedfrom_details ? comment.addedfrom_details.profile_url : ""}')`,
                                }}
                              ></div>
                            </div>
                            <div className={`chat-msg`}>
                              <div className="chat-text-box"
                              onClick={()=>{
                                if (docType === "leaseDocument") {
                                  dispatch(setCommentIDtoHighlight(comment.id))
                                }

                              }}>
                                <div
                                  className={`p-0 w-100 ${
                                    comment && comment.parent_comment_id > 0
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <div className="bg-transparent  p-0 w-100">
                                    <div>
                                      <ArrowBendDownLeftIcon
                                        className="HW14 m-auto"
                                      />
                                    </div>
                                    <div className="border-bottom image-upload-wrapper mb-2 w-100">
                                      <div className="d-flex align-items-center flex-wrap base-body-color radius_3">
                                        {
                                          comment && comment.parent_comment_details && !comment.parent_comment_details.file_url
                                          ?
                                          <span className="p-1">{ReactHtmlParser(
                                            comment && comment.parent_comment_details && comment.parent_comment_details.content.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "<br>"
                                            )
                                          )}</span>
                                          :
                                          <a href="#/" class="h40w40 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3  mb-2">
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover"
                                              style={{
                                                backgroundImage: `url(${
                                                  comment &&
                                                  comment.parent_comment_details
                                                    ? `${comment.parent_comment_details.file_url}`
                                                    : ""
                                                })`,
                                              }}
                                            ></div>
                                          </a>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="image-upload-wrapper">
                                  {comment.attachments &&
                                  comment.attachments.length ? (
                                    <div className="d-flex align-items-center flex-wrap ">
                                      {comment.attachments.map(
                                        (image, index) => {
                                          if (index < 2) {
                                            let preview = {};
                                            preview.url = getPreviewFromURL(
                                              image.file_name
                                            );

                                            return (
                                              <>
                                              {["wav","mp3"].includes(getFileDetails(image.file_url || image.file_name))
                                              ? <>
                                                    <div className="chat-audio withf12 small">
                                                      <AudioPlayer
                                                        style={{ width: '200px', margin: "0px" }}
                                                        layout="horizontal"
                                                        className="audio_player p-0 bg-transparent box-shadow-none "
                                                        showJumpControls={false}
                                                        showSkipControls={false}
                                                        customAdditionalControls={[]}
                                                        customVolumeControls={[]}
                                                        src={image.file_url || image.file_name}
                                                        autoPlay={false}
                                                        autoPlayAfterSrcChange={false}
                                                      />
                                                    </div>
                                              </> :
                                              <a href="#/"
                                                key={index}
                                                className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05  radius_3 with-top-overlay"
                                              >
                                                  <div
                                                    className="absolute-download-icon"
                                                    onClick={() => {
                                                      window.open(
                                                        image.file_url ||
                                                        image.file_name,
                                                        "_self"
                                                      );
                                                    } }
                                                  >
                                                    <DownloadSimpleIcon className="HW14" />
                                                  </div>
                                                  {typeof preview.url ===
                                                    "string" ? (
                                                    <div
                                                      className="comman-bg-img h-100 w-100 bg-style-cover"
                                                      onClick={() => {
                                                        if (external) {
                                                          setshowExternalImage(
                                                            true
                                                          );
                                                          setAttchmentOnTop(
                                                            image.id
                                                          );
                                                        } else {
                                                          handleImagePreview(
                                                            comment,
                                                            image,
                                                            index
                                                          );
                                                        }
                                                      } }
                                                      style={{
                                                        backgroundImage: `url('${image.file_url || image.file_name}')`,
                                                      }}
                                                    ></div>
                                                  ) : (
                                                    <>
                                                      <div
                                                        className="h-100 w-100 d-flex"
                                                        style={{
                                                          alignContent: "center",
                                                          flexWrap: "wrap",
                                                        }}
                                                      >
                                                        <preview.url
                                                          size={72}
                                                          weight="light"
                                                          className="c-icons" />
                                                      </div>
                                                      <div className="max-w-130px pt-2 d-none">
                                                        <div className="c-font text-truncate f-12 color-white-60 ">
                                                          {decodeURIComponent(
                                                            image.file_name.substring(
                                                              image.file_name.lastIndexOf(
                                                                "/"
                                                              ) + 1
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </a>}</>
                                            );
                                          } else if (index === 2) {
                                            return (
                                              <a href="#/"
                                                className="h60w60 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-1 with_overlay"
                                                onClick={() => {
                                                  if (external) {
                                                    setshowExternalImage(true);
                                                    setAttchmentOnTop(image.id);
                                                  } else {
                                                    handleImagePreview(
                                                      comment,
                                                      image,
                                                      index
                                                    );
                                                  }
                                                }}
                                              >
                                                <div class="unread_count m-auto">
                                                  +
                                                  {comment.attachments.length -
                                                    2}
                                                </div>
                                              </a>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {comment.description === undefined ? (
                                        <>
                                          <div className="text-start">
                                            {ReactHtmlParser(
                                              comment.content && comment.content.replace(
                                                /(?:\r\n|\r|\n)/g,
                                                "<br>"
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        " "
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="chat-text">
                                  {" "}
                                  {comment.description
                                    ? ReactHtmlParser(
                                        comment.description.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        )
                                      )
                                    : ""}
                                </div>
                                <div
                                  className={`dropdown dropdown-center chat-action-option on-hover-active-toggle-img ${
                                    comment.is_allowed_to_edit == "1" || (isCallRecived && comment.staffid == localStorage.getItem("staff_id"))
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <a href="#/"
                                    className="line-height-0 "
                                    type="button"
                                    id="commentOptionDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                     
                                     
                                     
                                     
                                  >
                                    <DotsThreeVerticalIcon
                                      className="HW18"
                                    />
                                  </a>
                                  <ActionDropdown
                                    actions={[
                                      {
                                        actionName: _l("l_edit"),
                                        actionHandler: () => {
                                          editComment(
                                            docType == "proposal"
                                              ? comment.comment_id
                                              : comment.id
                                          );
                                        },
                                        actionIcon: "PencilSimpleLineIcon",
                                      },
                                      {
                                        actionName: _l("l_send_to_email"),
                                        actionHandler: () => {
                                          setMailForComment(comment);
                                          setShowLink(true);
                                        },
                                        actionIcon: "EnvelopeSimpleIcon",
                                      },
                                      {
                                        actionName: _l("l_delete"),
                                        actionHandler: () => {
                                          docType == "proposal"
                                            ? handleDeleteComment(
                                                comment.comment_id,
                                                comment.comment
                                              )
                                            : handleDeleteComment(comment.id);
                                        },
                                        actionIcon: "TrashIcon",
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="chat-send-name c-font f-10 opacity-50 d-flex justify-content-start align-items-center">
                                <span className="me-2">{moment(comment.dateadded).format("DD/MM/YYYY hh:mm A")}</span>
                                <span>{comment.date}</span>
                                <span className="delivered-status-icon message-read d-none">
                                  <CheckIcon className="HW14 msg-status ms-2 read-msg color-green" />
                                  <ChecksIcon className="HW14 msg-status ms-2 msg-delivered-double" />
                                  <CheckIcon className="HW14 msg-status ms-2 msg-delivered-single" />
                                </span>
                              </div>
                            </div>
                             
                          </div>
                           
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <CommanPlaceholder
                        mainSpacing="h-100"
                        imgType="no-comment"
                        placeholderText={_l("l_no_comments")}
                      />
                    </>
                  )}
                  
                </div>
                {
                    waitingForAIResponse || aiIsTypingLease
                    ?
                      <div className="ps-20pximp"> 
                        <Typing isFrom = {aiIsTypingLease ? "ai_lease" :'' } />
                      </div>
                      :
                      <></>
                  }
                <React.Fragment>
                  {external ? (
                    <div className="align-items-center bg bg-white-05 box-shadow-2 d-flex justify-content-between p-2 color-white">
                      <span className="f-12 c-font">
                        {_l("l_login_as_user")}
                      </span>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        {_l("l_login")}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {external &&
                  (guestcommentCount > 7 ||
                    (guestUsrerCommentsCount &&
                      guestUsrerCommentsCount[0].comment_count >= 7)) ? (
                    <div className="align-items-center bg bg-white-05 box-shadow-2 d-flex justify-content-between p-2 btn btn-outline-danger for-disabled">
                      <span className="f-12 c-font">
                        {_l("l_you_have_reached_your_maximum_limit")}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {imagePreviews && imagePreviews.length ? (
                    <div className="upload-image-preview p-2">
                      <div className="d-flex align-items-center justify-content-start">
                        {imagePreviews.map((preview, index) => {
                          if (typeof preview.url === "string") {
                            return (
                              <div
                                key={index}
                                className="comman-image-box h60w60 radius_3 upload-image with-margin mt-2"
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                  style={{
                                    backgroundImage: `url(
                                                '${preview.url}'
                                              )`,
                                  }}
                                ></div>
                                <a href="#/"
                                  className="upload-close"
                                  onClick={() => {
                                    removeImageSelection(preview.name);
                                  }}
                                >
                                  <XIcon className="HW10" />
                                </a>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={index}
                                className="comman-image-box h60w60 radius_3 upload-image with-margin d-flex"
                              >
                                <preview.url
                                  size={40}
                                  weight="light"
                                  className="c-icons"
                                />
                                <a href="#/"
                                  className="upload-close"
                                  onClick={() => {
                                    removeImageSelection(preview.name);
                                  }}
                                >
                                  <XIcon className="HW10" />
                                </a>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
                {hideCommentSection() ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <>
                  {showEmoji ?
                    <EmojiPicker onEmojiSelect={handleEmojiSelect} />
                    : <></>
                  }
                  <div
                    className={`chat-comment-box  border-0 p-0 ${
                      isReadOnly ||
                      guestcommentCount > 7 ||
                      (guestUsrerCommentsCount &&
                        guestUsrerCommentsCount[0].comment_count >= 7)
                        ? "for-disabled"
                        : ""
                    }`}
                  >
                    {
                      selectedAttachment && Object.keys(selectedAttachment).length
                      ?
                      <div className="aicommentbox-reply p-2 light-theme-border border-bottom-0 border-start-0 border-end-0" id="aicommentbox-reply">
                        <div className="d-flex flex-wrap align-items-center">
                          <ArrowBendUpLeftIcon className="HW18" />
                          <div className="overflowscroll w100minus30">
                              <div className="d-flex align-items-center justify-content-start bgspacelisting lightthemebgblue radius_3 overflow-hidden p-1 ">
                                  <div className="h50w50 with-bg d-flex align-items-center bg-white-05">
                                    {
                                      typeof parentPreview.url === "string" ? <>
                                      <div className="comman-bg-img h-100 w-100 bg-style-cover " style={{ backgroundImage: `url('${parentPreview.url}')` }}></div>
                                      </> : 
                                      <>
                                      <parentPreview.url size={72} weight="light" className="c-icons"/>
                                      </>
                                    }
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div> 
                      :
                      <React.Fragment></React.Fragment>
                    }
                    <div className="d-flex m-auto flex-column chat-main-box border-top">
                    <div className={`comment-input-box border-bottom p-10 ${waitingForAIResponse || aiIsTypingLease ? 'for-disabled' :''}`}>
                      <div className="d-flex align-items-center position-relative ">
                        <div
                          className="h32w32 comman-image-box rounded-circle"
                           
                           
                           
                        >
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            style={{
                              backgroundImage: `url(${contact_image})`,
                            }}
                          ></div>
                        </div>
                        <div
                          className="mension-box mension-box--multiLine withpl7px"
                          style={{
                            position: "relative",
                            overflowY: "visible",
                          }}
                        >
                          <MentionsInput
                            value={commentInput}
                            className="mension-box"
                            placeholder={`${_l("l_write_comment_placeholder")}`}
                            alwaysOpen={true}
                            style={mentionInputStyle}
                            onChange={(e) => {
                              setCommentInput(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.keyCode === 13 &&
                                !e.ctrlKey &&
                                !e.shiftKey
                              ) {
                                e.preventDefault();
                                aiMentionAddOrEditComment()
                              }
                            }}
                            id="comment_input"
                          >
                            <Mention
                              markup='<span style="font-weight: 600;"  contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;"  data-mce-selected="1">@__display__</span>'
                              trigger="@"
                              data={mentionList}
                              displayTransform={(id,display) => `@${display}`}
                              style={mentionStyle}
                              renderSuggestion={(
                                suggestion,
                                search,
                                highlightedDisplay
                              ) => {
                                return (
                                  <div className="user d-flex align-items-center">
                                    <div className="comman-image-box h32w32 rounded-circle mx-2">
                                      {suggestion.isteam == 1 ? (
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                                          {" "}
                                          <UsersThreeIcon className="HW18 m-auto opacity-60" />{" "}
                                        </div>
                                      ) : (
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${suggestion.image}')`,
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                    {highlightedDisplay}
                                  </div>
                                );
                              }}
                            />
                          </MentionsInput>
                        </div>
                           
                        <a href="#/"
                          className="send-arrow-btn with_position_right_zero on-hover-active-toggle-img"
                           
                           
                           
                           
                          onClick={() => {
                            aiMentionAddOrEditComment();
                          }}
                        >
                          <ArrowRightIcon
                            className="HW18"
                          />
                        </a>
                      </div>
                    </div>
                    </div>
                    <div className="comment-bottom-option p-10">
                      <div className="d-flex align-items-center">
                        <div
                          className={`comman_action_icon mr-10 ${
                            docType == "proposal" ? "pe-none" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="action_icon with_bg h32w32 on-hover-active-toggle-img"
                               
                               
                               
                               
                              onClick={() => {
                                mentionListToggle();
                              }}
                            >
                              <AtIcon
                                className="HW18"
                              />
                            </a>
                          </div>
                        </div>
                        <div
                          className={`comman_action_icon mr-10 Forbeforednone  ${
                            external ? "for-disabled d-none" : ""
                          } `}
                        >
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img"
                               
                               
                               
                               
                            >
                              <input
                                type="file"
                                multiple="multiple"
                                className="absolute-input"
                                accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                                onChange={(event) => {
                                  if (event.target.files.length > 4) {
                                    dispatch(
                                      showMessage(
                                        "unsucess",
                                        _l("l_error"),
                                        _l(
                                          "l_maximum_4_files_are_allowed_at_a_time"
                                        )
                                      )
                                    );
                                    return;
                                  }
                                  preparePreview(event.target.files);
                                }}
                                onClick={(e) => {
                                  e.target.value = "";
                                }}
                              />
                              <PaperclipIcon
                                className="HW18"
                              />
                            </a>
                          </div>
                        </div>
                        {docType == "task" || docType == "request-task" ? 
                        <UncontrolledDropdown className={`w-100 h-100 ${userType == "contact" && JSON.parse(contact_role) != 3 ? "" : "for-disabled" }`}>
                            <DropdownToggle className="p-0" data-toggle="dropdown" tag="span">
                                <a href="#/" className="comman_action_icon on-hover-active-toggle-img"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false">
                                        <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                                            <StarIcon className="HW18" />
                                        </div>
                                </a>
                            </DropdownToggle>
                            <DropdownItem toggle={false} className="p-0">
                            <StarRatingDropdown 
                            handler={(starValue, customRating)=>{
                              handleTaskRating(starValue, customRating)
                            }}
                            rating={taskRating}
                            />
                            </DropdownItem>
                        </UncontrolledDropdown>
                      :
                      <></>}
                      {
                        showAIContextModal() && adminAsStaff ? 
                        <>
                          <div className={`comman_action_icon mr-10 ${waitingForAIResponse ? 'disabledElenent' :''}`}>
                            <a href="#/"
                              className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img"
                               
                               
                               
                               
                              onClick={() => {setViewAIContext(true)}}
                            >
                            <CpuIcon
                              className="HW18"
                            />
                            </a>
                          </div>
                          <div className="comman_action_icon mr-10">
                            <a href="#/"
                              className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img"
                               
                               
                               
                               
                              onClick={() => autoUpdateAIContext()}
                            >
                            <RobotIcon
                              className="HW18"
                            />
                            </a>
                          </div>
                        </>
                          :
                          <></>
                      }
                        <div className="comman_action_icon mr-10">
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className={`action_icon with_bg h32w32 with-border-active on-hover-active-toggle-img ${showEmoji ? "active":""}`}
                              onClick={() => { setShowEmoji(!showEmoji) }}
                            >
                              <SmileyIcon className="HW18" />

                            </a>
                            </div>
                        </div>
                        
                        <div className="comman_action_icon with_separator_10 d-none">
                          <div className="d-flex align-items-center">
                            <a href="#/" className="action_icon with_bg h32w32 on-hover-active-toggle-img">
                              <HashIcon
                                className="HW18"
                              />
                            </a>
                          </div>
                        </div>
                        {
                          isRelativeView
                          ?
                          <React.Fragment></React.Fragment>
                          :
                          <div className="d-flex comman_action_icon  for-accessible before-dnone ms-auto">
                          {docType == "leaseDocument" ? 
                            <div className="comman_action_icon  for-accessible before-dnone ms-auto pe-2">
                              <div className="d-flex align-items-center">
                                <a href="#/"
                                  className="action_icon with_bg h32w32 on-hover-active-toggle-img"
                                   
                                   
                                   
                                  onClick={() => {
                                    aiMentionAddOrEditComment(1); 
                                  }}
                                >
                                  <RobotIcon
                                    className="HW18"
                                  />
                                </a>
                              </div>
                            </div>
                            : <></>}
                          <div className="comman_action_icon  for-accessible before-dnone ms-auto">
                            <div className="d-flex align-items-center">
                              <a href="#/"
                                className="action_icon with_bg h32w32 on-hover-active-toggle-img"
                                 
                                 
                                 
                                 
                                onClick={() => {
                                  setShowSearch(!showSearch);
                                }}
                              >
                                <MagnifyingGlassIcon
                                  className="HW18"
                                />
                              </a>
                            </div>
                          </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
     
    </div>
  );

  const fetchAIContextForTask = async() => {
    let contextDescription = "";
    let taskContext = "";
    let attachmentId = 0;
    let parentCommentId = 0;
    let projectId = data.rel_id || data.space_project_id;
    let taskId = data.id;

    if(docType === "imageview")
    {
      taskId = selectedAttachment.task_id;
      attachmentId = selectedAttachment.id;
      parentCommentId = selectedAttachment.task_comment_id;
      projectId = data.projectId || data.space_project_id;
    }

    try {
      const response = await CustomerServices.fetchTaskAIContext(taskId, projectId, attachmentId, parentCommentId);
      if(response.status)
      {
        contextDescription = response.data;
        taskContext = response.extra && response.extra.task_context ? response.extra.task_context : "";
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      return {contextDescription, taskContext};
    }
  };

  const saveTaskAIContext = async(content, message, save_and_send, is_refresh_context, new_comment, ) => {

    try {

      let taskId = data.id;
      let attachmentId = 0;
      let parentCommentId = 0;
      let projectId = data.rel_id || data.space_project_id;
      let imgurl = '';
      if(docType === "imageview")
      {
        taskId = selectedAttachment.task_id;
        attachmentId = selectedAttachment.id;
        parentCommentId = selectedAttachment.task_comment_id;
        projectId = data.projectId || data.space_project_id;
        imgurl = selectedAttachment.image_url;
      }
      const response = await CustomerServices.saveTaskAIContext(
        taskId, 
        projectId, 
        content, 
        message, 
        save_and_send, 
        is_refresh_context, 
        attachmentId, 
        parentCommentId, 
        imgurl, 
        new_comment,
        "", 
      );
      if(response.status) { 
      }
      else if(response.message) {
        showError(response.message);
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      console.error(e);
    }
    finally {
      if(!is_refresh_context)
      {
        getTaskComments(0, 0, docType === "imageview" ? 1 : 1);
        setViewAIContext(false);
      }
      setShowAianalyticsModal(false);
    }
  };

  const handleCreateSummarizeTask = async(content, message, save_and_send, is_refresh_context, new_comment, ) =>{
    setShowToast(false);
    setTaskCreationLoader(true)
    let descriptionContext = ""
    let generalContext = ""
    try {
      try {
        const { contextDescription, taskContext } = await fetchAIContextForTask();
          descriptionContext = contextDescription.replace(
            /<br>/g,
            "\n"
          );
          generalContext = taskContext.replace(
            /<br>/g,
            "\n"
          );
        } catch (e) {
        console.error(e);
      } 

      let taskId = data.id;
      let attachmentId = 0;
      let parentCommentId = 0;
      let projectId = data.rel_id || data.space_project_id;
      let imgurl = '';
      if(docType === "imageview")
      {
        taskId = selectedAttachment.task_id;
        attachmentId = selectedAttachment.id;
        parentCommentId = selectedAttachment.task_comment_id;
        projectId = data.projectId || data.space_project_id;
        imgurl = selectedAttachment.image_url;
      }
  
      const response = await CustomerServices.saveTaskAIContext(
        taskId, 
        projectId, 
        descriptionContext + generalContext, 
        message, 
        save_and_send, 
        is_refresh_context, 
        attachmentId, 
        parentCommentId, 
        imgurl, 
        new_comment,
        "", 
        1 
      );
      if(response.status)
      {
          if (response.message && response.message.length > 0) {
            let newList = []
            response.message.map((msg)=>{
              newList.push({
                addedfrom: contact_id,
                description: msg.trim(),
                finished: "0",
                id: Math.floor(Math.random() * 10000000).toString().padStart(7, '0'),
                is_added_from_contact: "1",
                list_order: "0",
                req_photo: "0",
                addedfrom_details: { profile_url: contact_image, full_name : localStorage.getItem("full_name") },
                comments_count: 0,
                links_count: 0,
                attachments_count: 0,
                is_allowed_to_edit: 1,
                status: 0,
                space_item_id: 0, 
                item_id:  0 ,
                dateadded : new Date(),
                start_date : new Date(),
                update_schedule_id: 0,
                days:  {},
                item_name: msg.trim(),
                assignee_id: 0,
                type_of_space_item_id : 0,
                room_id: 0 
              });
            })
            CustomerServices.createNewTask(
              projectId,
              0,
              1,
              [],
              "",
              `summary task of - ${data.name}`,
              0,
              0,
              undefined,
              2,
              false,
              [],
              newList,
              [],
              [],
              new Date(),
              [
                {
                  main_key: "my_task",
                  categories : [{
                    filter_key :"assigned_to_me",
                    main_sub_key : "my_task",
                    sub_category : []
                  }]
              }],
              0,
              0,
              0,
              [],
              taskId
            ).then((res)=>{
              if (res && res.status) {
                handleAddOrEditComment({
                  content: "Summariaze task created successfully",
                  files: fileUplods,
                  editCommentId: commentId,
                });
                let taskId = res.data.task_id
                dispatch(
                  getSelectedTask(
                    localStorage.getItem("selectedOffice"),
                    taskId
                  )
                );
                let index = taskList.findIndex((t) => t.id == data.id);
                let tempdata = taskList;
                if (index > -1) {
                    tempdata[index].sub_task_id = res.data.task_id;
                }
                dispatch(updateTaskList(tempdata));
                showMessage("l_task_created")
              }else{
                setTaskCreationLoader(false);
              }
            })
          }else{
            setTaskCreationLoader(false)
          }
      }
      else if(response.message) {
        showError(response.message);
        setTaskCreationLoader(false);
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      console.error(e);
    }
    finally {
     
    }
  }
  const autoUpdateAIContext = async() => {
    try {
      let commentInputSet = commentInput
      setCommentInput("");
      setWaitingForAIResponse(true);
      document.getElementById("comment_input").blur();
      const {contextDescription, taskContext} = await fetchAIContextForTask();
      let contextValue = "";
      let newComment = "";
      if (docType === "task" || docType === "request-task") {
        newComment = commentInputSet.trim();
      }
      if(docType === "imageview")
      {
        newComment = commentInputSet.trim();
        contextValue = contextDescription;
      }
      else {
        contextValue = contextDescription + taskContext;
      }
      await saveTaskAIContext(contextValue, "", "", "", newComment);
      if(newComment.length)
      {
        setCommentInput("");
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong");
    }
    finally {
      setWaitingForAIResponse(false);
      document.getElementById("comment_input").focus();
    }
  };

  const aiMentionAddOrEditComment = (is_ai_comment = 0) => {
    
    // find contact role proccess --- Start ---
    let mentionIdArry = [];
    let contact_roleArray = [];
    let regex = /data-mention-id="([^"]*)"/g;
    const mentionedId = [...commentInput.matchAll(regex)];
    for (const match of mentionedId) {
        mentionIdArry.push(match[1]);
    }
    mentionList && mentionList.forEach((item) => {
      if (item.id && mentionIdArry && mentionIdArry.includes(item.id)){
          contact_roleArray.push(item.contact_role)
      }
    })
    // Find contact role proccess --- End ---
    if (contact_roleArray && contact_roleArray.includes('4')) {
      autoUpdateAIContext();
    }else{
      handleAddOrEditComment({
        content: commentInput,
        files: fileUplods,
        editCommentId: commentId,
        is_ai_comment : is_ai_comment
      });
      setShowEmoji(false)
      setCommentId(0);
      setCommentInput("");
      setImagePreviews([]);
      fileUplods = [];
      scrollToBottom();
    }
  }
  function showAIContextModal () {
    if(((["task", "request-task"].includes(docType) && data.rel_type === "project" && data.rel_id > 0) ||( data.rel_type === "equipment" && data.space_project_id > 0)) && userType === "contact"  && Number(contact_role) === 1)
    {
      contextRelType = "task";
      return true;
    }
    else if(docType === "imageview" && userType === "contact" && data.taskId > 0 && (data.projectId > 0 || data.space_project_id > 0) )
    {
      contextRelType = "attachment";
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (!jquery.isEmptyObject(callStatus)) {
      if (callStatus.commentsList && callStatus.commentsList.length > 0) {
        setComments(removeDuplicates(callStatus.commentsList))
      }
      
    }
  }, [callStatus])  
  useEffect(() => {
    if (callComments && callComments.length > 0) {
        setComments(removeDuplicates(callComments))  
    }
  }, [callComments])  
  return (
    <React.Fragment>
         {showToast ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            handleCreateSummarizeTask();
          }}
        />
      ) : (
        <></>
      )}
      {
        <div
          className={`${isRelativeView ? "h-100" : ""}`}
          onPaste={(e) => setCopiedFiles(e)}
        >
          <Lightbox
            open={openGallery}
            close={() => setOpenGallery(false)}
            slides={previewSrc}
          />
          {isRelativeView ? (
            <div className="offcanvas offcanvas-end taskDetailModal position-relative w-100 show z-index-5imp onboarding-floor-bg">
              <div className="p-0  offcanvas-body">{commentSectionContain}</div>
            </div>
          ) : (
            <Offcanvas
              className={`taskDetailModal custom-offcanvas-modal custom-modal-style stand-alone-comments 
                ${isTodoTable ? 'z-index-12imp' : ''}
                ${ImageViewerComments ? "image-view-comment" : ""}
                ${commanCommentSection ? "" : "comman-comments"}
                ${pageName !== "MyEmail" && (showFullscreen && !ImageViewerComments) ? "full-comment-width400" : ""}
                ${isCallFullscreen ? "full-comment-width400" : ""}
              `}
              id={`${
                docType === "imageview"
                  ? "commonCanvasImageComments"
                  : "comment_close"
              } `}
              placement="end"
              scroll={false}
              backdrop={false}
              show={show}
              keyboard={false}
              onHide={() => {
                handleClose();
                CloseCommentModal();
              }}
              onShow={() => {
                tabindexFix();
              }}
            >
              
                <Offcanvas.Body className={`p-0 `}>
                  {commentSectionContain}
                   
                </Offcanvas.Body>
              
            </Offcanvas>
          )}
          {showTaskImage && Object.keys(attachmentDetails).length > 0 ? (
            <CommanOffCanvas
              show={showTaskImage}
              handleClose={() => {
                setShowTaskImage(false);
              }}
              data={{
                id: attachmentDetails.id,
                taskId: docType == "imageview" ? data.taskDetails.id : data.id,
                projectId: localStorage.getItem("selectedOffice"),
                attachmentDetails: attachmentDetails,
                taskDetails: data.taskDetails ? data.taskDetails : data,
                allAttachments: allAttachments,
                parent_comment_details:
                  attachmentDetails.parent_comment_details,
              }}
              docType={"imageview"}
              setSelectedId={setSelectedAttachmentId}
              selectedTaskId={data.id}
              commentList={commentList}
              handleParentComponentAction={() => {}}
              CloseCommentModal={CloseCommentModal}
              isChat={true}
              isCommentsection={true}
            />
          ) : (
            <></>
          )}
          {show ? (
            <TaskShareLinkModal
              show={showLink}
              handleClose={handleCloseShareLink}
              handleShow={handleShowShareLink}
              link={`${process.env.REACT_APP_MYR_WEB_URL}/${data.taskDetails ? 'task': docType}/${ data && data.hash ? data.hash : data.taskDetails && data.taskDetails.hash ? data.taskDetails.hash : ""}`}
              data={{...data, contacts: contacts }}
              docType={docType}
              mailForComment={mailForComment}
            />
          ) : (
            <></>
          )}
          {showExternalImage ? (
            <>
              <Lightbox
                open={showExternalImage}
                close={() => setshowExternalImage(false)}
                slides={allAttachments.map((img) => {
                  return { src: getPreviewFromURL(img.image_url) };
                })}
              />
            </>
          ) : (
            <></>
          )}
          {
            viewAIContext ?
              <AIContextModal
                relType={contextRelType}
                show={viewAIContext}
                handleClose={() => {
                  setViewAIContext(false);
                  setShowAianalyticsModal(false);
                }}
                loadDescription={fetchAIContextForTask}
                handelSave={saveTaskAIContext}
                isanalysis={showAianalyticsModal}
              />
            :
              <></>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default CommentSection;
