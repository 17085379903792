import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import TwoStepAccordians from "../Accordians/TwoStepAccordians";
import { useDispatch, useSelector } from "react-redux";
import {
  dayGridDay,
  dayGridMonth,
  dayGridWeek,
  leftPanelFilters,
  setLeftPanelTab,
  setOpenOrCloseContactDirectoryChat,
  setProject,
  setSelectedGloablView,
  setDaterangeFilterApplied,
  emailTokenExpired,
  commanoffcanvasisOpen,
  setDaterangeFilter,
  setFilterSpaceProjectid,
  SetSelectedTask,
  getDefaultTopics,
} from "../../actions/customer";
import { TaskModalClose, _l, formateDate, hideRightPanelNew, removeExpandedformIntro, showLeftPanelNew } from "../../hooks/utilities";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import AddTaskIcon from "../../assets/icons/AddTaskIcon";
import RequestTaskIcon from "../../assets/icons/RequestTaskIcon";
import moment from "moment";
import { showConfirmation } from "../../actions/messages";
import { getPilotOnboardingDocumentDetail } from "../../actions/documents-actions/documents-actions";
import Spinner from "react-bootstrap/esm/Spinner";
import logo from "../../assets/images/myr-ai-logo-circle.svg";
import MyDrive from "../LeftPanelDrive/MyDrive";
import KPISkeleton from "../Skeleton/KPISkeleton";
import GaugeIcon from "../../assets/icons/GaugeIcon";
import BuildingsIcon from "../../assets/icons/BuildingsIcon";
import UserGearIcon from "../../assets/icons/UserGearIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";
import UserPlusIcon from "../../assets/icons/UserPlusIcon";
import UsersIcon from "../../assets/icons/UsersIcon";
import WrenchIcon from "../../assets/icons/WrenchIcon";
import EnvelopeIcon from "../../assets/icons/EnvelopeIcon";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import MagnifyingGlassIcon from "../../assets/icons/MagnifyingGlassIcon";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
import ChatIcon from "../../assets/icons/ChatIcon";
import FileTextIcon from "../../assets/icons/FileTextIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import CaretLeftIcon from "../../assets/icons/CaretLeftIcon";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import PhoneDisconnectIcon from "../../assets/icons/PhoneDisconnectIcon";
import HardDrivesIcon from "../../assets/icons/HardDrivesIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import GearSixIcon from "../../assets/icons/GearSixIcon";

const noActiveSpacePageNames = [
  "invoice",
  "estimate",
  "subscription",
  "staff",
  "benchmark",
  "schedule",
  "CompanyProfile",
  "Proposals",
  "MyProfile",
  "client",
  "fullcalendar",
  "AdminTaskPage",
  "credit_notes",
  "_equipments",
  "PaymentPage",
];

const hideLeftPanelFooter = [
  "Proposals",
  "dashboard",
  "estimate",
  "staff",
  "client",
  "fullcalendar",
  "AdminTaskPage",
  "subscription",
  "credit_notes",
  "_equipments",
  "invoice",
  "credit_notes",
  "subscription",
  "schedule",
  "benchmark",
  "leadPage",
  "MyProfile",
  "SpaceOnBoarding",
];

let SPACE_LIST_RESULTS_PER_PAGE = 10;

const LeftPanelSubMenu = ({
  setCompletedTasksCount = () => {},
  setInProgressTasksCount = () => {},
  setTodoTasksCount = () => {},
  projectId,
  searchCategory,
  pageName,
  teamLeftPanel,
  setSelectOperators = () => {},
  setShowScheduleDropDown = () => {},
  setShowProviderDropDown = () => {},
  allMAilMessages,
  allImportantMessages,
  hideMailSyncBtn,
  createTeamModal,
  setCreateTeamModal = () => {},
  equipmentModal,
  setEquipmentModal = () => {},
  selectAdminTeam = () => {},
  selectedTeam,
  clientTeamData,
  handleDeleteTeam,
  handleFavoriteAdminTeam,
  handleEditAdminTeam,
  topicType,
  setTopicType = () => {},
  selectedTeamForEdit,
  setSelectedTeamForEdit = () => {},
  showAddTeamMember,
  setshowAddTeamMember = () => {},
  adminTeamList,
  setAdminTeamList = () => {},
  showUserList,
  searchCategoty,
  searchTopic,
  showForMeSection,
  setShowForMeSection = () => {},
  spaces = [],
  setSearchString = () => {},
  searchString,
  setshowMyProfile = () => {},
  showMyProfile,
  selectedOffice,
  onClickOfficeChange = () => {},
  projectTaskCount,
  setSelectedOffice = () => {},
  setSelectedProject = () => {},
  selectedSpace = {},
  setSelectedSpace = () => {},
  contactDirData,
  contactDirTopicChange = () => {},
  contactlistShow,
  setContactlistShow = () => {},
  setsearchTopic = () => {},
  getspacedata = () => {},
  spaceData = [],
  setUserList = () => {},
  admin,
  adminUser,
  contact_role,
  createChatChannels = () => {},
  setproject_id = () => {},
  setshowProjectStatusList = () => {},
  getUserList = () => {},
  setClientIdLoginAsUser = () => {},
  setManagePeopleModal = () => {},
  setshowOpenAiModal = () => {},
  setspaeContent = () => {},
  setOfficeId = () => {},
  setShowToast = () => {},
  prepareToast = () => {},
  setSelectedspaceStatus = () => {},
  setShowDocumentCanvas = () => {},
  showProjectStatusList,
  projectStatusArray,
  userList,
  setTopicMainKey = () => {},
  setSettingTopicName = () => {},
  handleChangeProjectStatus = () => {},
  loginHandler = () => {},
  selectedspaceStatus,
  isLoginAsUserDataLoaded,
  handleChangeViewtoFavView = () => {},
  isSynced,
  tenantesList= [],
  setEmailModalOpenSet = () => {}
}) => {
  let defaultTaskStatus = [0, 1, 4, 5];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { leftPanelTab, isAllChatUserlistShow, virtualSpaceList } = useSelector((state) => state.customer);

  const userType = localStorage.getItem("user_type");
  const [loder, setLoder] = useState(false);
  const [activeOfficePage, setActiveOfficePage] = useState(1);
  const is_agent = localStorage.getItem("is_agent") == 1 ? "1" : "0";
  const currTab = localStorage.getItem("currentLeftPanelTab");
  const [showSearch, setShowSearch] = useState(false)

  const handleChangeTab = (tab, navigation) => {
    TaskModalClose();
    dispatch(SetSelectedTask());
    localStorage.setItem("currentLeftPanelTab", tab);
    dispatch(commanoffcanvasisOpen(false));
    dispatch(setLeftPanelTab(tab));
    if (navigation) {
      navigate(navigation);
    }

    if (tab == "my-tenants") {
      localStorage.removeItem("activeGroupId");
      localStorage.removeItem("selectedTopic");
      dispatch(getDefaultTopics(0, "", "", null));
    }
    if (tab == "my-contacts") {
      dispatch(setOpenOrCloseContactDirectoryChat(true));
      setContactlistShow(true);
      contactDirTopicChange({}, false);
    } else {
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      setContactlistShow(false);
      localStorage.removeItem("isContactDir");
    }

    if (tab == "my-calendar") {
      dispatch(setSelectedGloablView("calendarView"));
      dispatch(dayGridDay(false));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(true));
      dispatch(leftPanelFilters("","","applyForMeAndMonth"));
      localStorage.setItem(
        "fromDate",
        formateDate(moment(new Date()).startOf("month"))
      );
      localStorage.setItem(
        "toDate",
        formateDate(moment(new Date()).endOf("month"))
      );
      dispatch(setDaterangeFilterApplied(true));
    } else {
      dispatch(dayGridDay(true));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(false));
      dispatch(setDaterangeFilterApplied(false));
      handleChangeViewtoFavView();
    }

    if (tab !== "my-space") {
       localStorage.removeItem("activeGroupId")
    }

    if (tab == "my-staff") {
      const generlaSpace = virtualSpaceList.length > 0 && virtualSpaceList[0]
      Object.assign(generlaSpace, {
        is_virtual_space : 1, 
        is_project_member : 1, 
        project_status : 1 , 
        project_id : virtualSpaceList[0].id 
      });
      if (generlaSpace) {
        onClickOfficeChange(generlaSpace)
      }
    }

    if (tab == "my-request" || tab == "my-drive") {
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }else if(tab !== "my-calendar"){
      dispatch(setDaterangeFilter("today"))
      dispatch(setDaterangeFilterApplied(false));
      dispatch(leftPanelFilters("","","all"))
    }

    if (contact_role == 3 && tab == "my-space") {
      localStorage.setItem("fav_dashboard", "AichatView")
      dispatch(setSelectedGloablView("AichatView"));;
    }

    hideRightPanelNew();
    showLeftPanelNew();
  };

  useEffect(() => {
    if (leftPanelTab == "my-email") {
    } else if (leftPanelTab !== "my-space") {
      setSelectedOffice(0);
      setSelectedProject(0);
    }
  }, [leftPanelTab]);

  useEffect(() => {
    if (leftPanelTab == "my-space" && contact_role == 3 && spaces && spaces.length > 0 && localStorage.getItem("selectedOffice") != spaces[0].project_id ) {
      onClickOfficeChange(spaces[0], false);
    }
  }, [leftPanelTab, JSON.stringify(spaces)]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Get the menu element by its ID
      const menuElement = document.getElementById('profile-menu');
      
      // Check if the menu element exists and if the click is outside of it
      if (menuElement && !menuElement.contains(event.target)) {
        // setIsOpen(false); // Example action: close the menu
        setshowMyProfile(false)
      }
    };
  
    // Attach the event listener to document
    document.addEventListener('mousedown', handleClickOutside);
  
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        id="intro-office-list"
        className="fixed-left-panel office-list intro-office-list"
      >
        <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
          <div className="upper-part ">
            <div className="company-logo-box ps14px">
              <div className="d-flex align-items-center justify-content-between h-100">
                <div className="d-flex align-items-center justify-content-between w100minus70 flex-grow-1 office-list-left">
                  <a
                    className={`${userType == "staff" ? 'h40w40' : 'h40w40'} d-flex align-items-center justify-content-center ic-provider-list office-list-circle radius_3 border-0 comman-round-box`}
                    onClick={() => {
                      handleChangeTab("homepage", "/");
                    }}
                  >
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover"
                      style={{
                        backgroundImage: `url(${userType == "staff" ? logo : localStorage.getItem('company_logo_header')})`,
                      }}
                    ></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ul className="office-list-main mt-0 pt-10px" id="left-office-list">
            {/* ----------------------------- My Request start ----------------------------- */}
            <li
              className={`office-box spaceicon ps-0 pb-0 pt-0 on-hover-active-toggle-img ${
                leftPanelTab == "my-request" ? "active" : ""
              }`}
            >
              <a
                href="#/"
                className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                onClick={() => {
                  if (leftPanelTab != "my-request") {
                    localStorage.removeItem("selectedOffice");
                    dispatch(setSelectedGloablView("listView"));
                    handleChangeTab("my-request", "/");
                  }
                }}
              >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <GaugeIcon className="HW28" />
                    </div>
                    </div>
                  <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                  {_l("l_my_tasks")}
                  </span>
              </a>
            </li>
            {/* ----------------------------- My Request End ----------------------------- */}

            {/* ----------------------------- My Spaces start ----------------------------- */}

            {userType === "operator" && contact_role == 3 && is_agent == 1 ? <React.Fragment></React.Fragment> : 
            <li
              className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                leftPanelTab == "my-space" ? "active" : ""
              }`}
            >
              <a
                href="#/"
                className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                onClick={() => {
                  if (leftPanelTab != "my-space") {
                    handleChangeTab("my-space", "/");
                    setSelectedProject(0);
                    dispatch(setProject(0));
                    setSelectedSpace({});
                    setSelectedOffice(0);
                  }
                }}
              >
                <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <BuildingsIcon className="HW28" />
                    </div>
                    </div>
                  <span className=" color-white-80 d-block c-font f-9 pb-10 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                  {contact_role == 3 ? _l("l_chat_modal") : _l("l_space")}
                  </span>
              </a>
            </li>}
            {/* ----------------------------- My Spaces End ----------------------------- */}

            {/* ----------------------------- My Clients start ----------------------------- */}

            {userType === "operator" && contact_role != 3 ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "my-clients" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "my-clients") {
                      handleChangeTab("my-clients", "/");
                    }
                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <UserGearIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                    {_l("l_my_clients")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* ----------------------------- My Clients End ----------------------------- */}

            {/* ----------------------------- My Agents start ----------------------------- */}

            {userType === "operator" && contact_role != 3 ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "my-agents" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "my-agents") {
                      handleChangeTab("my-agents", "/");
                      dispatch(setSelectedGloablView("AichatView"))
                    }

                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <UserCircleIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                    {_l("l_my_agents")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* ----------------------------- My Agents End ----------------------------- */}

            {/* ----------------------------- chat Agents start ----------------------------- */}

            {userType === "operator" && contact_role == 3 && is_agent == 1 ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "chat-agents" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "chat-agents") {
                      handleChangeTab("chat-agents", "/");
                      dispatch(setSelectedGloablView("AichatView"))
                    }

                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <UserCircleIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                    {_l("l_chat_modal")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* ----------------------------- chat Agents End ----------------------------- */}

            {/* ----------------------------- My Providers start ----------------------------- */}
            {["1", "2"].includes(String(contact_role)) && userType === "contact" ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "my-providers" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "my-providers") {
                      dispatch(setFilterSpaceProjectid(0));
                      handleChangeTab("my-providers", "/providerlist");
                    }
                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <UserPlusIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                      {_l("l_providers")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* ----------------------------- My Providers End ----------------------------- */}
            
            {/* ----------------------------- My Tenants start ----------------------------- */}
            {userType == "contact" && contact_role != 3 ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "my-tenants" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box "
                  onClick={() => {
                    if (leftPanelTab != "my-tenants") {
                      handleChangeTab("my-tenants", "/");
                      dispatch(setSelectedGloablView("AichatView"));
                    }
                  }}
                >
                  <div className="comman-round-box title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <UsersIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                      {_l("l_tenants")}
                    </span>
                  </div>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}
 
            {/* ----------------------------- My Tenants End ----------------------------- */}


            {/* ----------------------------- Equipments start ----------------------------- */}
            {["1", "2"].includes(String(contact_role)) && userType === "contact" ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "equipments" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box "
                  onClick={() => {
                    if (leftPanelTab != "equipments") {
                      handleChangeTab("equipments", "/equipment-table");
                    }
                  }}
                >
                  <div className="comman-round-box title-fonts d-flex flex-column justify-content-center align-items-center square_box h-100">
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <WrenchIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                      {_l("l_equipments")}
                    </span>
                  </div>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}
 
            {/* ----------------------------- Equipments End ----------------------------- */}
            
            {/* ----------------------------- Staff List start ----------------------------- */}
            {userType === "staff" ? (
              <li
                className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                  leftPanelTab == "my-staff" ? "active" : ""
                }`}
              >
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "my-staff") {
                      handleChangeTab("my-staff", "/");
                    }
                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <UsersIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                      {_l("l_staff")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* -----------------------------Staff List End ----------------------------- */}

            {/* ----------------------------- Leads start ----------------------------- */}
            {userType === "staff" ? (
              <li className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img  ${leftPanelTab == "my-leads" ? "active" : ""}`}>
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "my-leads") {
                      handleChangeTab("my-leads", "/leads");
                    }
                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <PhoneDisconnectIcon className="HW28" />
                    </div>
                    </div>
                    <span className="color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                      {_l("l_leads")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* -----------------------------Leads End ----------------------------- */}

            {/* ----------------------------- Equipments start ----------------------------- */}
            {userType === "staff" ? (
              <li className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${leftPanelTab == "equipments" ? "active" : ""}`}>
                <a
                  href="#/"
                  className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                  onClick={() => {
                    if (leftPanelTab != "equipments") {
                      handleChangeTab("equipments", "/equipment-table");
                    }
                  }}
                >
                  <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <WrenchIcon className="HW28" />
                    </div>
                    </div>
                    <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                      {_l("l_equipments")}
                    </span>
                </a>
              </li>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {/* -----------------------------Equipments End ----------------------------- */}
            
          </ul>
          <div className="lower-part  mt-auto border-top pt-0">
            <ul className="office-list-main">
                {/* ----------------------------- My Email start ----------------------------- */}
            {contact_role != 3 ? <li
              className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                leftPanelTab == "my-email" ? "active" : ""
              }`}
            >
              <a
                href="#/"
                className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                onClick={() => {
                  dispatch(emailTokenExpired(true))
                    if (leftPanelTab != "my-email") {
                      handleChangeTab("my-email", "/MyEmail");
                    }
                }}
              >
                <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <EnvelopeIcon className="HW28" />
                    </div>
                    </div>
                  <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                  {_l("l_email")}
                  </span>
              </a>
            </li> : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Email End ----------------------------- */}

            {/* ----------------------------- Drive start ----------------------------- */}
            {contact_role != 3 ? 
            <li
              className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                leftPanelTab == "my-drive" ? "active" : ""
              }`}
            >
              <a
                href="#/"
                className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                onClick={() => {
                  if (leftPanelTab != "my-drive") {
                    localStorage.setItem("lastLeftPanelTab", leftPanelTab)
                    handleChangeTab("my-drive", "/my-drive");
                  }
                }}
              >
                <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <HardDrivesIcon className="HW28" />
                    </div>
                    </div>
                  <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                    {_l("l_drive")}
                  </span>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- Drive End ----------------------------- */}

            {/* ----------------------------- Calendar start ----------------------------- */}
            {contact_role != 3 ? 
            <li
              className={`office-box spaceicon ps-0 pb-0 on-hover-active-toggle-img ${
                leftPanelTab == "my-calendar" ? "active" : ""
              }`}
            >
              <a
                href="#/"
                className="h70w70 d-flex align-items-center justify-content-center ic-provider-list office-list-circle comman-round-box  title-fonts flex-column square_box"
                onClick={() => {
                  if (leftPanelTab != "my-calendar") {
                    handleChangeTab("my-calendar", "/");
                  }
                }}
              >
                <div className="p-20 py-0 text-center">
                    <div className="action_icon h32w32">
                      <CalendarBlankIcon className="HW28" />
                    </div>
                    </div>
                  <span className=" color-white-80 d-block c-font f-9 pb-1 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                    {_l("l_calendar")}
                  </span>
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- Calendar End ----------------------------- */}
            </ul>
            <div className="position-relative left-option-box mt-0 d-flex new_user_profile_bottom ms-0 mb-0">
              <a
                className={`d-flex  align-items-center border-transparent justify-content-center flex-column hovertexgreen h70w70 comman-round-box ${leftPanelTab == "dashboard" ? 'activeDashboard' :''}`}
                onClick={() => {
                  setshowMyProfile(!showMyProfile);
                }}
              >
                <div
                  className={`h32w32 comman-round-box with-bg d-flex align-items-center bgOfficelisting rounded-circle border-transparent position-relative ${
                    userType == "staff"
                      ? "staff_user"
                      : userType == "operator"
                      ? "operator_user"
                      : "contact_user"
                  }`}
                >
                  {/* contact_user operator_user staff_user */}
                  <div
                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex on-hover-active-toggle-img"
                    style={{
                      backgroundImage: `url(${localStorage.getItem(
                        "contact_image"
                      )})`,
                    }}
                  ></div>
                </div>
                <div class="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 text-truncate text-center w-100 ">
                  {_l("l_profile")}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-left-panel provider-list">
        {/* --------------- Space Listing Start --------------- */}
        {leftPanelTab == "my-space" && projectId < 1 ? (
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
            <div className="upper-part ">
              {/* <div className="company-logo-box position-relative d-flex flex-column justify-content-center"> */}
                <div className="d-flex align-items-center justify-content-between company-logo-box position-relative">
                  <div className="d-flex w100minus100 flex-grow-1 pe-1 flex-column">
                    {projectId > 0 ? (
                      <a
                        href="#/"
                        className=" d-flex pe10px"
                        onClick={() => {
                          setSelectedProject(0);
                          dispatch(setProject(0));
                          setSelectedSpace({});
                          setSelectedOffice(0);
                        }}
                      >
                        <CaretLeftIcon className="HW16" />
                      </a>
                    ) : (
                      <></>
                    )}
                      <div className="title-fonts fw-semibold text-truncate c-font f-18 text-uppercase hover-span">
                        {selectedSpace.office_title
                          ? selectedSpace.office_title
                          : _l("l_spaces")}
                      </div>
                      <div className="text-truncate c-font f-10 color-white-70 hover-span">
                        {selectedSpace.postal_address}
                      </div>
                  </div>
                  <div className="list-right-part">
                    <div className="d-flex align-items-center comman_action_icon">
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
            {/*  ------------------- left penal office listing start ------------------- */}
            <div className="d-flex flex-column overflow-auto flex-grow-1">
              {/* ------------------- left penal search box start ------------------- */}
             <div className="p-10 pb-2 z-index-15 position-sticky top-0 start-0 end-0">
                <Form.Group className=" c-input-box w-100 align-items-center d-flex ps15px bgspacelisting radius_5 leftpanelsearch">
                  <MagnifyingGlassIcon className="HW18 m-0" />
                  <Form.Control
                    type="text"
                    placeholder="search"
                    className="bg-transparent bg-white-03 border-0 searchInputField flex-grow-1 ps-2"
                    value={searchString}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                      setsearchTopic(e.target.value);
                    }}
                  />
                </Form.Group>
                
              </div> 
              {/* ------------------- left penal search box end ------------------- */}
              <ul className="mt-0 office-list-main spacelistingmain">
                {/* alphabetic div start */}
              <a href="#/" className="d-none bgleftpanelnew border c-font f-11 leftpanelsearch mb-1 p-1 position-sticky ps-3 radius3px start-0 text-uppercase top-0 w-100 z-3 fw-semibold">A</a>
                {/* alphabetic div over */}
                {spaces && spaces.length > 0 ? (
                  spaces
                    .sort((a, b) => {
                      if (parseInt(a.pin_count) > parseInt(b.pin_count))
                        return -1;
                      if (parseInt(a.pin_count) < parseInt(b.pin_count))
                        return 1;
                      if (!a.chat_group_message || a.chat_group_message === "")
                        return 1;
                      if (!b.chat_group_message || b.chat_group_message === "")
                        return -1;
                      const chatA = moment(
                        a.chat_group_message_date
                          ? a.chat_group_message_date
                          : ""
                      );
                      const chatB = moment(
                        b.chat_group_message_date
                          ? b.chat_group_message_date
                          : ""
                      );
                      if (chatA.isBefore(chatB)) return 1;
                      if (chatA.isAfter(chatB)) return -1;
                      if (
                        parseInt(a.notification_count) +
                          parseInt(a.chat_notification_count) +
                          parseInt(a.unread_notification_count) >
                        parseInt(b.notification_count) +
                          parseInt(b.chat_notification_count) +
                          parseInt(b.unread_notification_count)
                      )
                        return -1;
                      if (
                        parseInt(a.notification_count) +
                          parseInt(a.chat_notification_count) +
                          parseInt(a.unread_notification_count) <
                        parseInt(b.notification_count) +
                          parseInt(b.chat_notification_count) +
                          parseInt(b.unread_notification_count)
                      )
                        return 1;
                      if (a.project_id == selectedOffice) return 2;
                      return 0;
                    })
                    .sort((a, b) => {
                      if (
                        a.project_id == localStorage.getItem("selectedOffice")
                      ) {
                        if (a.pin_count == 1) {
                          return -1;
                        } else {
                          return -2;
                        }
                      } else {
                        return 1;
                      }
                    })
                    .slice(
                      0,
                      activeOfficePage > 1
                        ? (activeOfficePage - 1) * SPACE_LIST_RESULTS_PER_PAGE + 10
                        : activeOfficePage * SPACE_LIST_RESULTS_PER_PAGE
                    )
                    .map((space, index) => {
                      const activeClass =
                        selectedOffice > 0 &&
                        (space.project_id
                          ? space.project_id == selectedOffice
                          : space.id == selectedOffice) &&
                        !contactlistShow &&
                        !noActiveSpacePageNames.includes(pageName) &&
                        localStorage.getItem("isLiteVersion") != "true"
                          ? "active"
                          : "";
                      const badgeClass =
                        space.notification_count > 0 ? "with-badge" : "";
                      const projectStatus =
                        space.project_status && space.project_status == 2
                          ? "inprogress half"
                          : space.project_status == 3
                          ? "inprogress half"
                          : space.project_status == 5
                          ? "done half"
                          : space.project_status == 6
                          ? "done with_opacity"
                          : space.project_status == 1
                          ? "onboarding"
                          : "";
                      return (
                        <li
                          class={`position-relative task-sub-list w-100 p-10 rounded-0 mb-0 ${
                            space.project_id == selectedOffice ? "active" : ""
                          }`}
                          key={index}
                        >
                          <div class="d-flex align-items-center w-100">
                            <a
                              href="#/"
                              id="providers_myrai_support"
                              class="accordian-left-title w-100 on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen"
                              onClick={() => {
                                onClickOfficeChange(space);
                              }}
                            >
                              {["right"].map((placement) => (
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  delay={{ show: 3000 }}
                                  key={placement}
                                  placement={placement}
                                  onEnter={(e) =>
                                    space.is_virtual_space == 1
                                      ? {}
                                      : getspacedata(e, space.project_id)
                                  }
                                  overlay={
                                    <Popover
                                      id={`popover-positioned-${placement}`}
                                      className="leftpanelpopover"
                                    >
                                      {spaceData.length > 0 ? (
                                        <Popover.Body className="p-0">
                                          <div className="p-0 popover-detail-wrapper">
                                            <li
                                              className={`office-box  p-15 hr_1 pb-2 ${
                                                (pageName &&
                                                  [
                                                    "PremiumSubscription",
                                                    "leadPage",
                                                    "dashboard",
                                                    "MyEmail",
                                                    "dashboardnew",
                                                  ].includes(pageName)) ||
                                                window.location.pathname ==
                                                  "/dashboardfordaterange"
                                                  ? ""
                                                  : activeClass
                                              } ${badgeClass}  with-circle ${projectStatus}`}
                                            >
                                              <a
                                                href="#/"
                                                className="w-100 text-truncate"
                                              >
                                                <div className="d-flex align-items-center">
                                                  <div className="c-list-icon">
                                                    <div className="h40w40 with-bg d-flex align-items-center bg-white-05 radius_5 rounded-circle comman-round-box">
                                                      <span className="m-auto c-font f-10 p-1 office-subtitle text-truncate hover-span">
                                                        {
                                                          spaceData[0]
                                                            .office_title
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="ps-2 w100minus40 title-fonts">
                                                    <div className="c-list-detail c-font f-14 text-truncate color-white c-font f-12">
                                                      {
                                                        spaceData[0]
                                                          .office_title
                                                      }
                                                    </div>
                                                    <div className="c-font f-10 title-fonts color-white-60 text-truncate">
                                                      {
                                                        spaceData[0]
                                                          .postal_address
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="w-100 d-flex align-items-center pt-3">
                                                  <div className="d-flex align-items-center progress-slider flex-grow-1">
                                                    <div className="progress">
                                                      <div
                                                        className="progress-bar"
                                                        style={{
                                                          width: `${space.workspace_score}%`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                    <div className="progress-text c-font f-10 color-white-60 title-fonts">
                                                      {space.workspace_score}%
                                                    </div>
                                                  </div>
                                                </div>
                                              </a>
                                            </li>
                                            <li className="hr_1">
                                              <div className="p-15 py-2">
                                                <div className="color-white-60 text-truncate c-font f-12">
                                                  <span>{_l("l_date")}: </span>
                                                  <span className="with_separator_10">
                                                    {spaceData[0].date_added}
                                                  </span>
                                                  <span className="ps-2">
                                                    {_l("l_project_id")}:{" "}
                                                  </span>
                                                  <span>
                                                    {spaceData[0].project_id}
                                                  </span>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_1">
                                              <div className="p-10">
                                                <div className="c-font f-12 color-white-60 text-truncate">
                                                  {_l("l_kpi")}
                                                </div>
                                                <div className="p-15 pb-0 px-0 row ">
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <BuildingsIcon className="HW16" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">
                                                          {
                                                            spaceData[0]
                                                              .superficies
                                                          }
                                                        </div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">
                                                            {_l("l_sq_meter")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <UserCircleGearIcon className="HW16" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">
                                                          {
                                                            spaceData[0]
                                                              .employees_count
                                                          }
                                                        </div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">
                                                            {_l("l_tetants")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <UsersThreeIcon className="HW16" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">
                                                          {
                                                            spaceData[0]
                                                              .operator_count
                                                          }
                                                        </div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">
                                                            {_l("l_operators")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <WrenchIcon className="HW16" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">
                                                          {
                                                            spaceData[0]
                                                              .equipments_count
                                                          }
                                                        </div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">
                                                            {_l("l_equipments")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <div>
                                              <div className="">
                                                <div className="c-font f-12 color-white-60 p-10 pb-0">
                                                  {_l("l_notification")}
                                                </div>
                                                <ul className="notification-listing">
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      <div className="c-list-icon ">
                                                        <AddTaskIcon className="HW18" />
                                                      </div>
                                                      <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                        <div className="c-list-detail c-font f-14 text-truncate">
                                                          {_l("l_tasks")}
                                                        </div>
                                                        {space.notification_count ==
                                                        0 ? (
                                                          <></>
                                                        ) : (
                                                          <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                            {
                                                              spaceData[0]
                                                                .notification_count
                                                            }
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      <div className="c-list-icon ">
                                                        <RequestTaskIcon className="HW18 fill-transparent" />
                                                      </div>
                                                      <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                        <div className="c-list-detail c-font f-14 text-truncate">
                                                          {_l("l_request")}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      <div className="c-list-icon ">
                                                        <ChatIcon className="HW16" />
                                                      </div>
                                                      <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                        <div className="c-list-detail c-font f-14 text-truncate">
                                                          {_l("l_chat_modal")}
                                                        </div>
                                                        {spaceData[0]
                                                          .notification_count &&
                                                        space.notification_count ==
                                                          0 ? (
                                                          <></>
                                                        ) : (
                                                          <div
                                                            className={`unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex ${
                                                              spaceData[0]
                                                                .chat_notification_count >
                                                              0
                                                                ? ""
                                                                : "d-none"
                                                            }`}
                                                          >
                                                            {
                                                              spaceData[0]
                                                                .chat_notification_count
                                                            }
                                                          </div>
                                                        )}{" "}
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      <div className="c-list-icon ">
                                                        <FileTextIcon className="HW16" />
                                                      </div>
                                                      <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                        <div className="c-list-detail c-font f-14 text-truncate">
                                                          {_l("l_documents")}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </Popover.Body>
                                      ) : (
                                        <></>
                                      )}
                                    </Popover>
                                  }
                                >
                                  <div className="h50w50 d-flex align-items-center justify-content-center ic-provider-list office-list-circle radius_3 comman-round-box bg-white-05">
                                    <div className="h45w45 comman-round-box radius_3 title-fonts d-flex flex-column square_box">
                                      <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                                        <BuildingsIcon className="HW18" />
                                      </span>
                                      <span className=" color-white-80 d-block c-font f-9 pt-0 pb-1 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                                        {space.office_title}
                                      </span>
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              ))}
                              <div class="d-flex justify-content-between flex-grow-1 align-items-center ps10px">
                                <div class="text-truncate w100minus100per">
                                  <div class="title-font text-truncate left_panel_toggle categorytitle accordion-bodyc-font f-14">
                                    {space.office_title
                                      ? space.office_title
                                      : space.name
                                      ? space.name
                                      : _l("l_office_title_placeholder")}
                                  </div>
                                  <div class="title-font text-truncate c-font f-10 left_panel_toggle">
                                    {space.postal_address}
                                  </div>
                                </div>
                              </div>
                            </a>
                            <div class="right-accordian-header transparent-bg">
                              <div class="d-flex align-items-center right-pill-addtask gap10 comman_action_icon dropstart">
                                {space.unread_notification_count > 0 ? (
                                  <div class="rounded-pill badge-custom h18w18 position-static my-auto badge-green rounded-circle d-flex right-accordian-header-pill border-0">
                                    <span class="m-auto ps-0 ">
                                      {space.unread_notification_count}
                                    </span>
                                  </div>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}

                                {space.pin_count == 1 ? (
                                  <a
                                    href="#/"
                                    className="h18w18 on-hover-active-toggle-img d-flex"
                                  >
                                    <PushPinFillIcon className="HW18" />
                                  </a>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}

                                {!teamLeftPanel && selectedTeam ? (
                                  admin == "true" &&
                                  selectedTeam.creator_details.staff_id ==
                                    adminUser &&
                                  ![
                                    "Proposals",
                                    "invoice",
                                    "estimate",
                                    "credit_notes",
                                    "subscription",
                                    "staff",
                                    "_equipments",
                                    "client",
                                    "AdminTaskPage",
                                    "benchmark",
                                    "schedule",
                                    "dashboardnew",
                                    "leadPage",
                                  ].includes(pageName) ? (
                                    <>
                                      <a
                                        href="#/"
                                        className={`action_icon h20w20 ${
                                          (teamLeftPanel &&
                                            localStorage.getItem("admin") ==
                                              "false") ||
                                          contact_role == 3
                                            ? "d-none"
                                            : ""
                                        }`}
                                        id="ActiveOfficeOptionsDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-auto-close="outside"
                                         
                                         
                                         
                                         
                                         
                                        data-popper-placement="left-start"
                                      >
                                        <DotsThreeVerticalIcon className="HW18" />
                                      </a>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <>
                                    {![
                                      "Proposals",
                                      "invoice",
                                      "estimate",
                                      "credit_notes",
                                      "subscription",
                                      "staff",
                                      "_equipments",
                                      "client",
                                      "AdminTaskPage",
                                      "benchmark",
                                      "schedule",
                                      "dashboardnew",
                                      "leadPage",
                                    ].includes(pageName) && (
                                      <a
                                        href="#/"
                                        className={`action_icon h20w20 ${
                                          (teamLeftPanel &&
                                            localStorage.getItem("admin") ==
                                              "false") ||
                                          pageName == "leadPage" ||
                                          pageName == "Proposals" ||
                                          pageName == "invoice" ||
                                          pageName == "estimate" ||
                                          pageName == "subscription" ||
                                          pageName == "staff" ||
                                          pageName == "_equipments" ||
                                          pageName == "client" ||
                                          pageName == "AdminTaskPage" ||
                                          pageName == "credit_notes" ||
                                          pageName == "fullcalendar" ||
                                          pageName == "benchmark" ||
                                          pageName == "schedule" ||
                                          contact_role == 3 ||
                                          pageName == "dashboardnew"
                                            ? "d-none"
                                            : ""
                                        }`}
                                        id="ActiveOfficeOptionsDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-auto-close="outside"
                                         
                                         
                                         
                                         
                                         
                                        onClick={() => {
                                          setUserList([]);
                                        }}
                                      >
                                        <DotsThreeVerticalIcon className="HW18" />
                                      </a>
                                    )}
                                  </>
                                )}

                                <ActionDropdown
                                  widthClass="w-170"
                                  actions={
                                    !teamLeftPanel &&
                                    selectedTeam &&
                                    admin == "true" &&
                                    selectedTeam.creator_details.staff_id ==
                                      adminUser
                                      ? [
                                          {
                                            actionName: _l("l_edit"),
                                            actionHandler: () => {
                                              handleEditAdminTeam(selectedTeam);
                                            },
                                            actionIcon: "PencilSimpleLineIcon",
                                          },
                                          {
                                            actionName: _l("l_delete"),
                                            actionHandler: () => {
                                              dispatch(
                                                showConfirmation(
                                                  _l("l_are_you_sure"),
                                                  "You want to delete space",
                                                  _l("l_yes"),
                                                  _l("l_no"),
                                                  undefined,
                                                  () => {
                                                    handleDeleteTeam(
                                                      selectedTeam,
                                                      1
                                                    );
                                                  }
                                                )
                                              );
                                            },
                                            actionIcon: "TrashIcon",
                                          },
                                        ]
                                      : teamLeftPanel
                                      ? [
                                          {
                                            actionName: _l("l_add"),
                                            actionHandler: () => {
                                              setCreateTeamModal(true);
                                            },
                                            actionIcon: "PlusIcon",
                                          },
                                        ]
                                      : localStorage.getItem("admin")
                                      ? [
                                          {
                                            actionName:
                                              _l("l_create") +
                                              " " +
                                              _l("l_support"),
                                            actionHandler: () => {
                                              dispatch(
                                                showConfirmation(
                                                  _l("l_are_you_sure"),
                                                  _l(
                                                    "l_you_want_to_create_chat_channels"
                                                  ),
                                                  _l("l_yes"),
                                                  _l("l_no"),
                                                  undefined,
                                                  () => {
                                                    createChatChannels(
                                                      projectId
                                                    );
                                                  }
                                                )
                                              );
                                            },
                                            actionIcon: "ChatIcon",
                                          },
                                          {
                                            actionName: _l("l_login_as_user"),
                                            actionHandler: () => {
                                              setproject_id(space.project_id);
                                              setshowProjectStatusList(false);
                                              setUserList([]);
                                              getUserList(
                                                space.client_id,
                                                space.project_id
                                              );
                                              setClientIdLoginAsUser(
                                                space.client_id
                                              );
                                            },
                                            actionIcon: "UserIcon",
                                          },
                                          {
                                            actionName: _l(
                                              "l_manage_project_member"
                                            ),
                                            actionHandler: () => {
                                              setManagePeopleModal(true);
                                              setproject_id(
                                                selectedSpace.project_id
                                              );
                                            },
                                            actionIcon:
                                              "IdentificationBadgeIcon",
                                          },
                                          {
                                            actionName: _l("l_open_ai"),
                                            actionHandler: () => {
                                              setshowOpenAiModal(true);
                                              setproject_id(
                                                selectedSpace.project_id
                                              );
                                              setspaeContent(
                                                selectedSpace.content
                                              );
                                            },
                                            actionIcon: "AiIcon",
                                          },
                                          {
                                            actionName:
                                              _l("l_delete") +
                                              " " +
                                              _l("l_space"),
                                            actionHandler: () => {
                                              setproject_id(
                                                selectedSpace.project_id
                                              );
                                              setOfficeId(selectedSpace.id);
                                              setShowToast(true);
                                              prepareToast(
                                                "toast-with-btn",
                                                _l(
                                                  "l_space_delete_conformation"
                                                ),
                                                _l("l_confirm")
                                              );
                                            },
                                            actionIcon: "TrashIcon",
                                          },
                                          {
                                            actionName:
                                              _l("l_change") +
                                              " " +
                                              _l("l_project_status"),
                                            actionHandler: () => {
                                              setproject_id(
                                                selectedSpace.project_id
                                              );
                                              setSelectedspaceStatus(
                                                parseInt(
                                                  selectedSpace.project_status
                                                )
                                              );
                                              setOfficeId(selectedSpace.id);
                                              setshowProjectStatusList(true);
                                            },
                                            actionIcon: "FlagIcon",
                                          },
                                        ]
                                      : [
                                          {
                                            actionName: _l("l_explore_feature"),
                                            actionHandler: () => {
                                              dispatch(
                                                setProject(space.project_id)
                                              );
                                              setSelectedOffice(
                                                space.project_id
                                              );
                                              localStorage.setItem(
                                                "selectedOffice",
                                                space.project_id
                                              );
                                              navigate(
                                                `/explore-features?space=${space.project_id}`
                                              );
                                            },
                                            actionIcon: "PencilSimpleLineIcon",
                                          },
                                          {
                                            actionName: _l(
                                              "l_manage_project_member"
                                            ),
                                            actionHandler: () => {
                                              setManagePeopleModal(true);
                                              setproject_id(
                                                selectedSpace.project_id
                                              );
                                            },
                                            actionIcon:
                                              "IdentificationBadgeIcon",
                                          },
                                        ]
                                  }
                                  list={
                                    showProjectStatusList
                                      ? projectStatusArray
                                      : userList.map((item, index) => {
                                          return {
                                            name: item.fullname || item.name,
                                            image: item.profile_image_url,
                                            email: item.email,
                                            type: item.type ? item.type : "",
                                            user_type: item.user_type
                                              ? item.user_type
                                              : "",
                                            is_primary: item.is_primary
                                              ? item.is_primary
                                              : "",
                                            is_boss_operator:
                                              item.is_boss_operator
                                                ? item.is_boss_operator
                                                : "",
                                            client_id: item.userid,
                                            contact_role: item.contact_role,
                                            company: item.company,
                                            id:
                                              item.user_type == "Provider"
                                                ? item.staff_id
                                                : item.contact_id,
                                            session_details:
                                              item.session_details,
                                            last_login: item.last_login,
                                            no_of_comments: item.no_of_comments,
                                            task_score: item.task_score,
                                            contact_id: item.contact_id,
                                            staff_id: item.staff_id,
                                          };
                                        })
                                  }
                                  loder={loder}
                                  unControlList={false}
                                  setLoder={setLoder}
                                  handler={(actionItem) => {
                                    if (
                                      actionItem &&
                                      actionItem.metaData &&
                                      actionItem.metaData.key ===
                                        "edit_user_profile"
                                    ) {
                                      setTopicMainKey("edit_user");
                                      setSettingTopicName(
                                        actionItem.type === "Provider"
                                          ? "l_edit_provider_profile"
                                          : "l_edit_contact_profile"
                                      );
                                    } else {
                                      showProjectStatusList
                                        ? handleChangeProjectStatus(
                                            actionItem.id
                                          )
                                        : loginHandler(
                                            actionItem.email,
                                            actionItem.client_id,
                                            actionItem.type,
                                            actionItem.id,
                                            0,
                                            actionItem
                                          );
                                    }
                                  }}
                                  selectedspaceStatus={selectedspaceStatus}
                                  setSelectedspaceStatus={
                                    setSelectedspaceStatus
                                  }
                                  isLoginAsUserDataLoaded={
                                    isLoginAsUserDataLoaded
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {spaces.length >= (activeOfficePage - 1) * 10 + 10 &&
                spaces.length != (activeOfficePage - 1) * 10 + 10 ? (
                  <a
                    href="#/"
                    className="office-box left-view-more-btn p-0"
                    onClick={() => {
                      setActiveOfficePage(activeOfficePage + 1);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="office-list-left flex-grow-1">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="">
                            <div className="btn btn-white-03 w-100 view-more-btn bg-transparent p-0">
                              <div className="d-flex align-item-center justify-content-between comman_action_icon">
                                <span className=" title-fonts c-font f-12 my-auto">
                                  {_l("l_view_more_btn")}
                                </span>
                                <div className="c-three-dots office-list-static h25w25 on-hover-active-toggle-img left-toggle-none with_margin_minus_10px">
                                  <CaretDoubleDownIcon className="HW12" />
                                </div>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  className={`ms-2 d-none ${
                                    loder.todoLoder ? "opacity-1" : "opacity-0"
                                  }`}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ) : (
                  <></>
                )}
              </ul>
            </div>
            {/* ------------------- left penal office listing end ------------------- */}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {/* --------------- Space Listing End --------------- */}

        {/* --------------- Drive Listing start --------------- */}
        {leftPanelTab == "my-drive" ?(
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
            <div className="upper-part ">
              <div className="company-logo-box position-relative d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex w100minus100 flex-grow-1 pe-1 flex-column">
                      <div className="title-fonts fw-semibold text-truncate c-font f-18 text-uppercase hover-span">
                        {_l("l_drive")}
                      </div>
                  </div>
                  <div className="list-right-part">
                    <div className="d-flex align-items-center comman_action_icon">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column overflow-auto">
              <div className="mt-0 office-list-main spacelistingmain">
                <MyDrive
                  data={{main_key:"documents", id: 1}}
                />
              </div> 
            </div>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {/* --------------- Drive Listing End --------------- */}

        {/* --------------- category list (burger Menu Start) --------------- */}
        {[
          "my-request",
          "my-email",
          "my-providers",
          "my-contacts",
          "my-calendar",
          "my-agents",
          "my-tenants",
          "my-staff",
          "my-leads",
          "equipments",
          "dashboard",
          "chat-agents"
        ].includes(leftPanelTab) ||
        (projectId &&
          Number(projectId) > 0 &&
          ["my-space"].includes(leftPanelTab)) ? (
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column ">
            <div className="upper-part ">
              {/* <div className="company-logo-box position-relative d-flex flex-column justify-content-center on-hover-active-toggle-img"> */}
                <div className="d-flex align-items-center justify-content-between company-logo-box position-relative on-hover-active-toggle-img">
                  <div className="d-flex w100minus100 flex-grow-1 pe-1">
                    {projectId > 0 && leftPanelTab == "my-space" ? (
                      <a
                        href="#/"
                        className=" d-flex pe10px"
                        onClick={() => {
                          setSelectedProject(0);
                          dispatch(setProject(0));
                          setSelectedSpace({});
                          setSelectedOffice(0);
                        }}
                      >
                        <CaretLeftIcon className="HW16" />
                      </a>
                    ) : (
                      <></>
                    )}
                    <div className="text-truncate flex-grow-1">
                      <div className="title-fonts fw-semibold text-truncate c-font f-18 text-uppercase hover-span">
                        {leftPanelTab == "my-space"
                          ? selectedSpace.office_title
                            ? selectedSpace.office_title
                            : _l("l_spaces")
                          : leftPanelTab == "my-email"
                          ? _l("l_intro_my_email")
                          : leftPanelTab == "my-providers"
                          ? _l("l_providers")
                          : leftPanelTab == "my-contacts"
                          ? _l("l_contacts")
                          : leftPanelTab == "my-drive"
                          ? _l("l_drive")
                          : leftPanelTab == "my-calendar"
                          ? _l("l_calendar")
                          : leftPanelTab == "my-agents"
                          ? _l("l_my_agents")
                          : leftPanelTab == "my-clients"
                          ? _l("l_my_clients")
                          : leftPanelTab == "my-tenants" 
                          ? _l("l_tenants")
                          : leftPanelTab == "my-staff" 
                          ? _l("l_staff")
                          : leftPanelTab == "my-leads" 
                          ? _l("l_leads")
                          : leftPanelTab == "equipments" 
                          ? _l("l_equipments")
                          : leftPanelTab == "dashboard"
                          ? _l("l_reporting")
                          : leftPanelTab == "chat-agents"
                          ? _l("chat-agents")
                          : _l("l_my_tasks")}
                      </div>
                      <div className="text-truncate c-font f-10 color-white-70 hover-span">
                        {leftPanelTab == "my-space"
                          ? selectedSpace.postal_address
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="list-right-part">
                    <div className="d-flex align-items-center comman_action_icon gap-1">
                      <a href="#/" className="action_icon h20w20" onClick={()=>{
                        setShowSearch(!showSearch)
                      }}>
                        <MagnifyingGlassIcon className="HW18" />
                      </a>
                      <div className="comman_action_icon dropstart dropdown-static with_margin_minus_10px">
                        {
                          (userType == "contact" || userType == "operator") && (contact_role == "1" || contact_role == "2") && currTab == "my-request" ?
                          <a
                                href="#/"
                                className={`1 action_icon h20w20 ${
                                  (teamLeftPanel &&
                                    localStorage.getItem("admin") == "false") ||
                                  contact_role == 3
                                    ? "d-none"
                                    : ""
                                }`}
                                id="ActiveOfficeOptionsDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="outside"  
                                data-popper-placement="left-start"
                              >
                                <DotsThreeVerticalIcon className="HW18" />
                              </a>
                          : <></>
                        }

                        {!teamLeftPanel && selectedTeam ? (
                          admin == "true" &&
                          selectedTeam.creator_details.staff_id == adminUser &&
                          ![
                            "Proposals",
                            "invoice",
                            "estimate",
                            "credit_notes",
                            "subscription",
                            "staff",
                            "_equipments",
                            "client",
                            "AdminTaskPage",
                            "benchmark",
                            "schedule",
                            "dashboardnew",
                            "leadPage",
                          ].includes(pageName) ? (
                            <>
                              <a
                                href="#/"
                                className={`1 action_icon h20w20 ${
                                  (teamLeftPanel &&
                                    localStorage.getItem("admin") == "false") ||
                                  contact_role == 3
                                    ? "d-none"
                                    : ""
                                }`}
                                id="ActiveOfficeOptionsDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="outside"
                                 
                                 
                                 
                                 
                                 
                                data-popper-placement="left-start"
                              >
                                <DotsThreeVerticalIcon className="HW18" />
                              </a>
                            </>
                          ) : (
                            <></>
                          )
                        ) : (
                          <>
                            {![
                              "Proposals",
                              "invoice",
                              "estimate",
                              "credit_notes",
                              "subscription",
                              "staff",
                              "_equipments",
                              "client",
                              "AdminTaskPage",
                              "benchmark",
                              "schedule",
                              "dashboardnew",
                              "leadPage",
                            ].includes(pageName) &&
                              leftPanelTab == "my-space" && (
                                <a
                                  href="#/"
                                  className={`action_icon h20w20 ${
                                    (teamLeftPanel &&
                                      localStorage.getItem("admin") ==
                                        "false") ||
                                    contact_role == 3
                                      ? "d-none"
                                      : ""
                                  }`}
                                  id="ActiveOfficeOptionsDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-bs-auto-close="outside"
                                   
                                   
                                   
                                   
                                   
                                  onClick={() => {
                                    setUserList([]);
                                  }}
                                >
                                  <DotsThreeVerticalIcon className="HW18" />
                                </a>
                              )}
                          </>
                        )}

                        <ActionDropdown
                          widthClass="w-170"
                          actions={
                            !teamLeftPanel &&
                            selectedTeam &&
                            admin == "true" &&
                            selectedTeam.creator_details.staff_id == adminUser
                              ? [
                                  {
                                    actionName: _l("l_edit"),
                                    actionHandler: () => {
                                      handleEditAdminTeam(selectedTeam);
                                    },
                                    actionIcon: "PencilSimpleLineIcon",
                                  },
                                  {
                                    actionName: _l("l_delete"),
                                    actionHandler: () => {
                                      dispatch(
                                        showConfirmation(
                                          _l("l_are_you_sure"),
                                          "You want to delete space",
                                          _l("l_yes"),
                                          _l("l_no"),
                                          undefined,
                                          () => {
                                            handleDeleteTeam(selectedTeam, 1);
                                          }
                                        )
                                      );
                                    },
                                    actionIcon: "TrashIcon",
                                  },
                                ]
                              : teamLeftPanel
                              ? [
                                  {
                                    actionName: _l("l_add"),
                                    actionHandler: () => {
                                      setCreateTeamModal(true);
                                    },
                                    actionIcon: "PlusIcon",
                                  },
                                ]
                              : localStorage.getItem("admin")
                              ? [
                                  {
                                    actionName:
                                      _l("l_create") + " " + _l("l_support"),
                                    actionHandler: () => {
                                      dispatch(
                                        showConfirmation(
                                          _l("l_are_you_sure"),
                                          _l(
                                            "l_you_want_to_create_chat_channels"
                                          ),
                                          _l("l_yes"),
                                          _l("l_no"),
                                          undefined,
                                          () => {
                                            createChatChannels(projectId);
                                          }
                                        )
                                      );
                                    },
                                    actionIcon: "ChatIcon",
                                  },
                                  {
                                    actionName: _l("l_login_as_user"),
                                    actionHandler: () => {
                                      setproject_id(selectedSpace.project_id);
                                      setshowProjectStatusList(false);
                                      setUserList([]);
                                      getUserList(
                                        selectedSpace.client_id,
                                        selectedSpace.project_id
                                      );
                                      setClientIdLoginAsUser(
                                        selectedSpace.client_id
                                      );
                                    },
                                    actionIcon: "UserIcon",
                                  },
                                  {
                                    actionName: _l("l_pilot_onboarding"),
                                    actionHandler: () => {
                                      dispatch(
                                        getPilotOnboardingDocumentDetail(
                                          projectId
                                        )
                                      );
                                      setShowDocumentCanvas(true);
                                    },
                                    actionIcon: "PlusIcon",
                                  },
                                  {
                                    actionName: _l("l_manage_project_member"),
                                    actionHandler: () => {
                                      setManagePeopleModal(true);
                                      setproject_id(selectedSpace.project_id);
                                    },
                                    actionIcon: "IdentificationBadgeIcon",
                                  },
                                  {
                                    actionName: _l("l_open_ai"),
                                    actionHandler: () => {
                                      setshowOpenAiModal(true);
                                      setproject_id(selectedSpace.project_id);
                                      setspaeContent(selectedSpace.content);
                                    },
                                    actionIcon: "AiIcon",
                                  },
                                  {
                                    actionName:
                                      _l("l_delete") + " " + _l("l_space"),
                                    actionHandler: () => {
                                      setproject_id(selectedSpace.project_id);
                                      setOfficeId(selectedSpace.id);
                                      setShowToast(true);
                                      prepareToast(
                                        "toast-with-btn",
                                        _l("l_space_delete_conformation"),
                                        _l("l_confirm")
                                      );
                                    },
                                    actionIcon: "TrashIcon",
                                  },
                                  {
                                    actionName:
                                      _l("l_change") +
                                      " " +
                                      _l("l_project_status"),
                                    actionHandler: () => {
                                      setproject_id(selectedSpace.project_id);
                                      setSelectedspaceStatus(
                                        parseInt(selectedSpace.project_status)
                                      );
                                      setOfficeId(selectedSpace.id);
                                      setshowProjectStatusList(true);
                                    },
                                    actionIcon: "FlagIcon",
                                  },
                                ]
                              :
                              currTab != "my-request" ?
                              [
                                  {
                                    actionName: _l("l_select_all_topics"),
                                    actionHandler: () => {
                                      dispatch(
                                        leftPanelFilters(
                                          "",
                                          "",
                                          "selectAllTopics"
                                        )
                                      );
                                    },
                                    actionIcon: "SelectionAll",
                                  },
                                  {
                                    actionName: _l("l_explore_feature"),
                                    actionHandler: () => {
                                      dispatch(
                                        setProject(selectedSpace.project_id)
                                      );
                                      setSelectedOffice(
                                        selectedSpace.project_id
                                      );
                                      localStorage.setItem(
                                        "selectedOffice",
                                        selectedSpace.project_id
                                      );
                                      navigate(
                                        `/explore-features?space=${selectedSpace.project_id}`
                                      );
                                    },
                                    actionIcon: "PencilSimpleLineIcon",
                                  },
                                  {
                                    actionName: _l("l_manage_project_member"),
                                    actionHandler: () => {
                                      setManagePeopleModal(true);
                                      setproject_id(selectedSpace.project_id);
                                    },
                                    actionIcon: "IdentificationBadgeIcon",
                                  },
                                  {
                                    actionName: _l("l_access_office"),
                                    actionHandler: () => {
                                      "";
                                    },
                                    actionIcon: "KeyIcon",
                                  },
                                  {
                                    actionName: _l("l_drive"),
                                    actionHandler: () => {
                                      localStorage.setItem("lastLeftPanelTab", leftPanelTab);
                                      setSettingTopicName("l_drive");
                                      setTopicMainKey("documents");
                                    },
                                    actionIcon: "HardDrivesIcon",
                                  },
                                ] :
                                [
                                  {
                                    actionName: _l("l_set_email"),
                                    actionHandler: () => {
                                      setEmailModalOpenSet(true);
                                      setproject_id(selectedSpace.project_id);
                                    },
                                    actionIcon: "EnvelopeSimpleIcon",
                                  },
                                ]
                          }
                          list={
                            showProjectStatusList
                              ? projectStatusArray
                              : userList.map((item, index) => {
                                  return {
                                    name: item.fullname || item.name,
                                    image: item.profile_image_url,
                                    email: item.email,
                                    type: item.type ? item.type : "",
                                    user_type: item.user_type
                                      ? item.user_type
                                      : "",
                                    is_primary: item.is_primary
                                      ? item.is_primary
                                      : "",
                                    is_boss_operator: item.is_boss_operator
                                      ? item.is_boss_operator
                                      : "",
                                    client_id: item.userid,
                                    contact_role: item.contact_role,
                                    company: item.company,
                                    id:
                                      item.user_type == "Provider"
                                        ? item.staff_id
                                        : item.contact_id,
                                    session_details: item.session_details,
                                    last_login: item.last_login,
                                    no_of_comments: item.no_of_comments,
                                    task_score: item.task_score,
                                    contact_id: item.contact_id,
                                    staff_id: item.staff_id,
                                  };
                                })
                          }
                          loder={loder}
                          unControlList={false}
                          setLoder={setLoder}
                          handler={(actionItem) => {
                            if (
                              actionItem &&
                              actionItem.metaData &&
                              actionItem.metaData.key === "edit_user_profile"
                            ) {
                              setTopicMainKey("edit_user");
                              setSettingTopicName(
                                actionItem.type === "Provider"
                                  ? "l_edit_provider_profile"
                                  : "l_edit_contact_profile"
                              );
                            } else {
                              showProjectStatusList
                                ? handleChangeProjectStatus(actionItem.id)
                                : loginHandler(
                                    actionItem.email,
                                    actionItem.client_id,
                                    actionItem.type,
                                    actionItem.id,
                                    0,
                                    actionItem
                                  );
                            }
                          }}
                          selectedspaceStatus={selectedspaceStatus}
                          setSelectedspaceStatus={setSelectedspaceStatus}
                          isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
            {/* left panel category accordian start */}
            <div className="d-flex flex-column office-list-main mt-0 ">
           {leftPanelTab === "my-email"  ? 
           <React.Fragment></React.Fragment>
           : <div className="kpi-main-wrapper shortcut-wrapper d-flex align-items-center justify-content-between p-10">
                {["my-request", "my-providers", "my-calendar", "dashboard"].includes(
                  leftPanelTab
                ) ||
                (projectId && Number(projectId) > 0) ? (
                  defaultTaskStatus.map((status, index) => {
                    var statusTitle =
                      status == 1
                        ? _l("l_todo_kpi_name")
                        : status == 4
                        ? _l("l_ongoing_kpi")
                        : status == 5
                        ? _l("l_done_kpi")
                        : _l("l_request");
                    var statusColor =
                      status == 1
                        ? "color-white-80"
                        : status == 4
                        ? "color-blue"
                        : status == 5
                        ? "color-green"
                        : "color-orange";
                    var taskCount = 0;
                    var requestTaskCount = 0;
                    if (projectTaskCount) {
                      taskCount =
                        status == 1
                          ? projectTaskCount.todo_count
                            ? projectTaskCount.todo_count
                            : 0
                          : status == 4
                          ? projectTaskCount.ongoing_count
                            ? projectTaskCount.ongoing_count
                            : 0
                          : status == 5
                          ? projectTaskCount.completed_count
                            ? projectTaskCount.completed_count
                            : 0
                          : projectTaskCount.request_count
                          ? projectTaskCount.request_count
                          : 0;
                      requestTaskCount =
                        status == 1
                          ? projectTaskCount.todo_request_count
                            ? projectTaskCount.todo_request_count
                            : 0
                          : status == 4
                          ? projectTaskCount.ongoing_request_count
                            ? projectTaskCount.ongoing_request_count
                            : 0
                          : status == 5
                          ? projectTaskCount.completed_count
                          : projectTaskCount.request_count;
                    }
                    return (
                      <>
                        <a href="#/" className="kpi-box bg-white-05 w_25per">
                          {/* <div className="">
                            <div className=""> */}
                              <span className={`${statusColor} kpi-upper-box kpi-upper-box-text`}>{statusTitle}</span>
                            {/* </div>
                          </div> */}
                          <div className="kpi-statatics c-font f-18 fw-bold">{taskCount}</div>
                        </a>
                      </>
                    );
                  })
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>}

              {/* ------------------- left penal search box start ------------------- */}
              {/* KPISkeleton start  */}
                {/* <KPISkeleton /> */}
              {/* KPISkeleton end  */}
              {
                leftPanelTab === "my-email"
                 ? <></> 
                 :  showSearch ? 
                 <div className="p-10  z-index-15 position-sticky top-0 start-0 end-0 p-5px">
                    <Form.Group className=" c-input-box w-100 align-items-center d-flex ps15px bgspacelisting radius_5 leftpanelsearch">
                      <MagnifyingGlassIcon className="HW18" />
                      <Form.Control
                        type="text"
                        placeholder="search"
                        className="bg-transparent bg-white-03 border-0 searchInputField flex-grow-1 ps-2"
                        value={searchString}
                        onChange={(e) => {
                          setSearchString(e.target.value);
                          setsearchTopic(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {/* support box start for admin */}
                    {userType == "staff" && leftPanelTab == "my-space" ? <>
                    <div className="border-bottom p-10 radius_3 d-flex align-items-center justify-content-between pe-0 ">
                         <div className="c-font f-14 fw-semibold">{_l("l_intro_header_support")}</div> 
                         <div className="d-flex align-items-center comman_action_icon">
                            <a href="" className="d-flex h32w32 position-relative on-hover-active-toggle-img"> 
                              <PlusIcon className="HW18" />
                            </a>
                            <div className="with_separator_10 before-block ps-0 pe-0"></div>
                            <a href="" className="d-flex h32w32 position-relative on-hover-active-toggle-img"> 
                              <GearSixIcon className="HW18" />
                            </a>
                          </div> 
                    </div>
                    </> : <></>}
                    {/* support box over for admin */}
                  </div> : <></>
              }
              {/* ------------------- left penal search box end ------------------- */}

              <div className="office-list-main with-padding shortcut-wrapper">
                <TwoStepAccordians
                  setCompletedTasksCount={setCompletedTasksCount}
                  setInProgressTasksCount={setInProgressTasksCount}
                  setTodoTasksCount={setTodoTasksCount}
                  projectId={projectId}
                  searchCategory={searchCategory || searchString || searchTopic}
                  pageName={leftPanelTab == "my-email" ? "MyEmail" : pageName}
                  teamLeftPanel={teamLeftPanel}
                  setSelectOperators={setSelectOperators}
                  setShowScheduleDropDown={setShowScheduleDropDown}
                  setShowProviderDropDown={setShowProviderDropDown}
                  allMAilMessages={allMAilMessages}
                  allImportantMessages={allImportantMessages}
                  hideMailSyncBtn={hideMailSyncBtn}
                  createTeamModal={createTeamModal}
                  setCreateTeamModal={setCreateTeamModal}
                  equipmentModal={equipmentModal}
                  setEquipmentModal={setEquipmentModal}
                  selectAdminTeam={(team, is_remove) =>
                    selectAdminTeam(team, is_remove)
                  }
                  selectedTeam={selectedTeam}
                  clientTeamData={clientTeamData}
                  handleDeleteTeam={handleDeleteTeam}
                  handleFavoriteAdminTeam={handleFavoriteAdminTeam}
                  handleEditAdminTeam={handleEditAdminTeam}
                  topicType={topicType}
                  setTopicType={setTopicType}
                  selectedTeamForEdit={selectedTeamForEdit}
                  setSelectedTeamForEdit={setSelectedTeamForEdit}
                  showAddTeamMember={showAddTeamMember}
                  setshowAddTeamMember={setshowAddTeamMember}
                  adminTeamList={adminTeamList}
                  setAdminTeamList={setAdminTeamList}
                  showUserList={showUserList}
                  searchCategoty={searchCategoty}
                  searchTopic={searchTopic}
                  showForMeSection={showForMeSection}
                  setShowForMeSection={setShowForMeSection}
                  contactDirData={contactDirData}
                  contactDirTopicChange={contactDirTopicChange}
                  contactlistShow={contactlistShow}
                  tenantesList={tenantesList}
                  spacesList={spaces}
                />
              </div>
              {/* Drive & setting icon start */}


              { contact_role != 3 && !["my-calendar", "my-email", "my-request", "my-tenants", "my-staff", "dashboard"].includes(leftPanelTab) && !isAllChatUserlistShow ?  
                // <div className={`kpi-main-wrapper  shortcut-wrapper with-white-bg p-10 d-flex gap-2 ${(teamLeftPanel ||  hideLeftPanelFooter.indexOf(pageName) > -1 )? "d-none" : ""} ${contactlistShow ? "for_disabled" : ""}`} >
                //   <a href="#/" className={`d-flex  bg-white-05 radius_3 comman-round-box border-transparent flex-column justify-content-center align-items-center text-center p-5px h50px flex-grow-1 w-50 on-hover-active-toggle-img ${pageName == "documents" ? "active " : ""} ${contactlistShow ? "pe-none" : ""}`} 
                //     onClick={()=>{
                //       localStorage.setItem("lastLeftPanelTab", leftPanelTab);
                //       setSettingTopicName("l_drive");
                //       setTopicMainKey("documents");
                //       }}>
                //     <div className={` mx-3 comman-round-box  d-flex  `} >
                //       <div className={`comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img `}>
                //       <HardDrives
                //               size={16}
                //               weight="light"
                //               className="c-icons"
                //             />
                //       </div>
                //     </div>
                //     <div className={` w100minus50  d-flex  title-fonts color-white-80 left-toggle-none team-box-open comman-round-box pt-2 `} >
                //       <div className="d-flex justify-content-center align-items-center w-100">
                //         <div className="d-flex flex-column align-item-center justify-content-between w-100 c-font f-12">
                //           <div className="title-font c-font f-11 fw-bold text-uppercase hover-span ">{_l("l_drive")}</div>
                //         </div>
                //       </div>
                //     </div>
                //   </a>
                //   {admin || pageName =='dashboard' || pageName == "dashboardnew" ? <></> :
                //   <a className={`d-flex  bg-white-05 radius_3 comman-round-box border-transparent flex-column justify-content-center align-items-center text-center p-5px h50px flex-grow-1 w-50 on-hover-active-toggle-img `}
                //       onClick={() => {
                //         removeExpandedformIntro()
                //         dispatch(setProject(projectId));
                //         setSelectedOffice(projectId)
                //         localStorage.setItem("selectedOffice", projectId);
                //         navigate(`/explore-features?space=${projectId}`);
                //       }}
                //   >
                //     <div className={`  comman-round-box  d-flex align-items-center justify-content-center `} >
                //       <div className={`comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img `}>
                //           <GearSix
                //             size={16}
                //             weight="light"
                //             className="c-icons"
                //           />
                //       </div>
                //     </div>
                //     <div className={` w100minus50  d-flex  title-fonts color-white-80 left-toggle-none team-box-open comman-round-box pt-2 `} >
                //       <div className="d-flex justify-content-center align-items-center w-100">
                //         <div className="d-flex flex-column align-item-center justify-content-between w-100 c-font f-12">
                //           <div className="title-font c-font f-11 fw-bold text-uppercase hover-span ">{_l("l_settings")}</div>
                //         </div>
                //       </div>
                //     </div>
                //   </a>
                // }
                // </div> 
                <></>
                : 
                <></>}
              {/* Drive & setting icon end */}
            </div>
            {/* left panel category accordian end */}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {/* --------------- category list (burger Menu end) --------------- */}
      </div>
    </>
  );
};

export default LeftPanelSubMenu;
