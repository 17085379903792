import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import { showMessage } from "../../actions/messages";
import benchmarkServices from "../../services/benchmark-services";
import Spinner from "react-bootstrap/Spinner";
import { _l } from "../../hooks/utilities";
import AddEmailTemplatesModalNew from "./AddEmailTemplatesModalNew";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import GearSixIcon from "../../assets/icons/GearSixIcon";
import PencilLineIcon from "../../assets/icons/PencilLineIcon";

const AddressModal = ({
  show,
  modalKey,
  handleClose,
  handleInput = (address) => {},
  data,
  countryList
}) => {
  const [billingAddress, setBillingAddress] = useState({
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_country: null,
  });
  const mainModalKey = modalKey == "billing_address" ? "billing" : "shipping";
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const validateInputFields = () => {
    let validationSuccess = true;

    const {
      billing_street,
      billing_city,
      billing_state,
      billing_zip,
      billing_country,
    } = billingAddress;
    let errMessage;

    if (billing_country == null) {
      errMessage = "Country is required";
      validationSuccess = false;
    }
    if (!billing_zip.trim()) {
      errMessage = "Zip Code is required";
      validationSuccess = false;
    }
    if (!billing_state.trim()) {
      errMessage = "State Name is required";
      validationSuccess = false;
    }
    if (!billing_city.trim()) {
      errMessage = "City Name is required";
      validationSuccess = false;
    }
    if (!billing_street.trim()) {
      errMessage = "Street Name is required";
      validationSuccess = false;
    }
    if (!validationSuccess) {
      dispatch(showMessage("unsucess", _l("l_error"), `${errMessage}`));
    }
    return validationSuccess;
  };

  const hideModal = () => {
    if (validateInputFields()) {
      handleInput(
        {
          [`${mainModalKey}_street`]: billingAddress.billing_street,
          [`${mainModalKey}_city`]: billingAddress.billing_city,
          [`${mainModalKey}_state`]: billingAddress.billing_state,
          [`${mainModalKey}_zip`]: billingAddress.billing_zip,
          [`${mainModalKey}_country`]: billingAddress.billing_country,
        },
        {
          [`shipping_street`]: billingAddress.billing_street,
          [`shipping_city`]: billingAddress.billing_city,
          [`shipping_state`]: billingAddress.billing_state,
          [`shipping_zip`]: billingAddress.billing_zip,
          [`shipping_country`]: billingAddress.billing_country,
        },
        checked
      );
    }
  };

  useEffect(() => {
    if (data !== null) {
      const addresData = {
        billing_street: data.billing_street || data.  shipping_street,
        billing_city: data.billing_city || data.shipping_city,
        billing_state: data.billing_state || data.shipping_state,
        billing_zip: data.billing_zip || data.shipping_zip,
        billing_country: data.billing_country || data.shipping_country,
      };
      setBillingAddress(addresData);
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{_l("l_create_invoice")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="form-wrapper-main">
          <div className="row m-0 mb-3 border-bottom">
            <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
              <div className="d-flex flex-wrap flex-column">
                <Form.Label className="input-label no-asterisk  d-flex align-items-center">
                  {_l("l_street")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_street")}
                  rows={2}
                  value={billingAddress.billing_street}
                  onChange={(e) => {
                    setBillingAddress({
                      ...billingAddress,
                      billing_street: e.target.value,
                    });
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_city")}
              </Form.Label>
              <Form.Control
                placeholder={_l("France")}
                aria-label="france"
                aria-describedby="basic-addon1"
                value={billingAddress.billing_city}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingAddress,
                    billing_city: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_state")}
              </Form.Label>
              <Form.Control
                placeholder={_l("")}
                aria-label=""
                aria-describedby="basic-addon1"
                value={billingAddress.billing_state}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingAddress,
                    billing_state: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_zip_code")}
              </Form.Label>
              <Form.Control
                placeholder={"93160"}
                aria-label=""
                aria-describedby="basic-addon1"
                value={billingAddress.billing_zip}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingAddress,
                    billing_zip: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_country")}
              </Form.Label>
              <div className="d-flex">
                <Select
                  className="custom-select-menu flex-grow-1"
                  placeholder={_l("l_please_select_country")}
                  options={countryList}
                  classNamePrefix="react-select"
                  value={
                    countryList &&
                    countryList.find(
                      (w) => w.value == billingAddress.billing_country
                    )
                  }
                  onChange={(e) => {
                    console.log("+++++++e", e);
                    setBillingAddress({
                      ...billingAddress,
                      billing_country: e.value,
                    });
                  }}
                />
              </div>
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_cancel")}
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            hideModal();
          }}
        >
          {_l("l_confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddInvoiceModal = ({ show, handleClose }) => {

  const paymentModes = [
    {
      value: "gocardless",
      label: _l("GoCardless"),
    },
    {
      value: "stripe",
      label: _l("Stripe"),
    },
    {
      value: "swan_getway",
      label: _l("Swan"),
    },
  ];

  const discountOption = [
    {
      value: "",
      label: "No Discount",
    },
    {
      value: "before_tax",
      label: "Before Tax",
    },
    {
      value: "after_tax",
      label: "After Tax",
    },
  ];

  const recurringOption = [
    {
      value: "0",
      label: "No",
      name : 0
    },
    {
      value: "1",
      label: "Every 1 Months",
      name : 1
    },
    {
      value: "2",
      label: "Every 2 Months",
      name : 2
    },
    {
      value: "3",
      label: "Every 3 Months",
      name : 3
    },
    {
      value: "4",
      label: "Every 4 Months",
      name : 4
    },
    {
      value: "5",
      label: "Every 5 Months",
      name : 5
    },
    {
      value: "6",
      label: "Every 6 Months",
      name : 6
    },
    {
      value: "7",
      label: "Every 7 Months",
      name : 7
    },
    {
      value: "8",
      label: "Every 8 Months",
      name : 8
    },
    {
      value: "9",
      label: "Every 9 Months",
      name : 9
    },
    {
      value: "10",
      label: "Every 10 Months",
      name : 10
    },
    {
      value: "11",
      label: "Every 11 Months",
      name : 11
    },
    {
      value: "12",
      label: "Every 12 Months",
      name : 12
    },
    {
      value: "custom",
      label: "Custom",
      name : 'custom'
    },
    
  ];

  const recurring_custom_Type_options = [
    {
      value: "day",
      label: "Day(s)",
    },
    {
      value: "week",
      label: "Week(s)",
    },
    {
      value: "month",
      label: "Month(s)",
    },
    {
      value: "year",
      label: "Year(s)",
    },
  ]

  const staffId = localStorage.getItem("staff_id")
    ? localStorage.getItem("staff_id")
    : null;
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const projectId =
    localStorage.getItem("isLiteVersion") == "true"
      ? 0
      : localStorage.getItem("selectedOffice");
  const selectedSpace =
    spaces && spaces.filter((item) => item.project_id == projectId);
  const spaceClientId = selectedSpace && selectedSpace.client_id;

  const spacesOption = spaces && spaces.map((sp) => {
    return { value: sp.project_id, label: sp.office_title };
  })

  const initialInput = {
    customer_client_id: null,
    project_id: projectId,
    staff_id: staffId,
    customer_company_name: "",
    estimateno: 0,
    start_date: null,
    end_date: null,
    currency: '',
    payment_mode: [],
    recurring: 0,
    recurringType : recurringOption[0],
    recurring_custom_Type : {custom_recurring : 0, recurring_type : 'day'},
    name: "",
    billing_address: null,
    shipping_address: null,
    show_shipping_on_estimate: 0,
    items: [],
    address: "",
    invoice_number: null,
    estimate_subtotal: 0,
    discount_total: 0,
    estimate_total: 0,
    tax_amount_total: 0,
    adjustment: 0,
    client_note: "",
    terms: "",
    sale_agent_id : "",
    purchase_order: '',
    discount_type: '',
    admin_note : '',
    cycles: {isInfinite : true, totalCyle : 0}
  };

  const dispatch = useDispatch();

  const [customers, setCustomers] = useState([]);
  const [taxFeild, setTaxFeild] = useState([]);
  const [invoiceInput, setInvoiceInput] = useState(initialInput);
  const [invoiceItems, setInvoiceItems] = useState([
    {
      description: "",
      long_description: "",
      order: 0,
      qty: 0,
      rate: 0,
      taxtnamewrapper: taxFeild && taxFeild.length > 0 ?  taxFeild[0] : null,
      taxname: taxFeild && taxFeild.length > 0 ? taxFeild[0].value : null,
      item_total: 0,
      tax_amount: 0,
      item_image: '',
      key_data : '',
      type_of_cost : ''
    },
  ]);
  const [addressModal, setAddressModal] = useState(false);
  const [addressModalKey, setAddressModalKey] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currencyTypes, setCurrencyTypes] = useState([]);
  const [salesAgentsOption, setsalesAgentsOption] = useState([])
  const [countryList, setCountryList] = useState([]);


  const excludedDate = (date) => {
    const day = Number(moment(date).format("D"));
    return ![29, 30, 31].includes(day);
  };

  useEffect(() => {
    let defaultSpace = invoiceInput.customer_client_id;
    let invoiceNumber = invoiceInput.invoice_number;
    let termsText = invoiceInput.terms;

    CustomerServices.getCustomerEstimate(projectId).then((res) => {
      if (res.status == "1") {
        const response = res.data;
        setCustomers(
          response.map((customer) => {
            let defaultSpace = null;
            if (customer.userid == spaceClientId) {
              defaultSpace = {
                value: customer.userid,
                label: customer.company,
              };
            }
            return {
              value: customer.userid,
              label: customer.company,
            };
          })
        );
        setInvoiceInput({
          ...invoiceInput,
          invoice_number: invoiceNumber,
          terms: termsText,
          customer_client_id: defaultSpace,
        });
      }
    });

    CustomerServices.getAllTaxName().then((res) => {
      if (res.status == "1") {
        const response = res.data;
        const taxFieldWrapper = response.map((tax) => {
          return {
            value: tax.id,
            label: `${tax.name} ${tax.taxrate}%`,
            taxrate: parseFloat(tax.taxrate),
          };
        });
        setTaxFeild(taxFieldWrapper);
        const items = [...invoiceItems];
        items[0] = {
          ...items[0],
          taxname: taxFieldWrapper[0].value,
          taxtnamewrapper: taxFieldWrapper[0],
        };
        setInvoiceItems(items);
      }
    });

    CustomerServices.getInvoicesTerms(staffId).then((res) => {
      if (res.status == "1") {
        const response = res.data;
        invoiceNumber = response ?  `${response.prefix} ${response.invoice}` : '';
        termsText = response ? response.terms : '';
        setInvoiceInput({
          ...invoiceInput,
          invoice_number: invoiceNumber,
          terms: termsText,
          customer_client_id: defaultSpace,
        });

        setCurrencyTypes(response && response.currencies.map((currency) => {
          return ({
            value: currency.id,
            label: `${currency.symbol} ${currency.name}`,
          });
        }));
        setsalesAgentsOption(response && response.sales_agent.map((salesAgent) => {
          return ({
            value: salesAgent.staffid,
            label: salesAgent.full_name,
          });
        }));
      }
    });

    CustomerServices.getCountryCodes(true).then((res) => {
      if (res.status == "1") {
        setCountryList(
          res.data.map((code) => {
            return {
              value: code.country_id,
              label: code.long_name,
              short_code: code.iso2,
            };
          })
        );
      }
    });

  }, []);

  const validateInputFields = () => {
    let validationSuccess = true;

    const {
      customer_client_id,
      start_date,
      recurring,
      items,
      terms,
    } = invoiceInput;
    let errMessage;

    if (!terms.trim()) {
      errMessage = "Please Aggree our terms and conditons";
      validationSuccess = false;
    }
    if (items.length == 0) {
      errMessage = "Please enter at least one item";
      validationSuccess = false;
    }
    if (recurring == null) {
      errMessage = "Please select if Estimate is recurring or not.";
      validationSuccess = false;
    }
    if (start_date == null) {
      errMessage = "Please select if Estimate is recurring or not.";
      validationSuccess = false;
    }
    if (start_date == null) {
      errMessage = "Start Date is required";
      validationSuccess = false;
    }
    if (customer_client_id == null) {
      errMessage = "Please Select Customer";
      validationSuccess = false;
    }

    if (!validationSuccess) {
      dispatch(showMessage("unsucess", _l("l_error"), `${errMessage}`));
    }
    return validationSuccess;
  };

  const createNewInvoices = (saveAndSend, sendEmailData = {}) => {
    if (validateInputFields()) {
      setLoader(saveAndSend ? "" : "create");
      benchmarkServices
        .createInvoices(invoiceInput, saveAndSend, sendEmailData)
        .then((res) => {
          if (res.status) {
            dispatch(
              showMessage("sucess", _l("l_success"), _l("l_invoices_created"))
            );
            setTimeout(() => {
              handleClose({
                hash: res.data.estimate_hash,
                type: "estimate",
              });
            }, 1000);
            setLoader(false);
          } else {
            setLoader(false);
            dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          }
        });
    }
  };

  const addNewItemValidation = (index) => {
    let validationSuccess = true;
    let errMessage;
    const { qty, description } = invoiceItems[index];

    if (qty == 0) {
      errMessage = "Quantity of item should be greater than 0";
      validationSuccess = false;
    }

    if (!description.trim()) {
      errMessage = "Description is required";
      validationSuccess = false;
    }

    if (!validationSuccess) {
      dispatch(showMessage("unsucess", _l("l_error"), `${errMessage}`));
    }
    return validationSuccess;
  };

  useEffect(() => {
    let estimateTotal = 0;
    let taxSum = 0;
    let estimateSubtotal = 0;
    invoiceItems.length &&
      invoiceItems.map((item, index) => {
        taxSum += item.tax_amount;
        estimateSubtotal += item.item_total;
        estimateTotal = estimateSubtotal + taxSum;
      });
    setInvoiceInput({
      ...invoiceInput,
      estimate_subtotal: estimateSubtotal.toFixed(2),
      estimate_total: (estimateTotal - invoiceInput.discount_total).toFixed(2),
      tax_amount_total: taxSum.toFixed(2),
    });
  }, [invoiceItems]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style w1300px"
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_create_invoice")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 mb-3 border-bottom">
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 z-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_customer")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_select")}
                    options={customers}
                    value={invoiceInput.customer_client_id}
                    onChange={(e) => {
                      setInvoiceInput({
                        ...invoiceInput,
                        customer_client_id: e,
                      });
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 z-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_project")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_select")}
                    options={spacesOption}
                    value={spacesOption.find((spaces) => spaces.value == invoiceInput.project_id)}
                    onChange={(e) => {
                      setInvoiceInput({ ...invoiceInput, project_id: e.value});
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3  ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_custom_company_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_name")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={invoiceInput.customer_company_name}
                  onChange={(e) => {
                    setInvoiceInput({
                      ...invoiceInput,
                      customer_company_name: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_invoice_number")}
                </Form.Label>
                <Form.Control
                  placeholder={_l(invoiceInput.invoice_number)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={invoiceInput.invoice_number}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_start_date")}
                </Form.Label>
                <div
                  className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100"
                  data-bs-toggle="tooltip"
                >
                  <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown">
                    <CalendarBlankIcon
                      className="HW18 opacity-60 m-0 me-2"
                    />
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      placeholderText={`${_l("l_start_date_placeholder")}`}
                      dateFormat="dd/MM/yyyy"
                      selectsStart
                      calendarStartDay={1}
                      minDate={new Date()}
                      selected={invoiceInput.start_date}
                      onChange={(date) => {
                        setInvoiceInput({
                          ...invoiceInput,
                          start_date: date,
                        });
                      }}
                      filterDate={excludedDate}
                    >
                      <div className="datepicker-label">
                        {_l("l_select_date_and_time")}
                      </div>
                    </DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_end_date")}
                </Form.Label>
                <div
                  className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100"
                  data-bs-toggle="tooltip"
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown-center dropdown">
                    <CalendarBlankIcon
                      className="HW18 opacity-60 m-0 me-2"
                    />
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      placeholderText={`${_l("l_end_date_placeholder")}`}
                      dateFormat="dd/MM/yyyy"
                      selectsStart
                      calendarStartDay={1}
                      selected={invoiceInput.end_date}
                      onChange={(date) => {
                        setInvoiceInput({
                          ...invoiceInput,
                          end_date: date,
                        });
                      }}
                    >
                      <div className="datepicker-label">
                        {_l("l_select_date_and_time")}
                      </div>
                    </DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 z-index-15">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_currency")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_please_select_currency")}
                    options={currencyTypes}
                    value={currencyTypes.find(cu => cu.value == invoiceInput.currency)}
                    onChange={(e) => {
                      setInvoiceInput({ ...invoiceInput, currency: e.value });
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 z-index-15">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_payment_mode")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_select")}
                    options={paymentModes}
                    isMulti={true}
                    value={invoiceInput.payment_mode}
                    onChange={(e) => {
                      setInvoiceInput({ ...invoiceInput, payment_mode: e });
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 z-index-15">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_recurring")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_select")}
                    options={recurringOption}
                    value={recurringOption.find(re => re.name == invoiceInput.recurringType.name)}
                    onChange={(e) => {
                      if (e.value == 0) {
                        setInvoiceInput({ ...invoiceInput, recurring: e.value , recurringType: e, cycles: {isInfinite : true, totalCyle : 0}, recurring_custom_Type : {custom_recurring : 0, recurring_type : 'day'}});
                      }if (e.value == 'custom') {
                        setInvoiceInput({ ...invoiceInput, recurring: 1 , recurringType: e, cycles: {isInfinite : true, totalCyle : 0}, recurring_custom_Type : {custom_recurring : 1, recurring_type : 'day'}} );
                      }else{
                        setInvoiceInput({ ...invoiceInput, recurring: e.value , recurringType: e, recurring_custom_Type : {custom_recurring : 0, recurring_type : 'day'}});
                      }
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              {invoiceInput.recurringType.value == 0 ? <></> :
              <>
              <Form.Group className={`${invoiceInput.recurringType.value == 'custom' ? 'col-xl-4' : 'col-xl-12'} c-input-box position-relative mb-3`}>
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_totle_cycle")}
                </Form.Label>
                <div className="d-flex">
                <Form.Control
                  placeholder={_l("l_cycles")}
                  aria-label={_l("l_totle_cycle")}
                  type="number"
                  aria-describedby="basic-addon1"
                  value={invoiceInput.cycles.totalCyle}
                  disabled={invoiceInput.cycles.isInfinite}
                  onChange={(e) => {
                    setInvoiceInput({
                      ...invoiceInput,
                      cycles: {...invoiceInput.cycles, totalCyle :e.target.value},
                    });
                  }}
                />
                  <div className="form-check with-span">
                    <div className="d-flex align-items-center">
                        <Form.Label className="form-check-label mt-2 mb-0 w-100">
                        <Form.Check
                            inline
                            name="Infinity"
                            label="Infinity"
                            type="checkbox"
                            className="ps-1"
                            value={invoiceInput.cycles.isInfinite}
                            checked={invoiceInput.cycles.isInfinite}
                            onChange={(e) => {
                              setInvoiceInput({
                                ...invoiceInput,
                                cycles: {...invoiceInput.cycles, isInfinite : !invoiceInput.cycles.isInfinite},
                              });
                            }}
                          />
                        </Form.Label>
                    </div>
                  </div>
                </div>
              </Form.Group>
              {invoiceInput.recurringType.value != "custom" ? <></> :
                <>
                <Form.Group className={`${invoiceInput.recurringType.value == 'custom' ? 'col-xl-4' : 'col-xl-12'} col-xl-4 c-input-box position-relative mb-3 mt-4  `}>
                  <Form.Control
                    placeholder={_l("l_count")}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={invoiceInput.recurring}
                    onChange={(e) => {
                      setInvoiceInput({
                        ...invoiceInput,
                        recurring: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className={`${invoiceInput.recurringType.value == 'custom' ? 'col-xl-4' : 'col-xl-12'} col-xl-4 c-input-box position-relative mb-3 z-index-15 mt-4`}>
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_select")}
                      options={recurring_custom_Type_options}
                      value={recurring_custom_Type_options.find((ti) => ti.value == invoiceInput.recurring_custom_Type.recurring_type)}
                      onChange={(e) => {
                        setInvoiceInput({ ...invoiceInput, 
                          recurring_custom_Type: {...invoiceInput.recurring_custom_Type, recurring_type : e.value} });
                      }}
                      classNamePrefix="react-select"
                    />
                  </div>
                </Form.Group>
                </>

              }
              </>
              }

              {/* Add row  */}
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3  ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_sale_agent")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_please_select_sale_agent")}
                    options={salesAgentsOption}
                    value={salesAgentsOption.find(sa => sa.value == invoiceInput.sale_agent_id)}
                    onChange={(e) => {
                      setInvoiceInput({ ...invoiceInput, sale_agent_id: e.value});
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_discount_type")}
                </Form.Label>
                <div className="d-flex">
                  <Select
                    className="custom-select-menu flex-grow-1"
                    placeholder={_l("l_please_select_discount_type")}
                    options={discountOption}
                    value={discountOption.find(dis => dis.value == invoiceInput.discount_type)}
                    onChange={(e) => {
                      setInvoiceInput({ ...invoiceInput, discount_type: e.value});
                    }}
                    classNamePrefix="react-select"
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3  ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_purchase_order")}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder={_l("l_select_purchase_order")}
                  aria-describedby="basic-addon1"
                  value={invoiceInput.purchase_order}
                  onChange={(e) => {
                    setInvoiceInput({
                      ...invoiceInput,
                      purchase_order: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <div className="c-input-box pb-10px pt-10px terms-condition">
                <div className=" input-label no-asterisk  form-label pb-2">
                  {_l("l_admin_note")}
                </div>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_description")}
                  rows={2}
                  value={invoiceInput.admin_note}
                  onChange={(e) => {
                    setInvoiceInput({
                      ...invoiceInput,
                      admin_note: e.target.value,
                    });
                  }}
                />
              </div>
              {/* Add Row End  */}
            </div>

            <div className="border-bottom pe-10px ps-10px row">
              <Form.Group className="col-12 c-input-box position-relative mb-3 ">
                <div className="d-flex align-items-center mb-2 comman_action_icon">
                  <Form.Label className="input-label no-asterisk mb-0">
                    {_l("l_bill_to")}
                  </Form.Label>
                  <a
                    href="#/"
                    className="action_icon h20w20 with_bg"
                    onClick={() => {
                      setAddressModal(true);
                      setAddressModalKey("billing_address");
                    }}
                  >
                    <PencilLineIcon className="HW18" weight="light" />
                  </a>
                </div>
                <div className="d-flex align-items-center gap10 title-fonts">
                  <div className="align-items-center d-flex gap-1">
                  {<><span className="color-white-60"> {_l("l_street")} </span> : <span className="color-white"> {invoiceInput.billing_address && invoiceInput.billing_address.billing_street || "-"} </span></> }
                  </div>
                  <div className="align-items-center d-flex gap-1">
                  {<><span className="color-white-60"> {_l("l_city")} </span> : <span className="color-white"> {invoiceInput.billing_address && invoiceInput.billing_address.billing_city || "-"} </span></> }
                  </div>
                  <div className="align-items-center d-flex gap-1">
                  {<><span className="color-white-60"> {_l("l_state")} </span> : <span className="color-white"> {invoiceInput.billing_address && invoiceInput.billing_address.billing_state || "-"} </span></> }
                  </div>
                  <div className="align-items-center d-flex gap-1">
                  {<><span className="color-white-60"> {_l("l_zip_code")} </span> : <span className="color-white"> {invoiceInput.billing_address && invoiceInput.billing_address.billing_zip || "-"} </span></> }
                  </div>
                  <div className="align-items-center d-flex gap-1">
                  {<><span className="color-white-60"> {_l("l_country")} </span> : <span className="color-white"> {invoiceInput.billing_address && countryList.find((county) => county.value == invoiceInput.billing_address.billing_country) && countryList.find((county) => county.value == invoiceInput.billing_address.billing_country).label || "-"} </span></> }
                  </div>
                </div>
              </Form.Group>
            </div>

            <div className="row m-0 border-bottom">
              <div className=" d-flex flex-column pt-3 p-0">
                <div className="comman-content-scroll-wrapper">
                  <div className="comman-content-scroll">
                    <div className="comman-data-table GeneralItemTable">
                      <table className="dataTable comman-table smaller-table">
                        <thead className="bg-transparent">
                          <tr className="bg-white-03">
                            <th>{_l("l_item")}</th>
                            <th>{_l("l_description")}</th>
                            <th>{_l("l_qty")}</th>
                            <th>{_l("l_rate")}</th>
                            <th>{_l("l_tax")} (%)</th>
                            <th>{_l("l_amount")}</th>
                            <th className="text-center">
                              <GearSixIcon
                                className="HW18"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceItems.length &&
                            invoiceItems.map((item, index) => {
                              return (
                                <tr className="">
                                  <td className="max-width-100 text-truncate border-bottom">
                                    <div className="color-white-60 c-input-box position-relative">
                                      <Form.Control
                                        as="textarea"
                                        placeholder={_l("l_description")}
                                        rows={1}
                                        className="inputh30 pb-1 ps-2 pt-1"
                                        value={invoiceItems[index].description}
                                        onChange={(e) => {
                                          const items = [...invoiceItems];
                                          items[index] = {
                                            ...items[index],
                                            description: e.target.value,
                                          };
                                          setInvoiceItems(items);
                                        }}
                                        onBlur={() =>
                                          setInvoiceInput({
                                            ...invoiceInput,
                                            items: invoiceItems,
                                          })
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="max-width-100 text-truncate border-bottom">
                                    <div className="color-white-60 c-input-box position-relative">
                                      <Form.Control
                                        as="textarea"
                                        placeholder={_l("l_description")}
                                        rows={1}
                                        className="inputh30 pb-1 ps-2 pt-1"
                                        value={
                                          invoiceItems[index].long_description
                                        }
                                        onChange={(e) => {
                                          const items = [...invoiceItems];
                                          items[index] = {
                                            ...items[index],
                                            long_description: e.target.value,
                                          };
                                          setInvoiceItems(items);
                                        }}
                                        onBlur={() =>
                                          setInvoiceInput({
                                            ...invoiceInput,
                                            items: invoiceItems,
                                          })
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="max-width-100 text-truncate border-bottom">
                                    <div className="color-white-60 c-input-box position-relative">
                                      <Form.Control
                                        type="number"
                                        placeholder={_l("l_quantity")}
                                        aria-label="Username"
                                        className="inputh30 pb-1 ps-2 pt-1"
                                        min="0"
                                        value={invoiceItems[index].qty}
                                        onChange={(e) => {
                                          const items = [...invoiceItems];
                                          let amount = 0;
                                          if (
                                            items[index].taxtnamewrapper &&
                                            items[index].rate > 0
                                          ) {
                                            amount =
                                              items[index].rate *
                                              e.target.value;
                                          }
                                          items[index] = {
                                            ...items[index],
                                            qty: e.target.value,
                                            item_total: amount,
                                          };
                                          setInvoiceItems(items);
                                        }}
                                        onBlur={() =>
                                          setInvoiceInput({
                                            ...invoiceInput,
                                            items: invoiceItems,
                                          })
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="max-width-100 text-truncate border-bottom">
                                    <div className="color-white-60 c-input-box position-relative">
                                      <Form.Control
                                        type="number"
                                        placeholder={_l("l_rate")}
                                        aria-label="Username"
                                        className="inputh30 pb-1 ps-2 pt-1"
                                        min="0"
                                        value={invoiceItems[index].rate}
                                        onChange={(e) => {
                                          const items = [...invoiceItems];
                                          let amount = 0;
                                          let taxAmount = 0;
                                          if (items[index].taxtnamewrapper) {
                                            amount =
                                              items[index].qty * e.target.value;
                                            let percentage =
                                              items[index].taxtnamewrapper
                                                .taxrate;
                                            taxAmount =
                                              ((e.target.value * percentage) /
                                                100) *
                                              items[index].qty;
                                          }
                                          items[index] = {
                                            ...items[index],
                                            rate: e.target.value,
                                            item_total: amount,
                                            tax_amount: taxAmount,
                                          };
                                          setInvoiceItems(items);
                                        }}
                                        onBlur={() =>
                                          setInvoiceInput({
                                            ...invoiceInput,
                                            items: invoiceItems,
                                          })
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="max-width-100 z-3 border-bottom">
                                    <div className="color-white-60 c-input-box position-relative">
                                      <div className="d-flex">
                                        <Select
                                          placeholder={_l("l_select")}
                                          className="custom-select-menu flex-grow-1 h_30px"
                                          options={taxFeild}
                                          classNamePrefix="react-select"
                                          value={invoiceItems[index].taxname}
                                          onChange={(e) => {
                                            const items = [...invoiceItems];
                                            let amount = 0;
                                            let taxAmount = 0;
                                            if (items[index].rate > 0) {
                                              let total =
                                                items[index].qty *
                                                items[index].rate;
                                              let percentage = e.taxrate;
                                              taxAmount =
                                                ((items[index].rate *
                                                  e.taxrate) /
                                                  100) *
                                                items[index].qty;
                                              amount =
                                                total +
                                                (percentage / 100) * total;
                                            }
                                            items[index] = {
                                              ...items[index],
                                              taxname: e.value,
                                              taxtnamewrapper: e,
                                              item_total: amount,
                                              tax_amount: taxAmount,
                                            };
                                            setInvoiceItems(items);
                                            setInvoiceInput({
                                              ...invoiceInput,
                                              items: items,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="max-width-100 text-truncate border-bottom text-center title-fonts">
                                    {invoiceItems[index].item_total}
                                  </td>
                                  <td className="comman_action_icon max-width-100 text-truncate border-bottom text-center ">
                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        if (addNewItemValidation(index)) {
                                          const newItem = [
                                            {
                                              description: "",
                                              long_description: "",
                                              order: 0,
                                              qty: 0,
                                              rate: 0,
                                              taxtnamewrapper: taxFeild[0],
                                              taxname: taxFeild[0].value,
                                              item_total: 0,
                                              tax_amount: 0,
                                              item_image: '',
                                              key_data : '',
                                              type_of_cost : ''
                                            },
                                          ].concat(invoiceItems);
                                          setInvoiceItems(newItem);
                                        }
                                      }}
                                    >
                                      {_l("l_add_item")}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}

                          <tr className="">
                            <td
                              colSpan={6}
                              className="text-end border-bottom pe-5  "
                            >
                              {_l("l_sub_total")}
                            </td>
                            <td className="border-bottom  color-white fw-semibold text-end">
                              {invoiceInput.estimate_subtotal}
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              colSpan={6}
                              className="text-end border-bottom  pe-0"
                            >
                              <Form.Group className=" c-input-box position-relative d-flex align-items-center justify-content-end">
                                <Form.Label className="input-label no-asterisk mb-0">
                                  {taxFeild.length > 0 && taxFeild[0].label}
                                </Form.Label>
                                <div className="d-flex  ">
                                  <Form.Control
                                    type="number"
                                    placeholder={invoiceInput.tax_amount_total}
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    value={invoiceInput.tax_amount_total}
                                    disabled
                                  />
                                </div>
                              </Form.Group>
                            </td>
                            <td className="border-bottom  color-white fw-semibold text-end">
                              {invoiceInput.tax_amount_total}
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              colSpan={6}
                              className="text-end border-bottom  pe-0"
                            >
                              <Form.Group className=" c-input-box position-relative d-flex align-items-center justify-content-end">
                                <Form.Label className="input-label no-asterisk mb-0">
                                  {`${_l("l_discount")}`}
                                  <span className="c-font f-10 ps-10px color-white">
                                    {_l("l_fixed_amount")}
                                  </span>
                                </Form.Label>
                                <div className="d-flex gap-2 ">
                                  <Form.Control
                                    type="number"
                                    placeholder={_l("l_discount")}
                                    aria-describedby="basic-addon1"
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    value={invoiceInput.discount_total}
                                    onChange={(e) => {
                                      setInvoiceInput({
                                        ...invoiceInput,
                                        discount_total: e.target.value,
                                        estimate_total: (
                                          parseFloat(
                                            invoiceInput.estimate_subtotal
                                          ) +
                                          parseFloat(
                                            invoiceInput.tax_amount_total
                                          ) -
                                          e.target.value
                                        ).toFixed(2),
                                      });
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            </td>
                            <td className="border-bottom  color-white fw-semibold text-end">
                              {invoiceInput.discount_total}
                            </td>
                          </tr>
                          <tr className="">
                            <td
                              colSpan={6}
                              className="text-end color-green pe-5"
                            >
                              {_l("l_total")}
                            </td>
                            <td className="fw-semibold  color-green text-end title-fonts">
                              {`€${invoiceInput.estimate_total}`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="border-top c-input-box pb-10px pt-10px terms-condition">
                        <div className=" fw-semibold pb-2">
                          {_l("l_client_note")}
                        </div>
                        <Form.Control
                          as="textarea"
                          placeholder={_l("l_description")}
                          rows={2}
                          value={invoiceInput.client_note}
                          onChange={(e) => {
                            setInvoiceInput({
                              ...invoiceInput,
                              client_note: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="terms-condition">
                        <div className=" fw-semibold pb-2">
                          {_l("l_terms_conditions")}
                        </div>
                        <div className=" pb-3 d-flex ">
                          <Form.Control
                            as="textarea"
                            placeholder={_l("l_terms_conditions")}
                            rows={2}
                            value={invoiceInput.terms}
                            onChange={(e) => {
                              setInvoiceInput({
                                ...invoiceInput,
                                terms: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              if (validateInputFields()) {
                setEmailModal(true);
              }
            }}
            disabled={!loader == "" ? 1 : 0}
          >
            {loader == "saveAndSend"
              ? _l("l_please_wait")
              : _l("l_save_and_send")}
            {loader == "saveAndSend" ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              createNewInvoices(0);
            }}
            disabled={!loader == "" ? 1 : 0}
          >
            {loader == "create" ? _l("l_please_wait") : _l("l_submit")}
            {loader == "create" ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {addressModal ? (
        <AddressModal
          show={addressModal}
          modalKey={addressModalKey}
          data={invoiceInput[addressModalKey]}
          handleClose={() => {
            setAddressModal(false);
            setAddressModalKey("");
          }}
          handleInput={(address, address2, checked) => {
            if (checked) {
              setInvoiceInput({
                ...invoiceInput,
                [addressModalKey]: address,
                shipping_address: address2,
              });
            } else {
              setInvoiceInput({
                ...invoiceInput,
                [addressModalKey]: address,
              });
            }
            setAddressModal(false);
            setAddressModalKey("");
          }}
          countryList={countryList}
        />
      ) : (
        <></>
      )}

      {emailModal ? (
        <AddEmailTemplatesModalNew
          show={emailModal}
          handleClose={() => setEmailModal(false)}
          type={"estimate"}
          from={"newEstimate"}
          createEstimateHandler={(sendEmailData) => {
            createNewInvoices(1, sendEmailData);
            setEmailModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AddInvoiceModal;
