import { _l } from "../../hooks/utilities";
import React, { useState } from "react";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import moment from "moment";
 
import { DropdownItem } from "reactstrap";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import DotsNineIcon from "../../assets/icons/DotsNineIcon";

const TaskTrackingListing = ({checklistItems, selectedAttachment, setSelectedAttachment, setShowTaskImage}) => {
  const [checklistPages, setChecklistPages] = useState(1);
  const checklistLength = checklistItems;
  const selectAttachment = (attachData) => {
    setSelectedAttachment(attachData.otherData);
    if(setShowTaskImage){
      setShowTaskImage(true); 
    }
  }
  return (
    <>
    <ul className="comman-list-line position-relative">
    {checklistItems && checklistItems.length > 0 ? (checklistItems.sort((a,b) => (b.finished - a.finished)).slice(0, checklistPages > 1 ? (checklistPages - 1) * 7 + 7 : checklistPages * 7).map((item) => {
        return (
          <li className="comman-list  ms-4 pe-1">
            <span className={`comman-list-dots ${item.finished == 1 ? "done" : ""}`}></span>
            <div className="d-flex">
              <div className="w100minus100per flex-grow-1 text-truncate">
                <a href="#/" className={`badge rounded-pill text-nowrap me-2 ${item.floor_name ? "" : "d-none"}`}
                   
                   
                  data-class="tooltip-main tooltip-tetx-break"
                   >
                  <span className="p-0">
                    {(item.floor_name)}
                  </span>
                </a>
                <a href="#/" className={`badge rounded-pill text-nowrap ${ item.space_item_name ? "" : "d-none"}`}
                   
                   
                  data-class="tooltip-main tooltip-tetx-break"
                   >
                  <span className="p-0">
                    {(item.space_item_name)}
                  </span>
                </a>
                <div className="c-font f-14 text-truncate pt-1" 
                 
                 
                 
                data-class="tooltip-main tooltip-tetx-break"
                >{item.description}</div>
                { 
                item.finished_datetime && item.finished_datetime != null && item.finished_datetime != ""  ?
                  <div className="c-font f-12 color-white-80">{moment(item.finished_datetime).format("DD/MM/YYYY hh:mm a")}</div>
                  :
                  <></>
                }
              </div>
              <div className="d-flex dropdown h30w30">
                <a href="#/" className="line-height-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="comman_action_icon"
                     
                     
                     
                    data-class="tooltip-main tooltip-tetx-break"
                  >
                    <div className="action_icon on-hover-active-toggle-img with_bg">
                      <DotsNineIcon className="HW16" />
                    </div>
                  </div>
                </a>
                <ActionDropdown
                  actions={[
                    {
                      actionName: _l("l_edit"),
                      actionIcon: "PencilSimpleLineIcon",
                    },
                    {
                      actionName: _l("l_delete"),
                      actionIcon: "TrashIcon",
                    },
                  ]}
                />
              </div>
               
            </div>
            <div className="d-flex flex-wrap upload-image-preview pt-10px">
            {
                item.attachments && item.attachments.length > 0 ?
                  item.attachments.map((attach, i) => {
                    if(i < 4 || item.attachments.length == 5){
                      return (
                        <div 
                          className={`comman-image-box h30w30 radius_3 upload-image me-10px border-transparent ${attach.id == selectedAttachment.id ? "active" : ""} ${i==0 ? "ms-0" : ""}`} 
                          onClick={() => { 
                            setSelectedAttachment(attach); 
                            if(setShowTaskImage){
                              setShowTaskImage(true); 
                            }
                          }}
                        >
                          <DropdownItem toggle={true} tag="div" className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{ backgroundImage: `url('${attach.image_url}')` }}></DropdownItem>
                        </div>
                      );
                    }
                  })
                : <></>
              }
              {
                item.attachments && item.attachments.length > 5 ?
                <>
                  <div class="comman-image-box h30w30 radius_3 with_overlay d-flex  me-10px"
                    type="button"
                    id="GeneralListing"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false">
                    <div class="unread_count m-auto title-fonts">+{item.attachments.length - 4}</div>
                  </div>
                  <GeneralListing handler={selectAttachment} roundedImage={false} list={item.attachments.map((generalAttach, index) => {
                    return {
                      name: generalAttach.file_name,
                      image: generalAttach.image_url,
                      id: generalAttach.id,
                      otherData: generalAttach
                    };
                  })} />
                </>
              : <></>}
            </div>
          </li>
        )
      }))
      : <></>
      }
      </ul>
      {(checklistLength.length >= (checklistPages - 1) * 7 + 7 &&
          checklistLength.length != (checklistPages - 1) * 7 + 7) ? 
        <div>
          <a href="#/"
            className="btn btn-white-03 w-100 view-more-btn"
            onClick={() => {
              setChecklistPages(prev => prev + 1)
            }}
          >
            <div className="d-flex align-item-center justify-content-center">
              <span className=" title-fonts c-font f-12 ms-2">
                {_l("l_view_more_btn")} (
                {checklistPages > 1
                            ? checklistLength.length -
                            ((checklistPages - 1) * 7 + 7)
                            : checklistLength.length - 7}
                )
              </span>
              <CaretDoubleDownIcon className="HW12 mx-2" />
            </div>
          </a>
        </div>
        : <></>}
      {
        !checklistItems || !checklistItems.length
        ?
        <CommanPlaceholder mainSpacing="mt-0" imgType="no-checklist"  placeholderText = {_l("l_no_checklist")} />
        :
        <></>
      }
    </>
  );
};

export default TaskTrackingListing;
