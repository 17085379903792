import moment from "moment";
import store from "../store";
import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;
const getDocumentInvoices = (project_id, filters, is_premium_invoices = 0, selectedDate="", limit = 10, page = 1, clientid = 0) => {
  const endPoint = window.location.pathname == '/invoice' ? API_URL + "mod=invoices&act=get_all_invoices" : window.location.pathname == '/clients' ? API_URL + "mod=invoices&act=get_client_invoices" : API_URL + "mod=invoices&act=get_invoices";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      if(category.main_key=="date_range_filter")
      {
        if(category.filter_key == "all")
        {
          form.append(
            `filters[from_date]`,
            category.filter_key
          );
        } else {
          if (localStorage.getItem("fromDate")) {
            form.append(
              `filters[from_date]`,
              localStorage.getItem("fromDate")
            );
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
            form.delete("selected_date");
          }
        }
      } else {
        form.append(
          `filters[${category.main_key}][${index}]`,
          category.filter_key
        );
      }
    });
  }
  form.append("project_id", project_id);
  form.append("is_premium_invoices", is_premium_invoices);
  form.append("selected_date", selectedDate);
  form.append("limit", limit);
  form.append("page", page);
  form.append("clientid", clientid);
  form.append("page_name", "premium_estimate");
  return apiService(endPoint, "POST", true, form);
};
const getDocumentSubscriptions = (project_id, filters, clientid=0) => {
  const endPoint = window.location.pathname == "/subscription"? API_URL + "mod=subscriptions&act=get_all_subscriptions" : window.location.pathname == "/clients"? API_URL + "mod=subscriptions&act=get_client_subscriptions" : API_URL + "mod=subscriptions&act=get_subscription_list";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      if(category.main_key=="date_range_filter")
      {
        if(category.filter_key == "all")
        {
          form.append(
            `filters[from_date]`,
            category.filter_key
          );
        } else {
          if (localStorage.getItem("fromDate")) {
            form.append(
              `filters[from_date]`,
              localStorage.getItem("fromDate")
            );
            form.append(`filters[to_date]`,  moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"));
            form.delete("selected_date");
          }
        }
      } else {
        form.append(
          `filters[${category.main_key}][${index}]`,
          category.filter_key
        );
      }
    });
  }
  form.append("project_id", project_id);
  form.append("clientid", clientid);
  form.append("selected_date", moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"));
  return apiService(endPoint, "POST", true, form);
};
const getDocumentEstimates = (project_id, filters, selectedDate="", clientid = 0) => {
  const endPoint = window.location.pathname == "/estimate"? API_URL + "mod=estimate&act=get_all_estimates" : window.location.pathname == "/clients"? API_URL + "mod=estimate&act=get_client_estimates" : API_URL + "mod=estimate&act=get_estimate_list";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      if(category.main_key=="date_range_filter")
      {
        if(category.filter_key == "all")
        {
          form.append(
            `filters[from_date]`,
            category.filter_key
          );
        } else {
          if (localStorage.getItem("fromDate")) {
            form.append(
              `filters[from_date]`,
              localStorage.getItem("fromDate")
            );
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
            form.delete("selected_date");
          }
        }
      } else {
        form.append(
          `filters[${category.main_key}][${index}]`,
          category.filter_key
        );
      }
    });
  }
  form.append("project_id", project_id);
  form.append("clientid", clientid);
  form.append("selected_date", selectedDate);
  return apiService(endPoint, "POST", true, form);
};
const getAllCreditNotes = (project_id, filters, selectedDate="", clientid=0) => {
  const endPoint = window.location.pathname == '/clients' ? API_URL + "mod=creditnotes&act=get_client_creditnotes" : API_URL + "mod=creditnotes&act=get_all_creditnotes";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      if(category.main_key=="date_range_filter")
      {
        if(category.filter_key == "all")
        {
          form.append(
            `filters[from_date]`,
            category.filter_key
          );
        } else {
          if (localStorage.getItem("fromDate")) {
            form.append(
              `filters[from_date]`,
              localStorage.getItem("fromDate")
            );
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
            form.delete("selected_date");
          }
        }
      } else {
        form.append(
          `filters[${category.main_key}][${index}]`,
          category.filter_key
        );
      }
    });
  }
  form.append("project_id", project_id);
  form.append("clientid", clientid);
  form.append("selected_date", selectedDate);
  return apiService(endPoint, "POST", true, form);
};
const getStaff = (project_id, filters, selectedDate="") => {
  const endPoint = window.location.pathname == "/clients" ? API_URL + "mod=user&act=client_listing" : API_URL + "mod=user&act=staff_listing";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      if(category.main_key=="date_range_filter")
      {
        if(category.filter_key == "all")
        {
          form.append(
            `filters[from_date]`,
            category.filter_key
          );
        } else {
          if (localStorage.getItem("fromDate")) {
            form.append(
              `filters[from_date]`,
              localStorage.getItem("fromDate")
            );
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
            form.delete("selected_date");
          }
        }
      } else {
        form.append(
          `filters[${category.main_key}][${index}]`,
          category.filter_key
        );
      }
    });
  }
  form.append("project_id", project_id);
  form.append("selected_date", selectedDate);
  return apiService(endPoint, "POST", true, form);
};
const getSpaceDocumentList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_space_document_list";
  let form = new FormData();
  // if (filters && filters.length) {
  //   filters.map((category, index) => {
  //     form.append(
  //       `filters[${category.main_key}][${index}]`,
  //       category.filter_key
  //     );
  //   });
  // }
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getInvoiceDetail = (project_id, invoice_id, hash_id, is_app_invoice = 0) => {
  const endPoint =  window.location.pathname == '/invoice' ? API_URL + "mod=invoices&act=invoice_detail" : window.location.pathname == '/clients' ? API_URL + "mod=invoices&act=invoice_detail" : API_URL + "mod=invoices&act=get_invoice_detail";
  let form = new FormData();
  if (window.location.pathname == "/dashboard") {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else if (hash_id != "" && !project_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("invoice_id", invoice_id);
    form.append("project_id", project_id);
  }
  form.append("is_app_invoice", is_app_invoice);
  return apiService(endPoint, "POST", true, form);
};
const getStaffDeatil = (id) => {
  const endPoint = window.location.pathname == "/clients" ? API_URL + "mod=user&act=get_client_detail" : API_URL + "mod=user&act=get_staff_detail";
  let form = new FormData();
    form.append("id", id);
  return apiService(endPoint, "POST", true, form);
};
const getEquipmentDetail = (id) => {
  const endPoint =  window.location.pathname == '/clients' ? API_URL + "mod=user&act=contact_detail" : API_URL + "mod=equipments&act=equipment_detail";;
  let form = new FormData();
    form.append("id", id);
  return apiService(endPoint, "POST", true, form);
};
const getEstimateDetail = (project_id, estimate_id, hash_id) => {
  const endPoint = window.location.pathname == '/estimate'? API_URL + "mod=estimate&act=get_single_estimate" : API_URL + "mod=estimate&act=get_estimate_detail";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("project_id", project_id);
    form.append("estimate_id", estimate_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getEstimateTopics = (pageName, id, hash_id) => {
  const endPoint =  API_URL + "mod=estimate&act=estimate_Topics";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("pageName", pageName);
    form.append("id", id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getStaffTopics = (pageName) => {
  const endPoint =  API_URL + "mod=user&act=staff_topics";
  let form = new FormData();
    form.append("pageName", pageName);
  return apiService(endPoint, "POST", true, form);
};

const getDocuments = (project_id, filters, rel_type, document_rel_id) => {
  const endPoint =  API_URL + "mod=document&act=get_documents";
  let form = new FormData();
  if(rel_type) {
    form.append("document_type", rel_type);
  }
  form.append("filters", JSON.stringify(filters));
  form.append("project_id", project_id);
  form.append("page_name", "project");
  if (document_rel_id && document_rel_id !== "") {
    if (document_rel_id > 0) {
      form.append("document_rel_id", document_rel_id);
    }
    form.append("document_rel_type", "providers");
  }
  return apiService(endPoint, "POST", true, form);
};

const updateStaffDate = (
  staffid,
  fname = "",
  lname = "",
  email = "",
  phone = "",
  hourly_rate = 0.00,
  skype = "",
  facebook = "",
  linkedin = "",
  type = 0,
  sex = "",
  status = 0,
) => {
  const endPoint =  API_URL + "mod=user&act=update_staff_data";
  let form = new FormData();
    form.append("staffid", staffid);
    form.append("fname", fname);
    form.append("lname", lname);
    form.append("email", email);
    form.append("phone", phone);
    form.append("hourly_rate", hourly_rate);
    form.append("skype", skype);
    form.append("facebook", facebook);
    form.append("linkedin", linkedin);
    form.append("type", type);
    form.append("sex", sex);
    form.append("status", status);
  return apiService(endPoint, "POST", true, form);
};
const updateCustomerData = (
  userid,
  comapny = "",
  vat = "",
  phone = "",
  website = "",
  language = "",
  address = "",
  city = "",
  state = 0,
  zipCode = 0,
  country = 0,
  status = 0,
) => {
  const endPoint =  API_URL + "mod=user&act=update_client_data";
  let form = new FormData();
    form.append("userid", userid);
    form.append("comapny", comapny);
    form.append("vat", vat);
    form.append("website", website);
    form.append("phone", phone);
    form.append("language", language);
    form.append("address", address);
    form.append("city", city);
    form.append("state", state);
    form.append("zipCode", zipCode);
    form.append("country", country);
    form.append("status", status);
  return apiService(endPoint, "POST", true, form);
};
const updateStaffPermission = (
  staffid,
  index = "",
  key = "",
  status = ""
) => {
  const endPoint =  API_URL + "mod=user&act=update_staff_permission";
  let form = new FormData();
    form.append("staffid", staffid);
    form.append("index", index);
    form.append("key", key);
    form.append("status", status);
  return apiService(endPoint, "POST", true, form);
};
const changeEstimateTopics = (filterKey, status) => {
  const endPoint = window.location.pathname == "/staff" ? API_URL + "mod=user&act=change_staff_topic" :  API_URL + "mod=estimate&act=change_estimate_topic";
  let form = new FormData();

    form.append("filterKey", filterKey);
    form.append("status", status);
  return apiService(endPoint, "POST", true, form);
};

const getDocumentDetail = (project_id, document_id) => {
  const endPoint = API_URL + "mod=google_drive_integration&act=get_file";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("document_id", document_id);

  return apiService(endPoint, "POST", true, form);
};

const getSubscriptionDetail = (project_id, subscription_id, hash_id, is_external_client) => {
  const endPoint = API_URL + "mod=subscriptions&act=get_subscription_detail";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("project_id", project_id);
    form.append("subscription_id", subscription_id);
  }
  if(is_external_client)
  {
    form.append("is_external_client", is_external_client);
  }
  return apiService(endPoint, "POST", true, form);
};

const subscribe = (hash_id, date, email, isExternal, project_id, auto_subscribe, operator_client_id, customer_client_id, clientId = 0, payment_mode) => {

  //As a part of new wallet flow, need to use new endpoint for subscribe
  // const endPoint = API_URL + "mod=subscriptions&act=subscription_action";

  const endPoint = payment_mode === "stripe" ? API_URL + "mod=subscriptions&act=stripe_subscription" : API_URL + "mod=subscriptions&act=subscription_action";

  let form = new FormData();

  const currentDate = moment();

  const tenDaysFromNow = moment(currentDate).add(9, 'days');

  const providedDate = moment(date);

  let subscriptionDate = ""

  if (providedDate.isSameOrAfter(tenDaysFromNow)) {
    subscriptionDate =  providedDate.toDate(); 
  }
  else {
    const nextMonthDate = moment(currentDate).add(1, 'months');
    providedDate.month(nextMonthDate.month());
    providedDate.year(nextMonthDate.year());
    subscriptionDate = providedDate.toDate();
  }

  if (hash_id) {
    form.append("hash_id", hash_id);
  }
  if (isExternal) {
    form.append("is_external", 1);
  }
  if (clientId > 0) {
    form.append("client_id",clientId)
  }
  form.append("project_id", project_id);
  form.append("date", moment(subscriptionDate).format("YYYY-MM-DD"));
  form.append("email", email);
  if(customer_client_id)
  {
    form.append("customer_client_id", customer_client_id);
  }
  if (clientId) {
    form.append("client_id",clientId)
  }
  if(auto_subscribe)
  {
    form.append("auto_subscribe", auto_subscribe);
    form.append("operator_client_id", operator_client_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const acceptEstimate = (hash_id) => {
  const endPoint = API_URL + "mod=estimate&act=accept_premium_estimate";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const rejectEstimate = (hash_id) => {
  const endPoint = API_URL + "mod=estimate&act=reject_estimate";
  let form = new FormData();
  if (hash_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  }
  return apiService(endPoint, "POST", true, form);
};

const addDocumentComment = (
  rel_type,
  rel_id,
  content,
  is_external,
  record_id,
  files = undefined, 
  master_record_id, 
  bulk_rel_ids,
  replyCommentId,
  position = undefined,
  is_ai_comment = 0,
  lease_id
) => {
  const endPoint = API_URL + "mod=comment&act=add_comment";
  const guest_id = localStorage.getItem("guest_id") || -1;
  const benchmarkStore = store.getState().customer.benchmarkDetail;

  let form = new FormData();
  switch(rel_type)
  {
    case "benchmark":
        form.append("rel_type", "schedule");
        form.append("rel_id", rel_id);
      break;
    case "proposal":
      form.append("rel_type", "schedule");
      form.append("rel_id", Number(master_record_id) > 0 ? master_record_id : benchmarkStore && benchmarkStore.id ? benchmarkStore.id : 0);
      form.append("proposal_id", rel_id);
      break;
    case "subscription":
      form.append("rel_type", "subscription");
      form.append("rel_id", rel_id);
      break;
    case "leaseDocument":
      form.append("rel_type", "lease_document");
      form.append("rel_id", rel_id);
      break;
    default:
      form.append("rel_type", rel_type);
      form.append("rel_id", rel_id);
      form.append("project_id", localStorage.getItem("selectedOffice"));
  }
  if (is_ai_comment) {
    form.append("is_ai_comment", 1);
  }
  if (lease_id) {
    form.append("lease_id", lease_id);
  }

  form.append("content", content);
  if (is_external) {
    form.append("is_external", 1);
    form.append("guest_id", guest_id);
  }
  if (record_id > 0) {
    form.append("comment_id", record_id);
  }
  if (files) {
    Array.from(files).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  if(bulk_rel_ids && bulk_rel_ids.length)
  {
    bulk_rel_ids.forEach((id, index) => {
      form.append(`proposal_id_bulk[${index}]`, id);
    })
  }
  if (replyCommentId && replyCommentId != '' && replyCommentId != undefined) {
    form.append("parent_comment_id", replyCommentId)
  }
  if (position) {
    form.append(`position`, JSON.stringify(position));
  }

  return apiService(endPoint, "POST", true, form, 0, false, guest_id);
};
const getMentionlist = (rel_id, rel_type, is_external = 0) => {
  const endPoint = API_URL + "mod=comment&act=get_names_for_mentions";
  let form = new FormData();
  const guest_id = localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : -1;
  // if (rel_type == "subscription") {
  //   rel_type = "subscriptions";
  // }
  form.append("rel_type", rel_type);
  form.append("rel_id", rel_id);
  if (is_external) {
    form.append("is_external", 1);
  }
  return apiService(endPoint, "POST", true, form, 0, false, guest_id);
};
const getComments = (rel_id, rel_type, is_external = 0, hash = 0, mark_as_read, master_record_id = 0, parent_comment_id = 0, attachment_id = 0) => {
  const endPoint = API_URL + "mod=comment&act=get_comments";
  let form = new FormData();
  const guest_id = localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : -1;
  const benchmarkStore = store.getState().customer.benchmarkDetail;
  // if (rel_type == "subscription") {
  //   rel_type = "subscriptions";
  // }
  switch(rel_type)
  {
    case "benchmark":
      form.append("rel_type", "schedule");
      form.append("rel_id", rel_id);
      break;
    case "proposal":
      form.append("rel_type", "schedule");
      form.append("rel_id", Number(master_record_id) > 0 ? master_record_id : benchmarkStore && benchmarkStore.id ? benchmarkStore.id : 0);
      form.append("proposal_id", rel_id);
      break;
    case "leaseDocument":
      form.append("rel_type", "lease_document");
      form.append("rel_id", rel_id);
      break;
    default:
      form.append("rel_type", rel_type);
      form.append("rel_id", rel_id);
  }
  
  if (is_external || hash) {
    form.append("is_external", 1);
    form.append("guest_id", guest_id);
    form.append("hash_id", hash);
  }

  if (localStorage.getItem("externalTaskData") && localStorage.getItem("externalTaskHash")) {
    form.append("is_external", 1);
  }
  form.append("all_comments", 1);
  if(mark_as_read)
  {
    form.append("mark_as_read", mark_as_read);
  }
  if(parent_comment_id > 0){
    form.append("parent_comment_id", parent_comment_id);
  }
  if(attachment_id > 0){
    form.append("attachment_id", attachment_id);
  }
  return apiService(endPoint, "POST", true, form, 0, false, guest_id);
};

const removeComment = (rel_id, rel_type, comment_id, is_external = 0, master_record_id) => {
  const endPoint = API_URL + "mod=comment&act=remove_comment";
  let form = new FormData();
  const guest_id = localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : -1;
  const benchmarkStore = store.getState().customer.benchmarkDetail;
  // if (rel_type == "subscription") {
  //   rel_type = "subscriptions";
  // }
  switch(rel_type)
  {
    case "benchmark":
      form.append("rel_type", "schedule");
      form.append("rel_id", rel_id);
      break;
    case "proposal":
      form.append("rel_type", "schedule");
      form.append("rel_id", Number(master_record_id) > 0 ? master_record_id : benchmarkStore && benchmarkStore.id ? benchmarkStore.id : 0);
      form.append("proposal_id", rel_id);
      break;
    case "leaseDocument":
        form.append("rel_type", "lease_document");
        form.append("rel_id", rel_id);
      break;
    default:
      form.append("rel_type", rel_type);
      form.append("rel_id", rel_id);
  }
  form.append("comment_id", comment_id);
  if (is_external) {
    form.append("is_external", 1);
    form.append("guest_id", guest_id);
  }
  return apiService(endPoint, "POST", true, form, 0, false, is_external ? guest_id : -1);
}
const setDocumentAssignee = (
  assign_key,
  assigne_value,
  rel_type,
  rel_id = 0
) => {
  const endPoint = API_URL + "mod=document&act=document_assignee";
  let form = new FormData();
  form.append("assign_key", assign_key);
  form.append("assigne_value", assigne_value);
  form.append("rel_type", rel_type);
  form.append("rel_id", rel_id);
  return apiService(endPoint, "POST", true, form);
};

const getCreditNotesList = (project_id, filters) => {
  const endPoint = API_URL + "mod=creditnotes&act=get_credit_note_list";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      form.append(
        `filters[${category.main_key}][${index}]`,
        category.filter_key
      );
    });
  }
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getEquipmentCategoryTopics = () => {
  const endPoint = API_URL + "mod=equipments&act=equipment_category_topics";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}
const getCreditNoteDetail = (project_id, credit_note_id, hash_id, is_app_credit_note) => {
  const endPoint = (window.location.pathname == "/credit_notes" || window.location.pathname == "/clients" )? API_URL + "mod=creditnotes&act=credit_note_details" : API_URL + "mod=creditnotes&act=get_credit_note_details";
  let form = new FormData();
  if (window.location.pathname == "/dashboard") {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else if (hash_id != "" && !project_id) {
    form.append("hash_id", hash_id);
    form.append("is_external", 1);
  } else {
    form.append("credit_note_id", credit_note_id);
    form.append("project_id", project_id);
  }
    form.append("is_app_credit_note", is_app_credit_note);
  
  return apiService(endPoint, "POST", true, form);
};

const getPilotOnboardingDocumentDetail = (project_id, document_id) => {
  const endPoint = API_URL + "mod=document&act=get_pilot_onboarding_document_details";
  let form = new FormData();
  form.append("project_id", project_id);
  if (document_id && document_id > 0) { 
    form.append("document_id", document_id);
  }
  return apiService(endPoint, "POST", true, form);
};
const getPilotOnboardingDocumentList = (project_id) => {
  const endPoint = API_URL + "mod=document&act=get_pilot_onboarding_document_list";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const addPilotOnboardingDocumentDetail = (project_id, document_id, document_type, company_name, total_area, tenants, cost, visibility, meeting, tools, people, saving, facility_manager) => {
  const endPoint = API_URL + "mod=document&act=add_pilot_onboarding_document_details";
  let form = new FormData();
  form.append("project_id", project_id);
  if (document_id && document_id > 0) { 
    form.append("document_id", document_id);
  }
  form.append("document_type", document_type);
  if (visibility.length > 0) {
    for (let i = 0; i < visibility.length; i++) {
      form.append(`visibility[${i}]`, visibility[i]);
    }
  }
  form.append("company_name", company_name);
  form.append("saving", saving);
  form.append("total_area", total_area);
  form.append("tenants", tenants);
  form.append("cost", cost);
  form.append("meeting", meeting);
  if (tools.length > 0) {
    for (let i = 0; i < tools.length; i++) {
      form.append(`tools[${i}]`, tools[i]);      
    }
  }
  if (people.length > 0) {
    for (let i = 0; i < people.length; i++) {
      form.append(`people[${i}]`, people[i]);      
    }
  }
  if (facility_manager != null && Object.keys(facility_manager).length) {
    form.append("facility_manager[company_name]", facility_manager.company_name);
    form.append("facility_manager[company_address]", facility_manager.company_address);
    form.append("facility_manager[meeting]", facility_manager.meeting);
    form.append("facility_manager[cost]", facility_manager.cost);
    if (facility_manager.tools && facility_manager.tools.length > 0) { 
      for (let j = 0; j < facility_manager.tools.length; j++) {
        if (facility_manager.tools[j]) { 
          form.append(`facility_manager[tools][${j}]`, facility_manager.tools[j]);
        }
      }
    }
    if (facility_manager.people && facility_manager.people.length > 0) { 
      for (let k = 0; k < facility_manager.people.length; k++) {
        if (facility_manager.people[k].full_name) { 
          form.append(`facility_manager[people][${k}][full_name]`, facility_manager.people[k].full_name);
        }
        if (facility_manager.people[k].role) { 
          form.append(`facility_manager[people][${k}][role]`, facility_manager.people[k].role);
        }
      }
    }
  }
    return apiService(endPoint, "POST", true, form);
};

const getOnboardProviderList = (project_id, document_id) => {
  const endPoint = API_URL + "mod=document&act=get_pilot_onboarding_document_provider_list";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("document_id", document_id);
  return apiService(endPoint, "POST", true, form);
};

const addEditOnboardProvider = (projectId, document_id, providerType, cost, meeting, tools, people, saving, document_provider_id) => {
  const endPoint = API_URL + "mod=document&act=add_pilot_onboarding_document_provider";
  let form = new FormData();
  if (document_provider_id > 0) { 
    form.append("document_provider_id",document_provider_id);
  }
  form.append("project_id", projectId);
  form.append("document_id", document_id);
  form.append("saving", saving);
  form.append("provider_type", providerType);
  form.append("cost", cost);
  form.append("meeting", meeting);
  form.append("people", people);
  if (tools.length > 0) {
    for (let i = 0; i < tools.length; i++) {
      form.append(`tools[${i}]`, tools[i].label);
    }
  }
  return apiService(endPoint, "POST", true, form);
};

const deleteOnboardProvider = (project_id, document_id, document_provider_id) => {
  const endPoint = API_URL + "mod=document&act=delete_pilot_onboarding_document_provider";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("document_id", document_id);
  form.append("document_provider_id",document_provider_id);
  return apiService(endPoint, "POST", true, form);
};
const getOnboardPDF = (project_id, document_id) => {
  const endPoint = API_URL + "mod=document&act=pdf_generate";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("document_id", document_id);
  return apiService(endPoint, "POST", true, form);
};
const changeEquipmentStatus = (project_id, filter_key, is_active) => {
  const endPoint =  window.location.pathname == "/clients" ? API_URL + "mod=user&act=change_client_category_topics" :  API_URL + "mod=equipments&act=change_equipment_category_topics";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("filter_key", filter_key);
  form.append("is_active", is_active);
  return apiService(endPoint, "POST", true, form);
};
const getClientContacts = (clientid, is_space) => {
  let endPoint = '';
  if(is_space && is_space == 'space')
  {
    endPoint = API_URL + "mod=space&act=get_client_spaces";
  } else {
    endPoint = API_URL + "mod=user&act=get_client_contacts";
  }
  let form = new FormData();
  form.append("clientid", clientid);
  return apiService(endPoint, "POST", true, form);
};

const equipmentlist = (project_id, filters, selectedDate="") => {
  const endPoint = API_URL + "mod=equipments&act=equipment_list";
  let form = new FormData();
  if (filters && filters.length) {
    filters.map((category, index) => {
      if(category.main_key=="date_range_filter")
      {
        if(category.filter_key == "all")
        {
          form.append(
            `filters[from_date]`,
            category.filter_key
          );
        } else {
          if (localStorage.getItem("fromDate")) {
            form.append(
              `filters[from_date]`,
              localStorage.getItem("fromDate")
            );
            form.append(`filters[to_date]`, localStorage.getItem("toDate"));
            form.delete("selected_date");
          }
        }
      } else {
        form.append(
          `filters[${category.main_key}][${index}]`,
          category.filter_key
        );
      }
    });
  }
  form.append("project_id", project_id);
  form.append("selected_date", selectedDate);
  return apiService(endPoint, "POST", true, form);
};

const updateSubscriptionDate = (subscription_id, subscription_date) => {
  const endPoint = API_URL + "mod=subscriptions&act=update_subscription_date";
  let form = new FormData();
  form.append("subscription_id", subscription_id);
  form.append("subscription_date", subscription_date);
  return apiService(endPoint, "POST", true, form);
};

const payAppInvoice = (hash, project_id, is_app_invoice, is_one_time_benchmark, proposal_wallet_id, proposal_id) => {
  const endPoint = API_URL + "mod=invoices&act=create_payment_invoice";
  let form = new FormData();
  form.append("hash_id", hash);
  form.append("project_id", project_id);
  form.append("is_app_invoice", is_app_invoice);
  form.append("proposal_wallet_id", proposal_wallet_id);
  form.append("is_one_time_benchmark", is_one_time_benchmark);
  form.append("proposal_id", proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const getCommentMailRecipients = (document_id, document_type, project_id) => {
  const endPoint = API_URL + "mod=comment&act=get_user_list_for_custom_email_send";
  let form = new FormData();
  form.append("rel_id", document_id);
  form.append("rel_type", document_type);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getPeopleDocuments = (project_id, filter_user_id, document_type) => {
  const endPoint = API_URL + "mod=document&act=get_user_all_document_by_type";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("filter_user_id", filter_user_id);
  if(document_type)
  {
    form.append("document_type", document_type);
  }
  return apiService(endPoint, "POST", true, form);
}
const cancelCustomerSubscription = (subscription_id, type ) => {
  const endPoint = API_URL + "mod=subscriptions&act=cancel_subscription";
  let form = new FormData();
  form.append("subscription_id", subscription_id);
  if(type)
  {
    form.append("type", type);
  }
  return apiService(endPoint, "POST", true, form);
};

const updateDocumentActivityStatus = (project_id, filter_user_id, document_type, document_id, active) => {
  const endPoint = API_URL + "mod=document&act=active_user_document";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("filter_user_id", filter_user_id);
  form.append("document_id", document_id);
  form.append("document_type", document_type);
  form.append("active", active);
  return apiService(endPoint, "POST", true, form);
};

const uploadEmployeeDocuments = (documentData, user_staff_id, is_gallery = false) => {
  const endPoint = API_URL + "mod=company_profile&act=add_user_document";
  let form = new FormData();
  form.append("user_staff_id", user_staff_id);
  if(!is_gallery && documentData && Object.keys(documentData).length)
  {
    Object.keys(documentData).forEach((type) => {
      if(documentData[type].files)
      {
        Array.from(documentData[type].files).forEach((file, index) => {
          form.append(`${documentData[type].key}[${index}]`, file);
        })
      }else if(documentData[type].file){
        Array.from(documentData[type].file).forEach((file, index) => {
          form.append(`${documentData[type].key}[${index}]`, file);
        })
      }
    });
    form.append("active_document_position", 0);
 }

 if(is_gallery && documentData && Object.keys(documentData).length)
  {
    // Object.keys(documentData).forEach((type) => {
      if(documentData.files)
      {
        Array.from(documentData.files).forEach((file, index) => {
          form.append(`${documentData.document_type}[${index}]`, file);
        })
      }
    // });
    form.append("active_document_position", 0);
 }
 
  return apiService(endPoint, "POST", true, form);
};

const getOperatorPayoutReport = (project_ids, amount, status) => {
  const endPoint = API_URL + "mod=invoices&act=payouts_report";
  let form = new FormData();
  if(project_ids && project_ids.length)
  {
    project_ids.forEach((id, index) => {
      form.append(`project_ids[${index}]`, id);
    })
  }
  if(amount)
  {
    form.append("amount", amount);
  }
  if(status)
  {
    form.append("status", status);
  }
  return apiService(endPoint, "POST", true, form);
};

  const getPremiumEstimateDetail = (project_id) => {
    const endPoint = API_URL + "mod=space&act=premium_estimate";
    let form = new FormData();
      form.append("project_id", project_id);
    return apiService(endPoint, "POST", true, form);
  };


  const getDocumentsForDashboard = (is_external, hash_id, is_dashboard, doc_type = '') => {
    const endPoint =  API_URL + "mod=dashboard&act=get_dashboard_documents";
    let form = new FormData();
    form.append("is_dashboard", 1);
    // form.append("is_external", 1);
    if (hash_id) {
      // form.append("hash_id", hash_id);
    }
    if (doc_type) {
      form.append("document_rel_type", doc_type);
      }
    return apiService(endPoint, "POST", true, form);
  };
  
  const handleEstimateStatus = (staff_id, estimate_id, status) => {
    const endPoint =  API_URL + "mod=estimate&act=change_estimate_status";
    let form = new FormData();
    form.append("staff_id", staff_id);
    form.append("estimate_id", estimate_id);
    form.append("status", status);
    return apiService(endPoint, "POST", true, form);
  }

  const copyEstimateByAdmin = (staff_id, estimate_id) => {
    const endPoint =  API_URL + "mod=estimate&act=copy_estimate_by_admin";
    let form = new FormData();
    form.append("staff_id", staff_id);
    form.append("estimate_id", estimate_id);
    return apiService(endPoint, "POST", true, form);
  }

  const sendEstimateAsEmail = (staff_id, email_send_to_id, estimate_id, estimatePdfCheck, reminder_date) => {
    const endPoint =  API_URL + "mod=estimate&act=send_estimate_as_email";
    let form = new FormData();
    form.append("staff_id", staff_id);
    form.append("email_send_to_id", email_send_to_id);
    form.append("estimate_id", estimate_id);
    form.append("attachpdf", estimatePdfCheck ? 1 : 0);
    form.append("reminder_date ", reminder_date);
    return apiService(endPoint, "POST", true, form);
  }

  const deleteEstimateAdmin = (estimate_id) => {
    const endPoint =  API_URL + "mod=estimate&act=delete_estimate_by_admin";
    let form = new FormData();
    form.append("estimate_id", estimate_id);
    return apiService(endPoint, "POST", true, form);
  };

  const topicDocumentUpload = (staff_id, contact_id, client_id, project_id, document_rel_id, users, files) => {
    const endPoint =  API_URL + "mod=document&act=upload_topic_document";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("document_rel_id", document_rel_id);
    form.append("document_rel_type", "providers");
    if (users && users.length > 0) {
      Array.from(users).map((user, index) => {
        form.append(`visible_users[${index}]`, user);
      });
    }
    // if (files) {
    //   Array.from(files).map((file, index) => {
    //   });
    // }
    form.append(`file[${0}]`, files);
    return apiService(endPoint, "POST", true, form);
  };

  const deleteTopicDocument = (staff_id, contact_id, client_id, project_id, document_rel_id, document_rel_type, file_id) => {
    const endPoint =  API_URL + "mod=document&act=delete_topic_document";
    let form = new FormData();
    form.append("staff_id", staff_id);
    form.append("contact_id", contact_id);
    form.append("client_id", client_id);
    form.append("project_id", project_id);
    form.append("document_rel_id", document_rel_id);
    form.append("document_rel_type", document_rel_type);
    form.append("file_id", file_id);
    return apiService(endPoint, "POST", true, form);
  };


  const editTopicDocument = (staff_id, contact_id, client_id, project_id, document_rel_id, document_rel_type, visible_users, file_id) => {
    const endPoint =  API_URL + "mod=document&act=edit_topic_document";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("document_rel_id", document_rel_id);
    form.append("document_rel_type", document_rel_type);
    if (visible_users && visible_users.length > 0) {
      Array.from(visible_users).map((visible_users, index) => {
        form.append(`visible_users[${index}]`, visible_users);
      });
    }
    form.append("file_id", file_id);
    return apiService(endPoint, "POST", true, form);
  };
  const editSubscriptionCancel = (id, premiumData ) => {
    const endPoint = API_URL + "mod=user_payment&act=edit_subscription";
    let form = new FormData();
    form.append("user_payment_id", premiumData.user_payment_id);
    form.append("subscription_id", premiumData.subscription_id);
    form.append("office_id", id);
    return apiService(endPoint, "POST", true, form);
  };
export default {
  acceptEstimate,
  addDocumentComment,
  getInvoiceDetail,
  rejectEstimate,
  getDocumentSubscriptions,
  getDocumentEstimates,
  getEstimateDetail,
  getSpaceDocumentList,
  getDocumentDetail,
  getSubscriptionDetail,
  getDocumentInvoices,
  getDocuments,
  subscribe,
  getMentionlist,
  getCreditNoteDetail,
  getComments,
  removeComment,
  setDocumentAssignee,
  getCreditNotesList,
  getEstimateTopics,
  getPilotOnboardingDocumentDetail,
  addPilotOnboardingDocumentDetail,
  getPilotOnboardingDocumentList,
  getOnboardProviderList,
  addEditOnboardProvider,
  deleteOnboardProvider,
  getOnboardPDF,
  changeEstimateTopics,
  getStaff,
  getStaffDeatil,
  getStaffTopics,
  updateStaffDate,
  updateCustomerData,
  updateStaffPermission,
  getAllCreditNotes,
  getEquipmentCategoryTopics,
  changeEquipmentStatus,
  equipmentlist,
  getEquipmentDetail,
  getClientContacts,
  updateSubscriptionDate,
  payAppInvoice,
  getCommentMailRecipients,
  getPeopleDocuments,
  updateDocumentActivityStatus,
  uploadEmployeeDocuments,
  cancelCustomerSubscription,
  getOperatorPayoutReport,
  getPremiumEstimateDetail,
  getDocumentsForDashboard,
  handleEstimateStatus,
  copyEstimateByAdmin,
  sendEstimateAsEmail,
  deleteEstimateAdmin,
  topicDocumentUpload,
  deleteTopicDocument,
  editTopicDocument,
  editSubscriptionCancel
};
