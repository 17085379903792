import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { ModelViewerPoint, getSpaceFloorList, selectedFloorId,  updateLocalAnchor, updatedFloorList } from "../../actions/customer";
import { useNavigate } from "react-router-dom";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import PlusIcon from "../../assets/icons/PlusIcon";
import AddEditFloor from "../Modals/AddEditFloor";
import placeholder from "../../assets/images/no-checklist.svg";
import PencilSimpleLineIcon from "../../assets/icons/PencilSimpleLineIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
const FloorFilterDropdown = ({isExternal = false, data}) => {
  const [floorList, setFloorList] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState();
  const [addFloor, setAddFloor] = useState(false);
  const [floorData, setFloorData] = useState();
  const userType = localStorage.getItem("user_type");
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isExternal) { 
      dispatch(getSpaceFloorList(isExternal, data.hash));
    }
  }, [])
  const { getFloorDocument, getSapceFloorList, getSelectedFloorId } = useSelector(
    (state) => state.customer
  );
  useEffect(() => {
    if (selectedFloor > 0) {
      dispatch(selectedFloorId(selectedFloor));
    }
  }, [selectedFloor]) 
  useEffect(() => {
    if (getSapceFloorList && getSapceFloorList.floor && getSapceFloorList.floor.length > 0) {
      setFloorList(getSapceFloorList.floor.map((f) => {
        return { label: f.title, value: f.id, hash: f.hash, sub_floors: f.sub_floors }
      }));
      setSelectedFloor(getSelectedFloorId == 0 ? getSapceFloorList.floor[0].id : getSelectedFloorId)
    }
  }, [JSON.stringify(getSapceFloorList)])
  useEffect(() => {
    if (getFloorDocument && getFloorDocument.length > 0) {
      setSelectedFloor(getFloorDocument.floor_id)
    }
    if(data){
      setSelectedFloor(data.floor_id)
    }
  }, [getFloorDocument,data])
  const getSpaceDocumentByFloorId = (id, hash) => {
    setSelectedFloor(id);
    dispatch(updateLocalAnchor([]));
    dispatch(ModelViewerPoint(false));
    if(isExternal) {
      if(hash != "" && hash != undefined){
        navigate(`/spaceDocument/${hash}`)
      }else{
        dispatch(showMessage("unsucess", _l("l_error"), _l("l_document_is_not_available_for_this_floor")));
      }
    }
  }
  const deleteSpaceFloor = (floor_id) => {
    customerServices.deleteFloor(floor_id).then((res) => {
      if (res.status == 1) {
        let updateList = getSapceFloorList.filter(f => f.id != floor_id)
        dispatch(updatedFloorList(updateList));
        dispatch(toastCloseAction());
        dispatch(showMessage('sucess', _l('l_success'), res.message));
      }else{
        dispatch(showMessage('unsucess', _l('l_error'), res.message));
      }
    });
  }
  const deleteFloor = (floor_id) =>{
      dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_all_documents_related_to_this_floor_will_be_removed_Are_you_sure_you_want_to_perform_this_action"), _l("l_yes"), _l("l_no"), undefined, () => {deleteSpaceFloor(floor_id);}));
  }
  return (
    <>
      <ul className="dropdown-menu w-250" aria-labelledby="FloorFilterDropdown">
        <li>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail c-font f-14 title-fonts text-truncate color-white">
              {_l("l_floor_filter")}
              </div>
              {!isExternal && (userType == "contact" && (role == "1" || role == "2") || userType == "staff" )  ?
              <a href="#/" className="right-image" data-bs-dismiss="dropdown" onClick={()=>{
                setAddFloor(true);
                setFloorData(undefined)
              }} >
                <PlusIcon className="HW18 opacity-60" />
              </a>
              :
              ""
              }
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider mt-0" />
        </li>
        <div className="floor-listing-wrapper overflowscroll">
        { floorList && floorList.length ?
            floorList.map((f, k) => {
              return (
                <>
                <li key={k} className="comman-list  with-after-40 ">
                  <div className="form-check with-span d-flex align-items-center">
                    <div className="d-flex align-items-center w-100">
                      <Form.Group className="form-check ps-0 w-100">
                        <Form.Label className="form-check-label mb-0 text-truncate w-100">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="invitepeopleradio"
                            onChange={() => { getSpaceDocumentByFloorId(f.value, f.hash) }}
                            checked={f.value == selectedFloor}
                          />
                          <span className="ps-2 text-truncate">{f.label}</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    {!isExternal && (userType == "contact" && (role == "1" || role == "2") || userType == "staff" ) ? 
                    <div className="d-flex align-items-center">
                      <div className="me-2 dropdown dropend">
                        <a href="#/" className="h25w25 d-flex bg-base-header-color radius_3"
                        onClick={()=>{
                          setAddFloor(true);
                          setFloorData(f)
                        }}
                        ><PencilSimpleLineIcon className="HW12" /></a>
                      </div>
                      <div className="dropdown dropend">
                       <a href="#/" className="h25w25 d-flex bg-base-header-color radius_3"
                       onClick={()=>deleteFloor(f.value)}
                       ><TrashIcon className="HW12" /></a>
                      </div>
                    </div>
                      :
                      ""}
                  </div>
                </li>
                </>
              )
            })
            :
          <>
            <div className="comman-placeholder-wrapper w-100  mt-3">
              <div className="d-flex flex-column align-items-center m-auto">
                <img
                  src={placeholder}
                  alt="placeholder"
                  className="big-image"
                />
                <span className="color-white-80 py-2 title-fonts">
                  {_l("l_no_floor_list")}
                </span>
              </div>
            </div>
          </>
          }
        </div>
      </ul>
      {
        <AddEditFloor
          show={addFloor}
          handleClose={() => {
            setAddFloor(false);
          }}
          floorData = {floorData}
          getSpaceDocumentByFloorId={getSpaceDocumentByFloorId}
          getSapceFloorList={getSapceFloorList}
        />
      }
    </>
  );
};
export default FloorFilterDropdown;
