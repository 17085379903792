import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import ImportProviders from "../../Modals/ImportProviders";
import {
  _l,
  copyToClipboard,
  csvOnLoadHandler,
  showError,
  validateEmail,
} from "../../../hooks/utilities";
import {
  CONTACT_ROLE_LISTING,
} from "../../../constants/constants";
import CustomerServices from "../../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../actions/messages";
 
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import AddEmployee from "../../Modals/AddEmployee";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import BenchmarkServices from "../../../services/benchmark-services";
import { getDefaultTopics } from "../../../actions/customer";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import CopyIcon from "../../../assets/icons/CopyIcon";
import PencilIcon from "../../../assets/icons/PencilIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";

let selectedTenantData = {};

const CreateTenant = ({
  tenantsData,
  setTenantsData,
  projectId,
  teamList,
  getTeamList,
  showTeams,
  setHeaderText,
  setTitlePrefix
}) => {
  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];
  const {leftPanelTab} = useSelector((state) => state.customer);
  const virtualspaces = JSON.parse(localStorage.getItem("selectedVirtualSpace"))
  const [bulkUploads, setBulkUploads] = useState(false);
  const [inviteSendLoader, setInviteSendLoader] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [processedCSV, setProcessedCSV] = useState("");
  const [bulkImportLoader, setBulkImportLoader] = useState(false);
  const [editTenantId, setEditTenantId] = useState(0);
  const [teamView, setTeamView] = useState(showTeams);
  const [spaceSelectionOptions, setSpaceSelectionOptions] = useState([]);
  const loginUserRole = localStorage.getItem("contact_role") ? localStorage.getItem("contact_role") : 0;
  const userType = localStorage.getItem("user_type");
  const [providerCompanyData, setProviderCompanyData] = useState({});
  const [adminRoles, setAdminRoles] = useState([]);
  const [numberFormate, setNumberFormate] = useState('');
  const [numberlength, setNumberLength]= useState({max : 14, min : 9});

  const { virtualSpaceList} = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const recordId = searchParams.get("edit");
  const role = searchParams.get("role");

  /**For Inviting the provider... */
  const invitee = searchParams.get("invitee");
  const inviteeRefer = searchParams.get("provider");
  const category = searchParams.get("category");
  let pathname = window.location.pathname
 

  const managerSelectionList =
    tenantsData && tenantsData.tenantsList.length
      ? tenantsData.tenantsList.filter((w) => { 
            if ( userType == "contact" || userType == "operator") {
              return (w.role_detail && w.role_detail.role_id == 1 || w.role_detail.role_id == 2);
            } 
            return tenantsData.tenantsList;
          })
          .map((w) => {
            return { 
              label: (
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                      <div
                         
                         
                         
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                        style={{
                          backgroundImage: `url('${w.profile_image}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus20">
                    <div className="c-font f-14 color-white-80 text-truncate">
                      {w.full_name}
                    </div>
                  </div>
                   
                </div>
              ),
              value: w.staff_id ,
              name : w.full_name
            };
          })
          .filter((w) => !tenantsData.team_members.includes(w.value))
      : [];

  const handleCsvImport = async () => {
    setBulkImportLoader(true);
    setBulkUploads(false);
    setCsvFile("");

    try {
      if (processedCSV) {
        const rows = processedCSV.split("\n");

        const spreadsheetData = rows.map((row) => {
          const columns = row.split(",");
          return columns.map((column) => column.replace(/(^"|"$)/g, ""));
        });

        let tenantsArray = [];

        spreadsheetData.forEach((row, rowIndex) => {
          if (rowIndex != 0) {
            let email = row[0] && validateEmail(row[0]) ? row[0] : "";
            let name =
              row[1] && row[1].trim().length
                ? row[1].replace(/[^a-zA-Z\s]/g, "").substring(0, 50)
                : "";
            let position = row[2] && row[2].trim();
            let countryCode =
              row[3] && row[3].trim().length
                ? row[3]
                    .trim()
                    .replace(/[- .a-zA-Z]/g, "")
                    .substring(0, 3)
                : "";
            let phonenumber =
              row[3] && row[3].trim().length
                ? row[3]
                    .trim()
                    .replace(/[- .a-zA-Z]/g, "")
                    .substring(3)
                : "";

            let firstname = name.split(" ")[0];
            let lastname = name.split(" ").slice(1).join(" ");

            if (
              email &&
              name &&
              (firstname || lastname) &&
              phonenumber &&
              countryCode
            ) {
              tenantsArray.push({
                email,
                name,
                phone_number: `${countryCode}${phonenumber}`,
                firstname,
                lastname,
                position,
                assigned_project_ids: [projectId],
              });
            }
          }
        });

        if (!tenantsArray.length) {
          showError("l_failed_to_process_file");
          throw "Incorrect file format";
        }

        setBulkImportLoader(true);

        const response = await CustomerServices.addTenants(tenantsArray, [
          projectId,
        ], providerCompanyData.provider_id);
        if (response.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));

          let updatedTenantList = [];

          if(userType === "staff")
          {
            response.data.forEach((contact) => {
              updatedTenantList.push({...contact, id: contact.staffid, staff_id: contact.staffid, role: contact.contact_role });
            });
          }
          else {
            updatedTenantList = response.data;
          }

          setTenantsData({
            ...tenantsData,
            ...resetInputFields(),
            tenantsList: tenantsData.tenantsList.concat(updatedTenantList),
          });

          if (location && location.state && location.state) {
            navigate(location.state.from ? location.state.from : "/",{ state: { from: "add-contact" } });
          }
        }else{
          showError(response.message);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setBulkImportLoader(false);
      setBulkUploads(false);
    }
  };

  const inputChange = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    switch (field) {
      case "firstname":
      case "position":
      case "full_name":
      case "lastname":
      case "companyName":
        value = value.substring(0, 30);
        break;
      case "phonenumber":
        value = value.replace(/\D/g, "").substring(0, 10);
        break;
      default:
        break;
    }
    setTenantsData({ ...tenantsData, [field]: value });
  };
  const resetInputFields = () => {
    return {
      firstname: "",
      lastname: "",
      email: "",
      full_name: "",
      phonenumber: "33",
      countryCode: "+33",
      role: 0,
      assigned_project_ids: [projectId],
    };
  };

  const validateTenantsInput = () => {
    let validInputs = true;
    if (!tenantsData.email || !validateEmail(tenantsData.email)) {
      showError("l_please_enter_valid_email");
      validInputs = false;
    } else if (providerCompanyData.userid <= 0 && (!tenantsData.role || tenantsData.role <= 0)) {
      showError("l_plaese_select_role");
      validInputs = false;
    } else if (
      !tenantsData.assigned_project_ids.length &&
      tenantsData.role != 1
    ) {
      showError("l_please_select_space");
      validInputs = false;
    }
    else if (
      !tenantsData.phonenumber || !tenantsData.phonenumber.length || (numberlength.max != tenantsData.phonenumber.length && numberlength.min != tenantsData.phonenumber.length)
    ) {
      showError("l_please_enter_valid_mobile_number");
      validInputs = false;
    }
    return validInputs;
  };

  const addTenant = async () => {
    if (!validateTenantsInput()) {
      throw "Invalid Inputs";
    }
    try {
      setInviteSendLoader(true);
      let phoneNumbers = tenantsData.countryCode + " " + (tenantsData.phonenumber).slice((tenantsData.countryCode).length - 1)
      const formattedInput = {
        ...tenantsData,
        phone_number: phoneNumbers,
        role : role == "lease-contact" ? 5 : tenantsData.role 
      };
      delete formattedInput.tenantsList;
      const response = await CustomerServices.addTenants(
        [formattedInput],
        tenantsData.assigned_project_ids,
        providerCompanyData.provider_id
      );
      if (response.status) {
        dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));
        if (location && location.state && location.state) {
          navigate(location.state.from ? `${location.state.from}?tab=manage-user` : "/",{ state: { from: "add-contact" } });
        }
        
        let updatedTenantList = [];

        if(userType === "staff")
        {
          response.data.forEach((contact) => {
            updatedTenantList.push({...contact, id: contact.staffid, staff_id: contact.staffid, role: contact.contact_role });
          });
        }
        else {
          updatedTenantList = response.data;
        }

        setTenantsData({
          ...tenantsData,
          ...resetInputFields(),
          tenantsList: tenantsData.tenantsList.concat(updatedTenantList),
        });

      }else{
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setInviteSendLoader(false);
    }
  };

  const deleteEmployee = (id) => {
    dispatch(
      showConfirmation(
        _l("l_delete"),
        _l("l_delete_tenant_confirmation"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          dispatch(toastCloseAction());
          try {
            const response = await CustomerServices.deleteCompanyProfileUser(
              projectId,
              id
            );
            if (response.status) {
              setTenantsData({
                ...tenantsData,
                tenantsList: tenantsData.tenantsList.filter((w) => w.id != id),
              });
            }
          } catch (e) {
            console.error(e);
          }
        }
      )
    );
  };

  const validTeamData = () => {
    if (!tenantsData.team_name.trim().length) {
      showError("l_please_enter_team_name");
      return false;
    } else if (tenantsData.manager <= 0) {
      showError("l_please_select_team_manager");
      return false;
    } else if (!tenantsData.team_members.length) {
      showError("l_please_add_members_to_team");
      return false;
    } else if (
      tenantsData.team_project_id == 0 ||
      tenantsData.team_project_id == null ||
      tenantsData.team_project_id == undefined
    ) {
      showError("l_please_select_space");
      return false;
    }
    return true;
  };

  const createEditTeam = async () => {
    if (validTeamData()) {
      setInviteSendLoader(true);
      try {
        const response = await CustomerServices.createTeam(
          tenantsData.team_members,
          tenantsData.manager,
          tenantsData.team_name.trim(),
          tenantsData.team_project_id,
          tenantsData.teamId
        );
        if (response.status) {
          setTenantsData({
            ...tenantsData,
            team_name: "",
            team_members: [],
            team_project_id: 0,
            teamId: 0,
            manager: 0,
          });
          await getTeamList();
          
          dispatch(
            showMessage("sucess", _l("l_success"), _l(response.message))
            );
          dispatch(getDefaultTopics(projectId)).then(() => {
            navigate(`/explore-features?space=${tenantsData.team_project_id}&setting-menu=people&section=people-list`);
          });
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setInviteSendLoader(false);
      }
    }
  };

  const optionListForTeamMemberSelection = () => {
    let optionsList = [];

    if (tenantsData && tenantsData.tenantsList.length) {
      tenantsData.tenantsList.filter((w) => { 
          if ( userType == "contact" || userType == "operator") {
            return (w.role_detail && w.role_detail.role_id == 1 || w.role_detail.role_id == 2);
          } 
          return tenantsData.tenantsList;
        })
        .forEach((w) => {
        if (w.staff_id == tenantsData.manager) {
          return;
        }
        const employee = {
          value: w.staff_id,
          label: (
            <div className="d-flex align-items-center">
              <div className="c-list-icon">
                <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                  <div
                     
                     
                     
                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                    style={{
                      backgroundImage: `url('${w.profile_image}')`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="ps-2 w100minus20">
                <div className="c-font f-14 color-white-80 text-truncate">
                  {w.full_name}
                </div>
              </div>
               
            </div>
          ),
          name: w.full_name,
          url: w.profile_image,
        };
        optionsList.push(employee);
      });
    }
    return optionsList;
  };

  const selectTeamMember = (e) => {
    setTenantsData({ ...tenantsData, team_members: e.map((w) => w.value) });
  };

  const updateTenantData = (data) => {
    const index = tenantsData.tenantsList.findIndex((w) => w.id);
    if (index > -1) {
      setTenantsData({
        ...tenantsData,
        tenantsList: Object.values({
          ...tenantsData.tenantsList,
          [index]: {
            ...tenantsData.tenantsList[index],
            email: data.email,
            assigned_project_ids: data.projects,
            role_detail: { role_id: data.role_id, role_name: data.role },
          },
        }),
      });
    }
  };

  const getSelectedRole = () => {
    if(userType === "staff")
    {
      if(tenantsData.role > 0)
      {
        return adminRoles.find(
          (w) => w.value == tenantsData.role
        )
      }
      else {
        return [];
      }
    }
    else {
      if(tenantsData.role > 0)
      {
        return CONTACT_ROLE_LISTING.find(
          (w) => w.value == tenantsData.role
        )
      }
      else {
        return [];
      }
    }
  };

  useEffect(() => {
    
    const getAdminRoles = async() => {
      try {
        const response = await CustomerServices.getRoles();
        if(response.status)
        {
          setAdminRoles(response.data.map((w) => {return {label: w.name, value: w.roleid}}));
        }
      }
      catch(e)
      {
        console.error(e);
      }
    };
    
    if (spaces.length || localStorage.getItem("spaces")) {
      let space = spaces.length ? spaces : localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [] 
      let virtualSpaces = []
      space = spaces.map((space) => {
          return { value: space.project_id, label: space.office_title != "" ? space.office_title : space.postal_address };
        })
      if (
        (recordId || isNaN(recordId) || !Number(recordId)) &&
        projectId &&
        projectId > 0
      ) {
        if (virtualspaces) {
          setTenantsData({
            ...tenantsData,
            assigned_project_ids: [
              virtualspaces && virtualSpaceList && virtualSpaceList.find((w) => w.project_id == projectId).project_id 
            ],
          });
          virtualSpaces = virtualSpaceList.map((spaces)=>{
            return { value : spaces.id , label : spaces.name}
          })
        } else {
          setTenantsData({
            ...tenantsData,
            assigned_project_ids: [
              spaces.find((w) => w.project_id == projectId).project_id,
            ],
          });
        }
      }
      setSpaceSelectionOptions(virtualSpaces.concat(space))
    }

    if(invitee && invitee.trim().length && !isNaN(inviteeRefer) && Number(inviteeRefer))
    {
      const getProviderEmployees = async(id) => {
        let employees = [];
        try {
          const response = await CustomerServices.getCompanyEmployee(0, 0, projectId, id);
          if(response.status)
          {
            employees = response.data.map((provider) => {
              return {
                ...provider
              }
            });
          }
        }
        catch(e)
        {
          console.error(e);
        }
        finally {
          return employees;
        }
      };

      const getProviderList = async() => {
        try {
          const response = await BenchmarkServices.getCategoryProviderList(
            projectId,
            [category]
          );
          if(response.status)
          {
            const currentProvider = response.data.find((w) => w.userid == inviteeRefer);
            const provider_id = currentProvider.employees.find((w) => w.boss_operator_id == 0).staffid;
            let employees = await getProviderEmployees(provider_id);
            setProviderCompanyData({...currentProvider, provider_id});
            setTenantsData({...tenantsData, tenantsList: employees});
            setHeaderText("l_add_provider");
            setTitlePrefix(`- ${currentProvider.company}`);
          }
          else {
            showError(response.message);
          }
        }
        catch (e) {
          showError("l_something_went_wrong");
          console.error(e);
        }
      };

      getProviderList();
    }

    if(userType === "staff")
    {
      getAdminRoles();
    }
    
  }, []);

  useEffect(() => {
    if (
      teamList &&
      tenantsData.tenantsList &&
      tenantsData.tenantsList.length &&
      teamList.length
    ) {
      if (recordId) {
        const teamForEdit = teamList.find((w) => w.id == recordId);
        if (teamForEdit) {
          setTenantsData({
            ...tenantsData,
            assigned_project_ids: [
              virtualspaces && virtualspaces ? virtualSpaceList.find((w) => w.project_id == projectId).project_id : spaces.find((w) => w.project_id == projectId).project_id,
            ],
            teamId: teamForEdit.id,
            team_name: teamForEdit.name,
            manager: teamForEdit.manager_id,
            team_members: teamForEdit.team.map((w) => w.member_id),
          });
        }
      }
    }
  }, [teamList,recordId,tenantsData.tenantsList]);

  const selectCustomFilter = (option, searchText) => {
    // select custom filter for custom ohtml render put search field as extra property in object of select
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase())  ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (numberFormate.dialCode) {
        setTenantsData({...tenantsData, countryCode : `+${numberFormate.dialCode}`})
        const dialcodeL = Number(numberFormate.dialCode.length);
        let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
        if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
            if (["49", "44"].includes(numberFormate.dialCode)) {
                setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
            }else{
                setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
            }
        }else{
            setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
        }
    }
  }, [numberFormate])

  return (
    <Row className="flex-grow-1 justify-content-center h-100">
      {teamView ? (
        <React.Fragment>
          <Col
            className="overflow-hiiden-auto h-100 padding-bottom-60px"
            xs={4}
          >
            <Row>
              <Form.Label className="c-font f-14 title-fonts mb-3">
              {tenantsData.teamId > 0
                    ? _l("l_edit_team")
                    : _l("l_create_team")}
              </Form.Label>
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative z-index-4">
                <Form.Label className="input-label">
                  {_l("l_team_name")}
                </Form.Label>
                <input
                  className="form-control p-15"
                  placeholder="Internal"
                  type="text"
                  name="team_name"
                  value={tenantsData.team_name}
                  onChange={(e) =>
                    setTenantsData({
                      ...tenantsData,
                      team_name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative pb-3 z-index-4">
                <Form.Label className="input-label">
                  {_l("l_manager")}
                </Form.Label>
                <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                  <Select
                    className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                    classNamePrefix="react-select"
                    placeholder={`${_l("l_manager")}`}
                    options={managerSelectionList}
                    value={
                      tenantsData.manager == 0
                        ? []
                        : managerSelectionList.find(
                            (w) => w.value == tenantsData.manager
                          )
                    }
                    onChange={(e) =>
                      setTenantsData({ ...tenantsData, manager: e.value })
                    }
                    filterOption={selectCustomFilter}
                    hideSelectedOptions
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative pb-3 z-index-3">
                <Form.Label className="input-label">
                  {_l("l_employees")}
                </Form.Label>
                <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                  <Select
                    className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                    classNamePrefix="react-select"
                    placeholder={`${_l("l_employees")}`}
                    options={optionListForTeamMemberSelection()}
                    isMulti
                    closeMenuOnSelect={false}
                    value={optionListForTeamMemberSelection().filter((w) =>
                      tenantsData.team_members.includes(w.value)
                    )}
                    filterOption={selectCustomFilter}
                    onChange={selectTeamMember}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative pb-3">
                <Form.Label className="input-label">{_l("l_space")}</Form.Label>
                <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                  <Select
                    className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh selece-arror-sticky"
                    classNamePrefix="react-select"
                    placeholder={_l("l_spaces")}
                    options={spaceSelectionOptions}
                    value={spaceSelectionOptions.filter(
                      (w) => tenantsData.team_project_id == w.value
                    )}
                    onChange={(e) =>
                      setTenantsData({
                        ...tenantsData,
                        team_project_id: e.value,
                      })
                    }
                    hideSelectedOptions
                  />
                </div>
              </Form.Group>
              <div className="d-flex align-items-center pt-3">
                <Button
                  className="flex-grow-1 w-100 border-0"
                  disabled={inviteSendLoader}
                  variant="primary"
                  onClick={createEditTeam}
                >
                  {tenantsData.teamId > 0
                    ? _l("l_edit_team")
                    : _l("l_create_team")}
                  {inviteSendLoader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 `}
                      aria-hidden="true"
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Button>
              </div>
              <div className="d-flex align-items-center pt-3">
                {tenantsData.teamId > 0 ? (
                  <Button
                    className="flex-grow-1 w-100 border-0"
                    disabled={inviteSendLoader}
                    variant="white-05"
                    onClick={() => {
                      setTenantsData({
                        ...tenantsData,
                        manager: 0,
                        team_name: "",
                        teamId: 0,
                        team_members: [],
                      });
                    }}
                  >
                    {_l("l_cancel")}
                  </Button>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            </Row>
          </Col>
          <Col className="d-flex flex-column flex-grow-1 h-100" xs={8}>
            <Form.Label className="c-font f-14 title-fonts mb-3">
              {tenantsData.team_name || ""}
            </Form.Label>
            <div className="border-dashed-2px radius_3 flex-grow-1 overflow-auto  padding-bottom-60px">
              <div className="px-2 dealing-list h-100 ">
                {tenantsData.manager != 0 ? (
                  <React.Fragment>
                    <div className="base-body-bg c-font color-white-60 comman-white-box f-12 position-sticky py-2 ps-1 title-fonts top-0 z-index-2 bg-white-03-solid-imp">
                      {_l("l_manager")}
                    </div>
                    <a href="#/"
                      className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active color-white-60 `}
                    >
                      <div
                        className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                      >
                        <div className="d-flex align-items-center">
                          <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{
                                backgroundImage: `url("${
                                  tenantsData.tenantsList.find(
                                    (w) => w.staff_id == tenantsData.manager
                                  ).profile_image
                                }")`,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="c-font  f-14 ps-2 text-truncate title-fonts">
                          {
                            tenantsData.tenantsList.find(
                              (w) => w.staff_id == tenantsData.manager
                            ).full_name
                          }
                        </div>
                      </div>
                      <div className="badge badge-red rounded-pill fw-light ">
                        <span className={`p-0`}>
                          {
                            tenantsData.tenantsList.find(
                              (w) => w.staff_id == tenantsData.manager
                            ).role_detail.role_name
                          }
                        </span>
                      </div>
                      <div className="c-font f-14 text-truncate d-flex align-items-center">
                        <a href="#/"
                          className="with_separator_10"
                          onClick={() => {
                            setTenantsData({
                              ...tenantsData,
                              manager: 0,
                            });
                          }}
                        >
                          <TrashIcon className="HW16" />
                        </a>
                      </div>
                    </a>
                  </React.Fragment>
                ) : (
                  <></>
                )}
                {tenantsData.tenantsList.filter((w) =>
                  tenantsData.team_members.includes(w.staff_id)
                ).length ? (
                  <React.Fragment>
                    <div className="base-body-bg c-font color-white-60 comman-white-box f-12 position-sticky py-2 ps-1 title-fonts top-0 z-index-2 bg-white-03-solid-imp">
                      {_l("l_members")}
                    </div>
                    {tenantsData.tenantsList.map((tenant, index) => {
                      if (
                        tenant.staff_id == tenantsData.manager ||
                        !tenantsData.team_members.includes(tenant.staff_id)
                      ) {
                        return <></>;
                      }
                      return (
                        <a href="#/"
                          key={index}
                          className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active color-white-60 `}
                        >
                          <div
                            className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                          >
                            <div className="d-flex align-items-center">
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url("${tenant.profile_image}")`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-font  f-14 ps-2 text-truncate title-fonts">
                              {tenant.full_name}
                            </div>
                          </div>
                          <div className="badge badge-red rounded-pill fw-light ">
                            <span className={`p-0`}>
                              {tenant.role_detail.role_name}
                            </span>
                          </div>
                          <div className="c-font f-14 text-truncate d-flex align-items-center">
                            <a href="#/"
                              className="with_separator_10"
                              onClick={() => {
                                setTenantsData({
                                  ...tenantsData,
                                  team_members: tenantsData.team_members.filter(
                                    (w) => w != tenant.staff_id
                                  ),
                                });
                              }}
                            >
                              <TrashIcon className="HW16" />
                            </a>
                          </div>
                        </a>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <CommanPlaceholder mainSpacing="h-100" placeholderText={"l_create_team"} />
                )}
              </div>
            </div>
          </Col>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Col
            className="overflow-hiiden-auto h-100 flex-grow-1"
            xs={4}
          >
            <Form.Label className="c-font f-14 title-fonts mb-3">
              {providerCompanyData.userid ? _l("l_add_provider") : (userType === "operator" ? _l("l_add_employee") : _l("l_add_tenants"))}
            </Form.Label>
            {/* Basic Info section start*/}
            <Row>
            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
              <Form.Label className="input-label">{_l("l_email")}</Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_email_placeholder")}
                type="email"
                name="email"
                onChange={inputChange}
                value={tenantsData.email}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative d-none">
              <Form.Label className="input-label">{_l("l_password")}</Form.Label>
              <input
                className="form-control p-15"
                placeholder="***"
                type="password"
                name="password"
                onChange={inputChange}
                value={tenantsData.password}
              />
            </Form.Group>
            
            {
              providerCompanyData.userid > 0 
              ?
              <React.Fragment></React.Fragment>
              :
              <React.Fragment>
                <Form.Group className={`${pathname == "/onboarding-employees" ? "" : "col-xl-6 "} z-index-3 c-input-box position-relative pb-3 ${role == "lease-contact" ? "for-disabled" : ""}`}>
                  <Form.Label className="input-label">{_l("l_role")}</Form.Label>
                  <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                    <Select
                      className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                      classNamePrefix="react-select"
                      placeholder={`${_l("l_select_role")}`}
                      hideSelectedOptions
                      options={userType === "staff" ? adminRoles : CONTACT_ROLE_LISTING}
                      value={getSelectedRole()}
                      onChange={(e) => {
                        if (e.value == 1 && userType !== "staff") {
                          setTenantsData({
                            ...tenantsData,
                            role: e.value,
                            assigned_project_ids: spaces.map((w) => w.project_id),
                          });
                        } else {
                          setTenantsData({
                            ...tenantsData,
                            role: e.value,
                            assigned_project_ids: [projectId],
                          });
                        }
                      }}
                      disabled={role == "lease-contact"}
                    />
                  </div>
                </Form.Group>
                {window.location.pathname == "/onboarding-employees" ? <></> :<Form.Group className="col-xl-6 c-input-box position-relative pb-3">
               <Form.Label className="input-label">{_l("l_space")}</Form.Label>
                  <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                    <Select
                      className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh selece-arror-sticky"
                      classNamePrefix="react-select"
                      placeholder={`${
                        Number(tenantsData.role) == 1
                          ? _l("all_project_access")
                          : _l("l_select_space_selection")
                      }`}
                      options={spaceSelectionOptions}
                      value={
                        tenantsData.role == 1
                          ? []
                          : spaceSelectionOptions.filter((w) =>
                              tenantsData.assigned_project_ids.includes(w.value)
                            )
                      }
                      isDisabled={tenantsData.role == 1}
                      onChange={(e) =>
                        setTenantsData({
                          ...tenantsData,
                          assigned_project_ids: e.map((w) => w.value),
                        })
                      }
                      isMulti
                      hideSelectedOptions
                    />
                  </div>
                </Form.Group>}
              </React.Fragment>
            }

            {/* Basic info section end*/}

            {
              providerCompanyData.userid > 0 
              ?
              <React.Fragment>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_full_name")}
                  </Form.Label>
                  <input
                    className="form-control p-15"
                    placeholder="John"
                    type="text"
                    name="full_name"
                    onChange={inputChange}
                    value={tenantsData.full_name}
                  />
                </Form.Group>
              </React.Fragment>
              :
              <React.Fragment>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_firstname")}
                  </Form.Label>
                  <input
                    className="form-control p-15"
                    placeholder="John"
                    type="text"
                    name="firstname"
                    onChange={inputChange}
                    value={tenantsData.firstname}
                  />
                </Form.Group>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_last_name")}
                  </Form.Label>
                  <input
                    className="form-control p-15"
                    placeholder="John"
                    type="text"
                    name="lastname"
                    onChange={inputChange}
                    value={tenantsData.lastname}
                  />
                </Form.Group>
              </React.Fragment>
            }
                  
            <Form.Group className={`c-input-box position-relative select-half-separator pb-3 col-xl-${tenantsData && tenantsData.tenantsList.length ? "12":"6"}`}>
              <Form.Label className="input-label no-asterisk no-asterisk">
                {_l("l_mobile_number")}
              </Form.Label>
              <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                <PhoneInput
                    inputClass="title-fonts f-12"
                    autoFormat={true}
                    containerClass="f-32"
                    disableCountryGuess={true}
                    countryCodeEditable={false}
                    disableSearchIcon={true}
                    enableSearch={true}
                    enableAreaCodes={true}
                    enableLongNumbers = {true}
                    localization={""}
                    country='fr'
                    preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                    value={tenantsData.phonenumber}
                    placeholder="780 1250 456"
                    isValid={(value, country)=>{
                        if (value.length > numberlength.max) {
                          setTenantsData({ ...tenantsData, phonenumber: value.slice(0,numberlength.max) });
                        } else {
                        return true;
                        }
                    }}
                    onChange={(phone, formattedValue) => {
                      setTenantsData({ ...tenantsData, phonenumber: phone });
                      setNumberFormate(formattedValue);
                    }}
                    inputProps={{
                        autoFocus: true
                      }}
                    />
                      </div>
            </Form.Group>
            <Form.Group className={`c-input-box position-relative pb-3 col-xl-${tenantsData && tenantsData.tenantsList.length ? "12":"6"}`}>
              <Form.Label className="input-label no-asterisk">{_l('l_position')}</Form.Label>
              <input
                className="form-control p-15"
                placeholder={`${_l("l_position_placeholder")}`}
                type="email"
                name="position"
                onChange={inputChange}
                value={tenantsData.position}
              />
            </Form.Group>
            </Row>
            <div className={`${tenantsData && tenantsData.tenantsList.length ? "d-grid gap-2 btn-inner-w-100":" justify-content-between"} d-flex flex-wrap align-items-center pt-3`}>
              <Button
                className="border-0"
                disabled={inviteSendLoader}
                variant="primary"
                onClick={addTenant}
              >
                {providerCompanyData.userid ? _l("l_invite_provider") : (userType === "operator" ? _l("l_add_employee") : _l("l_add_tenant"))}
                {inviteSendLoader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Button>
            
            {editTenantId > 0 ? (
              
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEditTenantId(0);
                    setTenantsData({ ...tenantsData, ...resetInputFields() });
                  }}
                >
                  {_l("cancel")}
                </Button>
              
            ) : (
              
                <Button
                  disabled={bulkImportLoader}
                  variant="secondary"
                  onClick={() => {
                    setBulkUploads(true);
                  }}
                >
                  {_l("l_bulk_upload")}
                  {bulkImportLoader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 `}
                      aria-hidden="true"
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Button>
              
            )}
            </div>
          </Col>
          {((tenantsData && tenantsData.tenantsList.length) || (!isNaN(inviteeRefer) && Number(inviteeRefer) && tenantsData && tenantsData.tenantsList.length) || pathname == "/onboarding-employees") ? (
            <Col className={`d-flex flex-column ${pathname == "/onboarding-employees" ? "" : leftPanelTab === "my-tenants" ? 'd-none' : "flex-grow-1 h-100"}`} xs={8}>
              <Form.Label className="c-font f-14 title-fonts mb-3">
                {providerCompanyData.userid ? _l("l_providers") : (userType === "operator" ? _l("l_employees") : _l("l_tenants"))}
              </Form.Label>
              <div className="border-dashed-2px radius_3 flex-grow-1 overflow-auto">
                <div className="px-2 dealing-list">
                  
                  {tenantsData.tenantsList.map((tenant, index) => {
                    return (
                      <a href="#/"
                        key={index}
                        className={`row align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active color-white-60 g-0 `}
                      >
                          <div className="col-3 d-flex align-items-center ps-0">
                            <div className="d-flex align-items-center">
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url("${tenant.profile_image}")`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-font  f-14 ps-2 text-truncate title-fonts">
                              {tenant.full_name}
                            </div>
                          </div>  
                          <div className="col-2 c-font  f-14 ps-2 text-truncate title-fonts">
                            {tenant.email}
                          </div>
                          <div className="col-2 c-font  f-14 ps-2 text-truncate title-fonts">
                            {tenant.phone_number}
                          </div>
                          <div className="col-5 d-flex align-items-center justify-content-end pe-0">
                            {
                              tenant && tenant.role_detail
                              ?
                              <div className="badge badge-white rounded-pill fw-light mx-2 m-w80px text-truncate">
                                <span className={`p-0`}>{tenant && tenant.role_detail ? tenant.role_detail.role_id == 5 ?  _l("l_resident") : tenant.role_detail.role_name : ""}</span>
                              </div>
                              :
                              <React.Fragment></React.Fragment>
                            }
                          <div className="badge badge-white rounded-pill fw-light mx-2 m-w80px text-truncate">
                            <span className={`p-0`}>
                              {tenant.is_psw_set == 1
                                ? _l("l_registered")
                                : _l("l_not_registered")}
                            </span>
                          </div>
                            <div className="c-font f-14 text-truncate d-flex align-items-center">
                              {providerCompanyData.userid ? (
                                <React.Fragment></React.Fragment>
                              ) : (
                                <a href="#/"
                                  className="with_separator_10"
                                  onClick={() => {
                                    if (
                                      tenant.staff_id &&
                                      tenant.staff_id != null &&
                                      tenant.staff_id > 0 &&
                                      loginUserRole <= tenant.role_detail.role_id &&
                                      userType != "staff"
                                    ) {
                                      navigate(
                                        `/myprofile?user=${tenant.staff_id}`
                                      );
                                    } else {
                                      showError(_l("l_staff_id_missing_for_profile_cant_edit_this_user"));
                                    }
                                  }}
                                >
                                  <PencilIcon className="HW16" />
                                </a>
                              )}
                              <a href="#/"
                                className={`with_separator_10 ${providerCompanyData.userid > 0 && tenant.customer_invited == 0 ? "for-disabled opacity-50" : ""}`}
                                onClick={() => {
                                  if(providerCompanyData.userid > 0 && tenant.customer_invited == 0)
                                  {
                                    return;
                                  }
                                  deleteEmployee(tenant.id);
                                }}
                              >
                                <TrashIcon className="HW16" />
                              </a>
                              {
                                tenant.password_set_link && tenant.password_set_link.length > 0
                                ?
                                  <a href="#/"
                                    className="with_separator_10"
                                    onClick={() => copyToClipboard(tenant.password_set_link)}
                                  >
                                    <CopyIcon className="HW16" />
                                  </a>
                                :
                                <React.Fragment></React.Fragment>
                              }
                            </div>
                          </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </Col>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}

      {editTenantId > 0 ? (
        <AddEmployee
          handleClose={() => {
            setEditTenantId(0);
          }}
          projectId={projectId}
          selectedRecord={selectedTenantData}
          show={editTenantId > 0}
          updateDataSource={updateTenantData}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {bulkUploads ? (
        <ImportProviders
          handleClose={() => {
            setBulkUploads(false);
          }}
          handleImport={handleCsvImport}
          importFile={csvFile}
          setImportFile={(e) =>
            csvOnLoadHandler(e, setCsvFile, setProcessedCSV)
          }
          show={bulkUploads}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Row>
  );
};

export default CreateTenant;
