import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { _l, accessPremiumFeature } from "../hooks/utilities";
import {
  getSelectedTask,
  getBenchmarkWeekSchedule, getBenchmarkDetail, setCreateTaskModalIsopen, commanoffcanvasisOpen,
  isCreareTaskDefaultTopics,
  setLeftPanelTab,
  setActionForLeaseDocument
} from "../actions/customer";
import jquery from "jquery";
import AddScheduleIcon from "../assets/icons/AddScheduleIcon";
import AddTaskIcon from "../assets/icons/AddTaskIcon";
import CreateBenchmarkIcon from "../assets/icons/CreateBenchmarkIcon";
import SelectProviderCategory from "./Modals/SelectProviderCategory";
import CreateTaskOffcanvas from "./Offcanvas/CreateTaskOffcanvas";
import CreateTaskRequestOffcanvas from "./Offcanvas/CreateTaskRequestOffcanvas";
import CreateVirtualSpace from "./Modals/CreateVirtualSpace";
import AddLeadModal from "./Modals/AddLeadModal";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import NewSchedule from "./Offcanvas/NewSchedule/NewSchedule";
import { createScheduleDetails, updateTypeOfSpaceList } from "../actions/benchmark-actions/benchmark-actions";
import AddNewDocument from "./Modals/AddNewDocument";
import AddNewClient from "./Modals/AddNewClient";
import { CUSTOM_ROLES } from "../constants/constants";
import AddEstimateModal from "./Modals/AddEstimateModal";
import DocumentModal from "./Modals/DocumentModal";
import PencilSimpleLineIcon from "../assets/icons/PencilSimpleLineIcon";
import AddEmailTemplatesModalNew from "./Modals/AddEmailTemplatesModalNew";
import UploadDocument from "./Modals/UploadDocument";
import customerServices from "../services/customer-services";
import SpaceSelectionModal from "./Modals/SpaceSelectionModal";
import AddInvoiceModal from "./Modals/AddInvoiceModal";
import AddCreditNote from "./Modals/AddCreditNote";
import CreateEstimateicon from "../assets/images/createestimate.svg";
import CreateSubscriptioneicon from "../assets/images/createsubscription.svg";
import CreateInvoiceicon from "../assets/images/createinvoice.svg";
import CreateCreditNoteicon from "../assets/images/createcreditnote.svg";
import EnvelopeIcon from "../assets/icons/EnvelopeIcon";
import PlusIcon from "../assets/icons/PlusIcon";
import FilesIcon from "../assets/icons/FilesIcon";
import GlobeIcon from "../assets/icons/GlobeIcon";
import UserGearIcon from "../assets/icons/UserGearIcon";
import UsersThreeIcon from "../assets/icons/UsersThreeIcon";
import WrenchIcon from "../assets/icons/WrenchIcon";
import ChatIcon from "../assets/icons/ChatIcon";
import FileTextIcon from "../assets/icons/FileTextIcon";
const CommanFooterPlus = ({ projectId, pageName, id = "", toggleId = "", docType = "",bottomMenuClass="",MainClass="" }) => {
  const { defaultTopics, benchmarkDetail, globalView, commanoffcanvasIsOpen, leftPanelTab} = useSelector((state) => state.customer);
  const { callStatus} = useSelector((state) => state.chat);
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const userType = localStorage.getItem("user_type");
  const project_id = useSelector((state) => state.customer.selectedProject);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [estimateModal, setShowEstimateModal] = useState(false);
  const [showCreateScheduleForCustomer, setCreateScheduleForCustomer] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [categoryListing, setCategoryListing] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [showVirtualSpace, setShowVirtualSpace] = useState(false);
  const [selectedVirtualSpace, setSelectedVirtualSpace] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [NewScheduleFooter, setNewScheduleFooter] = useState(false);
  const [isBenchmark, setIsBenchmark] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [docModalData, setDocModalData] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const isLeadPage = pageName == 'leadPage' ? 1 : 0
  const handleBenchmark = () => setShowBenchmarkModal(!showBenchmarkModal);
  const location = useLocation();
  const handleSchedules = (is_benchmark = false) => {
    if (!NewScheduleFooter && benchmarkDetail) {
      dispatch(
        getBenchmarkDetail(benchmarkDetail.id, localStorage.getItem("client_id"), "all")
      );
      dispatch(createScheduleDetails({}));
    }
    setNewScheduleFooter(!NewScheduleFooter);
    setIsBenchmark(is_benchmark);
  }
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [task_type, setTask_type] = useState('');
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [openCreatClientModal, setOpenCreatClientModal] = useState(false);
  const [bulkEmailSendShow, setBulkEmailSendShow] = useState(false);
  const [emailSyncStatus, setEmailSyncStatus] = useState(false);
  const [showSpaceModal, setShowSpaceModal] = useState({ show : false, actionType : ""});
  const [showMenu, setShowMenu] = useState(false)
  const [createSubscriptionModal, setCreateSubscriptionModal] = useState(false);
  const contact_role = localStorage.getItem("contact_role");
  const [showInvoiceModel, setShowInvoiceModel] = useState(false);
  const [showCreditNote, setShowCreditNote] = useState(false);
  const DrivePageTab = ["/my-drive"];
  let noPluseButtonPages = [
    "/dashboardpre",
    "/premiumsubscription",
    "/companyprofile",
    "/myprofile",
    "/chat",
    "/proposals",
    "/create-benchmark",
    "/liteversion",
    "/dashboard"
  ];
  useEffect(() => {
    if (defaultTopics && defaultTopics.length > 0) {
      var main_key = userType == "contact" ? "provider_task" : "proposals";
      defaultTopics.map((item) => {
        if (item.main_key == main_key) {
          setCategoryListing(
            item.categories.map((item1) => {
              return {
                value: parseInt(item1.filter_key),
                label: item1.title,
                is_default: item1.is_default,
              };
            })
          );
        }
      });
    }
  }, [defaultTopics]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (leftPanelTab == "homepage") {
      const checkEmailSync = async () => {
        try {
          const response = await customerServices.getMailSettings();
          setEmailSyncStatus(response.status)
        } catch (e) {
          console.error(e);
        }
      };
      checkEmailSync()
    }
  }, [])
  useEffect(() => {
    setSelectedVirtualSpace(false)
    if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
      let selectedVirutalSpace = JSON.parse(localStorage.getItem('selectedVirtualSpace'))
      if (Object.keys(selectedVirutalSpace).length) {
        setSelectedVirtualSpace(true)
      }
    }
  }, [localStorage.getItem('selectedVirtualSpace')])
  useEffect(() => {
    if (selectedTask && Object.keys(selectedTask).length) {
      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      if (pageName == "Dashboard" && showEditTaskModal) {
        jquery(".task-list-accordian").addClass("list-view-toggle");
      }
      let task_id = selectedTask.is_requested && selectedTask.is_requested == 1 ? selectedTask.task_id : selectedTask.id
      setTimeout(() => {
        if (selectedTask && pageName == "Dashboard" && showEditTaskModal) {
          if (typeof task_id == 'string') {
            jquery("#task_" + task_id.replace(new RegExp("=", "g"), '')).addClass("active");
          } else {
            jquery("#task_" + task_id).addClass("active");
          }
        }
      }, 100)
    } else if (task_type == 'task' || task_type == 'request-task') {
      jquery(".task-list-accordian").removeClass("list-view-toggle");
      setShowEditTaskModal(false);
      setCommonModalData();
      setSelectedTaskId(0);
      setTask_type('')
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
      });
    });
    return () => {
      jquery(document).unbind("ready");
    };
  }, [selectedTask]);
  useEffect(() => {
    if (benchmarkDetail) {
      setCommonModalData(benchmarkDetail);
    }
  }, [benchmarkDetail])
  if (isLeadPage) {
    jquery('.link-center-box-toggle').addClass('d-none')
  }
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowEditTaskModal(false);
    setSelectedTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {
      setShowEditTaskModal(show);
    }
    jquery('#task_' + taskId).addClass('active')
    jquery('#list_view').addClass('list-view-toggle')
  };
  const scrollToBottom = () => {
    var msgListDiv = document.getElementById("message-list");
    if(msgListDiv){
      msgListDiv.scrollTop = msgListDiv.scrollHeight;
    }
  };
  const isAllowedToCreateMultiSpaceBenchmark = () => {
    let flag = false;
    if(location.pathname == "/dashboard" && userType == 'contact' && ![2, 3].includes(Number(contact_role)))
    {
      flag = true;
    }
    return flag;
  };
  const createBenchmark = () => {
    accessPremiumFeature(project_id, () => {
      if(location.pathname === "/dashboard")
      {
        navigate(`/create-dpgf-benchmark`);
      } else if(leftPanelTab == "homepage" || Number(projectId) <= 0) {
        setShowSpaceModal({ show : true, actionType : "benchmark"})
      } else {
        navigate(`/connect-provider?space=${project_id}&provider-type=benchmark`);
      }
    });
  }
  const handleChangeTab = (tab, navigation) => {
    dispatch(commanoffcanvasisOpen(false));
    dispatch(setLeftPanelTab(tab));
    if (navigation) {
      navigate(navigation);
    }
  }
  const menuRef = useRef(null);
  useEffect(() => {
    const handlerBackground = (e) => {
      if(menuRef.current && !menuRef.current.contains(e.target)) {
        setShowMenu(false)
      }
    }
    document.addEventListener('click', handlerBackground);
    return () => {
      document.removeEventListener('click', handlerBackground);
    }
  }, [])
  return (
    <React.Fragment>
      { ["estimate", "space", "space_subscription", "subscription", "spaceDocument" ,"space_invoice", "invoice", "credit_notes", "documents","gmail"].includes(docType) ||
        ["dashboardnew"].includes(pageName) || (callStatus && callStatus.role == 4)
        ? <></>
      : 
      <div className={`footer-add-btn ms-auto ${MainClass} ${docType == "leaseDocument" ? "bottom120" : "bottom120" }  
       ${(noPluseButtonPages.includes(location.pathname) && !isAllowedToCreateMultiSpaceBenchmark()) || (leftPanelTab != "my-request" && contact_role == 3 && userType != "staff" && (localStorage.getItem("selectedOffice") == 0 || localStorage.getItem("selectedOffice") == undefined)) ? 'd-none' : ''} ${showMenu ? "show-menu-link" : ""} `}
        id={id}>
        { window.location.pathname == "/clients" || globalView != "liteVersionView" || globalView == "liteVersionView" ? 
        <a href="#/"
          className={` btn btn-primary  comman-round-box rounded-5  bg-gradient-green d-flex align-items-center justify-content-center z-index-3 p-10 ${commanoffcanvasIsOpen ? '' : 'w125px'} `} 
           onClick={()=>{
            setShowMenu(!showMenu)
          }}
          ref={menuRef}
        >
          {/* rotate-image remove from below div */}
          <div id="footer-plus-icon" className="comman-bg-img  d-flex">
            <PlusIcon className="HW20 black-l-white mx-0" />
          </div>
          <div className={`ps-10px text-uppercase fw-semibold ${commanoffcanvasIsOpen ? 'd-none' : ''}`}>{_l("l_create")}</div>
        </a> : <></>}
        {showDetails ? (
          <div className={`bottom-link-menu-options ${bottomMenuClass} ${showMenu ? "show-menu-link" : ""} `} id={toggleId}>
            {docType == "leaseDocument" &&  <>
            <a href="#/" className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img "
            onClick={()=>{
              dispatch(setActionForLeaseDocument("edit"))
              setShowMenu(false)
            }}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40 lease-action">
                  {`${_l("l_edit")}`}
                </div>
                <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                  <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                  <PencilSimpleLineIcon className="HW18 " />
                  </div>
                </div>
              </div>
            </a>
            <a href="#/" className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
            onClick={()=>{
              dispatch(setActionForLeaseDocument("comment"))
              setShowMenu(false)
            }}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40 lease-action">
                  {`${_l("l_comment")}`}
                </div>
                <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                  <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                    <ChatIcon className={`HW16`} />
                  </div>
                </div>
              </div>
            </a>
            <a href="#/" className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
            onClick={()=>{
              dispatch(setActionForLeaseDocument("filelog"))
              setShowMenu(false)
            }}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40 lease-action">
                  {`${_l("l_log_added")}`}
                </div>
                <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                  <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                    <FileTextIcon className={`HW18`} />
                  </div>
                </div>
              </div>
            </a>
            </>
            }
            {
              pageName == 'leadPage' ?
                <a href="#/"
                  className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                  id="Add_Lead"
                  onClick={() => {
                    setShowLeadModal(true);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                      {_l("l_add_lead")}
                    </div>
                    <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                      <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                        <AddTaskIcon className="HW18 " />
                      </div>
                    </div>
                  </div>
                </a>
                :
                pageName == "Proposals"
                  ?
                  <a href="#/"
                    className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img`}
                    id="Create_Offer"
                    onClick={() => {
                      handleSchedules(true);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                        {_l("l_create_offer")}
                      </div>
                      <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                        <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                          <CreateBenchmarkIcon className="HW18 fill-transparent" />
                        </div>
                      </div>
                    </div>
                  </a>
                  : window.location.pathname == "/clients"
                  ?
                  <a href="#/"
                    className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img`}
                    id="Create_Offer"
                    onClick={() => {
                      setOpenCreatClientModal(true);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                        {"Create New Client"}
                      </div>
                      <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                        <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                          <CreateBenchmarkIcon className="HW18 fill-transparent" />
                        </div>
                      </div>
                    </div>
                  </a>
                  : pageName === "MyEmail" ? 
                    <React.Fragment>
                      <a href="#/"
                        className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                        id= {_l("l_create_email")}
                        onClick={() => {
                          setBulkEmailSendShow(true);
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                            {_l("l_create_email")}
                          </div>
                          <div className="h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                            <div className="action_icon with_bg  d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                              <PencilSimpleLineIcon className="HW18 " />
                            </div>
                          </div>
                        </div>
                      </a>
                    </React.Fragment> :
                  <>
                  {
                    isAllowedToCreateMultiSpaceBenchmark() || ["my-providers", "equipments"].includes(leftPanelTab) || ["leaseDocument"].includes(docType)
                    ?
                    <React.Fragment></React.Fragment>
                    :
                    <React.Fragment>
                      {
                        !DrivePageTab.includes(location.pathname) ? userType == "contact" && ![1, 2].includes(Number(contact_role))
                        ?
                        // Temporary Patch
                        <a href="#/"
                          className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                          id="Add_task"
                          onClick={() => {
                            setIsRequest(true);
                            setShowTaskModal(true);
                            dispatch(setCreateTaskModalIsopen(true))
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                              {_l("l_create_request")}
                            </div>
                            <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                              <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                                <AddTaskIcon className="HW18 " />
                              </div>
                            </div>
                          </div>
                        </a>
                        :
                        <>
                        <a href="#/"
                          className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                          id="Add_task"
                          onClick={() => {
                            setShowTaskModal(true);
                            dispatch(setCreateTaskModalIsopen(true))
                            dispatch(isCreareTaskDefaultTopics(defaultTopics));
                            setIsRequest(false);
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                              {_l("l_create_task")}/{_l("l_request")}  
                            </div>
                            <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                              <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                                <AddTaskIcon className="HW18 " />
                              </div>
                            </div>
                          </div>
                        </a>
                        </>
                      : <></> }
                    </React.Fragment>
                  }
                  { pageName=="LiteVersion" || docType == "imageview" || (userType == "contact" && !CUSTOM_ROLES.includes(contact_role)) || ["my-providers", "my-tenants", "equipments"].includes(leftPanelTab) ?
                    <></>
                    :
                    <>
                  {!DrivePageTab.includes(location.pathname) ? userType != "staff"  && ((userType == "contact" || userType == "operator") && contact_role != 3) && !isAllowedToCreateMultiSpaceBenchmark() &&  !["leaseDocument"].includes(docType) ?
                    <a href="#/"
                      className={`bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img ${selectedVirtualSpace  ? 'd-none' : ''}`}
                      id="Add_schedule"
                      onClick={() => {
                        accessPremiumFeature(project_id, () => {
                          leftPanelTab == "homepage" || Number(projectId) <= 0 ? setShowSpaceModal({ show : true, actionType : "schedule"}) : navigate(`/connect-provider?space=${project_id}&provider-type=schedule`);
                        });
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {_l("l_create_schedule")}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <AddScheduleIcon className="HW18 " />
                          </div>
                        </div>
                      </div>
                    </a>
                    : <></> 
                    : <></>
                  }
                    {!DrivePageTab.includes(location.pathname) ? !["leaseDocument"].includes(docType) && <a href="#/"
                      className={`bottom-menu-link-two z-index-3 bottom-menu-links on-hover-active-toggle-img ${selectedVirtualSpace || userType == "operator" ? 'd-none' : ''}`}
                      id="Add_Benchmark"
                      onClick={createBenchmark}
                    >
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {_l("l_create_benchmark")}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <CreateBenchmarkIcon className="HW18 fill-transparent" />
                          </div>
                        </div>
                      </div>
                    </a> 
                    : <></>}
                    </> 
                  }
                {/* Create Email Start */}
                {
                  leftPanelTab == "homepage" ?
                    <a href="#/" className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img" onClick={() => emailSyncStatus ? setBulkEmailSendShow(true) : handleChangeTab("my-email", "/MyEmail")}>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {_l("l_create_email")}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <EnvelopeIcon className="HW18" />
                          </div>
                        </div>
                      </div>
                    </a>
                  : <></>
                }
                {/* Create Email End */}
                {/* Create Document Start */}
                {
                  leftPanelTab == "homepage" || DrivePageTab.includes(location.pathname) ?
                    <a href="#/" className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img" onClick={() => setShowDocumentUpload(true)}>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {`${_l("l_create")}  ${_l("l_document")}`}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <FilesIcon className="HW18" />
                          </div>
                        </div>
                      </div>
                    </a>
                  : <></>
                }
                {/* Create Email End */}
                {/* Create Space Start */}
                {
                  leftPanelTab == "homepage" ?
                    <a href="#/" className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img" onClick={() => navigate("/space-onboarding")}>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {`${_l("l_create")}  ${_l("l_space")}`}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <GlobeIcon className="HW18" />
                          </div>
                        </div>
                      </div>
                    </a>
                  : <></>
                }
                {/* Create Space End */}
                {/* Create Tenants Start */}
                {
                  leftPanelTab == "homepage" || leftPanelTab == "my-tenants"?
                    <a className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img" onClick={() => navigate('/tenants?space=0&actionType=add-contact')}>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {`${_l("l_create")}  ${_l("l_tenants")}`}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <UserGearIcon className="HW18" />
                          </div>
                        </div>
                      </div>
                    </a>
                  : <></>
                }
                {/* Create Tenants End */}
                {/* Create Provider Start */}
                {
                  leftPanelTab == "homepage" ||  leftPanelTab == "my-providers" ?
                    <a className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img" onClick={() => setShowSpaceModal({ show: true, actionType: "provider" })}>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                          {`${_l("l_create")}  ${_l("l_provider")}`}
                        </div>
                        <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                          <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <UsersThreeIcon className="HW18" />
                          </div>
                        </div>
                      </div>
                    </a>
                  : <></>
                }
                {/* Create Provider End */}
                {/* Create Equipment Start */}
                {
                  leftPanelTab == "homepage" ||  leftPanelTab == "equipments" ?
                    <a className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img" onClick={() => setShowSpaceModal({ show : true, actionType : "equipment"})}>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                      {`${_l("l_create")}  ${_l("l_equipment")}`}
                    </div>
                    <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                      <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                        <WrenchIcon className="HW18" />
                      </div>
                    </div>
                  </div>
                    </a>
                  : <></>
                }
                {/* Create Equipment End */}
                {/* Create Create Subscription Start */}
                { userType == "staff" && leftPanelTab == "homepage" ? 
                      <a href="#/"
                        className={`bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img ${selectedVirtualSpace  ? 'd-none' : ''}`}
                        id="Create Subscription"
                        onClick={() => {
                          setCreateSubscriptionModal(true)
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                            {_l("l_create")  + ' '  +_l("l_subscription")}
                          </div>
                          <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                            <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <img src={CreateSubscriptioneicon} className="h18w18 m-auto" />
                            </div>
                          </div>
                        </div>
                      </a> 
                      : <></>
                    }
                {/* Create Create Subscription End */} 
                {!DrivePageTab.includes(location.pathname) ? userType == "staff" ? 
                      <a href="#/"
                        className={`bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img ${selectedVirtualSpace  ? 'd-none' : ''}`}
                        id="Create_estimate"
                        onClick={() => {
                          setShowEstimateModal(true)
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                            {_l("l_create_estimate")}
                          </div>
                          <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                            <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                            <img src={CreateEstimateicon} className="h18w18 m-auto" />
                            </div>
                          </div>
                        </div>
                      </a> 
                      : <></>
                   : <></> }
                    {/* Create Invoice Start  */}
                    {
                      !DrivePageTab.includes(location.pathname) ? userType == "staff" ? (
                        <a
                          href="#/"
                          className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                          onClick={() => {
                            setShowInvoiceModel(true);
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                              {_l("l_create_invoice")}
                            </div>
                            <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                              <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                              <img src={CreateInvoiceicon} className="h18w18 m-auto" />
                              </div>
                            </div>
                          </div>
                        </a>
                      ) : (
                        <></>
                      )
                    : <></> }
                    {/* Create Invoice End  */}
                    {/* Credit note Start  */}
                    {
                      !DrivePageTab.includes(location.pathname) ? userType == "staff" ? (
                        <a
                          href="#/"
                          className="bottom-menu-link-one z-index-3 bottom-menu-links on-hover-active-toggle-img"
                          onClick={() => {
                            setShowCreditNote(true);
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="c-font color-white-60  menu-name pe-3 text-end text-truncate w100minus40">
                              {_l("l_create_credit_note")}
                            </div>
                            <div className="action_icon with_bg h40w40 comman-round-box bg-left-panel rounded-circle d-flex">
                              <div className=" d-flex light-theme-img comman-bg-img h-100 w-100 bg-style-cover cover-40">
                                <img src={CreateCreditNoteicon} className="h18w18 m-auto" />
                              </div>
                            </div>
                          </div>
                        </a>
                      ) : (
                        <></>
                      )
                    : <></> }
                    {/* Credit note End  */}
                  </>
            }
            <div className={`footer-add-btn-bg lease-doc-action ${docType == "leaseDocument" ? "shadowWhite" : ""}`}></div>
          </div>
        ) : (
          <div></div>
        )}
        </div>
      }
      {
        show ? (
          <CreateTaskRequestOffcanvas
            show={show}
            handleClose={(taskId) => {
              setShow(false);
              setShowDetails(true);
              if (taskId) {
                dispatch(
                  getSelectedTask(
                    localStorage.getItem("selectedOffice"),
                    taskId,
                    1
                  )
                );
                setShowEditTaskModal(true)
              }
            }}
            pageName={pageName}
          />
        ) : (
          <></>
        )
      }
      {
        showCreateScheduleForCustomer ? (
          <></>
        ) : (
          <></>
        )
      }
      {
        showEditTaskModal ? <CommanOffCanvas
          show={showEditTaskModal}
          handleClose={() => {
            jquery(".task-list-accordian").removeClass("list-view-toggle");
            setShowEditTaskModal(false);
            setCommonModalData();
            setSelectedTaskId(0);
            setTask_type('')
          }}
          pinHandler={() => {
          }}
          data={commonModalData}
          docType={task_type}
          setSelectedId={setSelectedTaskId}
          selectedTaskId={selectedTaskId}
          handleParentComponentAction={(type, taskData) => {
            if (task_type == 'task') {
              if (type == 'attachmentCountUpdate') {
                return;
              } else if (type == 'handleSelectedTask') {
                handleSelectedTask(taskData.task_id, taskData.flag)
                return;
              } else if (type == 'convertedTaskAction') {
                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                return;
              }
            } else if (task_type == 'request-task') {
              if (type == 'convertedTaskAction') {
                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                return;
              }
            }
          }}
          isCreateBenchmark={isBenchmark}
          isEdit={benchmarkDetail && Object.keys(benchmarkDetail).length ? true : false}
        /> : <></>
      }
      {
        showVirtualSpace ? (
          <CreateVirtualSpace
            projectId={projectId}
            show={showVirtualSpace}
            handleClose={() => {
              setShowVirtualSpace(false)
            }}
          />
        ) : (
          <></>
        )
      }
      {
        showScheduleModal ? (
          <SelectProviderCategory
            show={showScheduleModal}
            handleClose={() => {
              handleSchedules();
            }}
            handleShow={() => {
              handleSchedules();
            }}
            categoryListing={categoryListing}
            projectId={project_id}
            isCreateBenchmark={false}
          />
        ) : (
          ""
        )
      }
      {
        showBenchmarkModal ? (
          <SelectProviderCategory
            show={showBenchmarkModal}
            handleClose={() => {
              handleBenchmark();
            }}
            handleShow={() => {
              handleBenchmark();
            }}
            categoryListing={categoryListing}
            projectId={project_id}
            isCreateBenchmark={true}
          />
        ) : (
          ""
        )
      }
      {
        NewScheduleFooter ? (
          <NewSchedule
            show={NewScheduleFooter}
            handleClose={(id) => {
              setNewScheduleFooter(false);
              dispatch(updateTypeOfSpaceList(floorDetails.type_of_space.map((x) => x.id), false));
              if (id) {
                dispatch(getBenchmarkWeekSchedule(id));
                dispatch(getBenchmarkDetail(id, localStorage.getItem("client_id")));
                if (location.pathname == "/schedules") {
                  if (isBenchmark) {
                    setTask_type('benchmark')
                  }
                  else {
                    setTask_type('schedule')
                  }
                  setCommonModalData(benchmarkDetail);
                  setSelectedTaskId(id)
                  setShowEditTaskModal(true);
                }
                else {
                  navigate("/schedules", {
                    state: {
                      openDetailModal: true
                    }
                  });
                }
              }
              else {
                dispatch(createScheduleDetails({}));
                dispatch(getBenchmarkWeekSchedule(0, "all"));
              }
            }}
            isCreateBenchmark={isBenchmark}
          />
        ) : (
          <></>
        )
      }
      {
        showTaskModal ? (
          <CreateTaskOffcanvas
            show={showTaskModal}
            handleClose={(taskId) => {
              setShowTaskModal(false);
              scrollToBottom();
                setShowDetails(true);
                if (taskId) {
                  dispatch(
                    getSelectedTask(
                      localStorage.getItem("selectedOffice"),
                      taskId
                    )
                  );
                  setShowEditTaskModal(true)
                }
            }}
            isRequestTask={isRequest}
            pageName={pageName}
          />
        ) : (
          <></>
        )
      }
      {
        showLeadModal ?
          <AddLeadModal
            show={showLeadModal}
            handleClose={() => {
              setShowLeadModal(false);
            }}
          />
          :
          <></>
      }
      {
        openCreatClientModal ?
          <AddNewClient
            show={openCreatClientModal}
            handleClose={() => {
              setOpenCreatClientModal(false);
            }}
          />
          :
          <></>
      }
      {
        showToolsModal ?
          <AddNewDocument
            show={showToolsModal}
            handleClose={() => {
              setShowToolsModal(false);
            }}
          />
          :
          <></>
      }
      {
        estimateModal ?
          <AddEstimateModal
            show={estimateModal}
            handleClose={(data) => {
              setShowEstimateModal(false);
              if (data && data.hash) {
                setDocModal(true)
                setDocModalData(data)
              }
            }}
          />
          :
          <></>
      }
      {
        createSubscriptionModal ?
          <></>
            :
            <></>
        }
      {
        showInvoiceModel ?
          <AddInvoiceModal
            show={showInvoiceModel}
            handleClose={() => {
              setShowInvoiceModel(false);
            }}
          />
          :
          <></>
      }
      {
        showCreditNote ?
          <AddCreditNote
            show={showCreditNote}
            handleClose={() => {
              setShowCreditNote(false);
            }}
          />
          :
          <></>
      }
      { docModal 
        ? <DocumentModal
            handleClose={() => {
              setDocModalData({});
              setDocModal(false)
            }}
            fromTopicSetting={true}
            documentData={docModalData}
            show={docModal}
            type={docModalData.type}
          />
        : <></>
      }
      {bulkEmailSendShow ? 
          <AddEmailTemplatesModalNew
             show={bulkEmailSendShow}
             handleClose={() => {setBulkEmailSendShow(false)}}
             type = {"isFromEmailFooter"}
          />
          : <></>}
      {   
        showDocumentUpload
          ? <UploadDocument
            show={showDocumentUpload}
            handleClose={()=> setShowDocumentUpload(false)}
            />
          : <></>
      }
      {
        showSpaceModal.show 
        ? <SpaceSelectionModal
          showSpaceModal={showSpaceModal}
          handleClose={()=> setShowSpaceModal({ show: false, actionType : ""})}
          />
        : <></>
      }
    </React.Fragment>
  );
};
export default CommanFooterPlus;
